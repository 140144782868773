import React from "react";
import PropTypes from "prop-types";

// SHARED COMPONENTS
import { InfoGrid } from "components/shared/InfoGrid";
import { CaseDurationAndCapture } from "components/CaseDurationAndCapture/CaseDurationAndCapture";
import { ContactsSection } from 'components/CaseContactsListing/ContactsSection/ContactsSection';
import { getPeopleApiDataByHrids, getConcatNames, sortArray } from "utils/helpers/helpers";

// SCSS
import "./CPStep3.scss";

import LABELS from "labels";
import CONFIG from "config";

const {
  CASE_CONTACTS: {
    CASE_TEAM_LIST_HEADING,
    DATA_KEYS: {
      NAME: KEY_NAME,
      POSITION: KEY_POSITION,
      HOURS: KEY_HOURS,
      HRID: KEY_HRID,
      ALUMNI: KEY_ALUMNI,
    },

  },
  IDENTIFIER: {
    CARD_CP_CASE_TEAM_MEMBER
  },
} = LABELS;

class CPStep3 extends React.Component {
  state = {
    caseTeamListing: []
  }

  componentDidUpdate(prevProps) {
    const { stepState: { caseTeam }, caseTeamHours, isTBLoading } = this.props;
    const { caseTeamHours: prevCaseTeamHours } = prevProps
    const { caseTeamListing } = this.state;
    if (caseTeam?.length && !caseTeamListing.length && !isTBLoading && caseTeamHours && ((!caseTeamListing?.length) || (caseTeamHours !== prevCaseTeamHours))) {
      this.mergeCaseTeamAndHours(caseTeam, caseTeamHours);
    }
  }

  componentDidMount = () => {
    const { onLoadAnalytics } = this.props;
    onLoadAnalytics()
  }

  mergeCaseTeamAndHours(caseTeam, caseTeamHours) {
    let caseTeamListing = caseTeam.map(itm => {
      const hoursObj = caseTeamHours?.length && caseTeamHours.find((item) => (Number(item?.hrId) === Number(itm?.hrid)) && itm);
      return {
        [KEY_POSITION]: itm?.casePosition,
        [KEY_HRID]: itm?.hrid,
        [KEY_HOURS]: hoursObj?.hours ? hoursObj.hours : '',
      }
    });
    this.getPeopleDataOfCaseTeam(caseTeamListing)
  }

  getPeopleDataOfCaseTeam = async (caseTeamListing) => {
    const {
      getPeopleDataUsingIDs,
    } = this.props;

    // list of hrIds for which we need People API results
    const hrIds = caseTeamListing.map(caseTeamMember => caseTeamMember?.[KEY_HRID])
    const {
      PEOPLE_API_QUERY_PARAMS: {
        CASE_CONTACT_SEARCH: {
          LIMIT, REQUEST_DATA
        }
      }
    } = CONFIG;
    const peopleApiParams = {
      LIMIT, REQUEST_DATA
    }

    const peopleResultSet = await getPeopleApiDataByHrids(hrIds, this.props.getPeopleDataUsingIDs);

    this.setCaseTeamData(caseTeamListing, peopleResultSet)
  }

  setCaseTeamData = async (caseTeamListing, peopleResultSet) => {
    let caseTeamListingWithName = [];
    if (peopleResultSet && peopleResultSet?.length) {
      caseTeamListingWithName = caseTeamListing.map(itm => {
        const peopleObject = peopleResultSet.find((item) => (Number(item?.id) === Number(itm?.[KEY_HRID])) && itm);
        const { preferredLastName, alumni, preferredFirstName } = peopleObject || {};
        return {
          ...itm,
          [KEY_NAME]: getConcatNames(preferredFirstName, preferredLastName),
          [KEY_ALUMNI]: alumni,
        }
      });
    } else {
      caseTeamListingWithName = caseTeamListing;
    }
    const allCaseTeamSortedByTBHours = sortArray(caseTeamListingWithName, KEY_HOURS);
    this.setState({ caseTeamListing: allCaseTeamSortedByTBHours })
  }


  render() {
    const {
      unSanitizedCaseData,
      caseInfo,
    } = this.props;
    const { caseTeamListing } = this.state;
    return (
      <div className="cpstep cpstep3">
        <CaseDurationAndCapture {...caseInfo} />
        <InfoGrid
          data={unSanitizedCaseData}
          showTitleDataSeparator={false}
        />
        {!!caseTeamListing && caseTeamListing.length > 0 &&
          <ContactsSection
            heading={CASE_TEAM_LIST_HEADING}
            context="caseListing"
            data={caseTeamListing}
            caseContactsClickCallback={() => { }}
            identifier={CARD_CP_CASE_TEAM_MEMBER}
            showSortOption
            showSortLabel
            showContactsCount
            showAlumniTag
          />
        }
      </div>
    );
  }
}

CPStep3.defaultProps = {
  unSanitizedCaseData: PropTypes.array.isRequired,
  caseInfo: PropTypes.object.isRequired,
};



export { CPStep3 };

