import { put, takeLatest, call, delay } from '@redux-saga/core/effects';
import moment from 'moment';
import CONSTANTS from "globalConstants";
import { PROPOSAL_BIO_ACTIONS } from "redux/constants";
import { doAxiosRequest } from 'config-axios';
import CONFIG from 'config';
import { downloadFileFromBlobResoponse, triggerUsabillaSurvey } from 'utils/helpers/helpers';
import { proposalBioAcknowledgementSuccess, proposalBioAcknowledgementFailure, proposalBioPollingAPIStart, proposalBioPollingAPISuccess, proposalBioPollingAPIFailure } from "./proposalBio.actions";


const getCurrentDateTime = () => {
  var date = new Date();
  var value = date.getTime();
  const formattedDate = moment(value).format('DDMMYYYY_HHmmss');
  return formattedDate;
}
const triggerUsabilla = () => {
  const {
    USABILLA_SURVEY_KEYS: {
      FACECARD_DOWNLOAD
    }
  } = CONSTANTS;
  triggerUsabillaSurvey(FACECARD_DOWNLOAD);
}


export function* proposalDownloadFileFromCloudFront(action) {
  try {
    let { payload: { url, hrid } } = action;
    const axiosConfig = {
      method: 'GET',
      responsetype: 'blob',
      endpoint: url,
      headers: {
        'x-api-key': process.env.REACT_APP_CLIPPING_API_KEY
      }
    };
    const response = yield call(doAxiosRequest, axiosConfig);
    const fileName = `Proposal Bio_${hrid || "Unknown"}.pptx`;
    downloadFileFromBlobResoponse(response, fileName);
    triggerUsabilla();

  } catch (error) {
    console.log('Error in proposalDownloadFileFromCloudFront', error);
  }
}

export function* callAcknowledgementAPI(action) {
  const { payload } = action;

  try {
    const { XA, API_URL: { PROPOSAL_BIO_ACKNOWLEDGE } } = CONFIG;
    const hrid = payload?.employees?.[0]?.hrid;
    console.log("Acknowledgement API URL:", PROPOSAL_BIO_ACKNOWLEDGE);

    const axiosConfig = {
      method: 'POST',
      endpoint: PROPOSAL_BIO_ACKNOWLEDGE,
      params: payload,
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': XA,
      }
    };

    const response = yield call(doAxiosRequest, axiosConfig);

    const status = response?.error?.code || response?.status;
    const requestId = response?.requestId;

    if (status === '200' && requestId) {
      yield put(proposalBioAcknowledgementSuccess(requestId, hrid));
      yield put(proposalBioPollingAPIStart(requestId, hrid));
    } else {
      const errorMessage = response?.error?.message || "Acknowledgement failed";
      yield put(proposalBioAcknowledgementFailure(errorMessage));
    }
  }
  catch (error) {
    yield put(proposalBioAcknowledgementFailure(error.toString()));
  }
}

export function* callPollingAPI(action) {
  try {
    const { requestId, hrid } = action.payload;
    const maxAttempts = CONFIG.MAX_PROPOSAL_BIO_POLL;
    const delayDuration = CONFIG.MAX_PROPOSAL_BIO_DELAY;

    for (let attempts = 0; attempts < maxAttempts; attempts++) {
      const { API_URL: { PROPOSAL_BIO_POLLING } } = CONFIG;
      const pollingUrl = PROPOSAL_BIO_POLLING(requestId);

      const axiosConfig = {
        method: 'GET',
        endpoint: pollingUrl,
        headers: {
          'x-api-key': CONFIG.XA
        }
      };
      const response = yield call(doAxiosRequest, axiosConfig);
      const status = response?.status?.toLowerCase();
      if (status === 'completed') {
        yield put(proposalBioPollingAPISuccess(response));
        const fileUrl = response?.slideUrl;
        if (fileUrl) {
          yield put({
            type: PROPOSAL_BIO_ACTIONS.PROPOSAL_DOWNLOAD_FILE_FROM_CLOUDFRONT,
            payload: { url: fileUrl, hrid }
          });
        }
        return;
      } else if (status === 'inprogress' || status === 'requested') {
        yield delay(delayDuration);
      } else {
        yield put(proposalBioPollingAPIFailure("Polling failed"));
        return;
      }
    }

    yield put(proposalBioPollingAPIFailure("Polling limit reached without completion"));
  } catch (error) {
    yield put(proposalBioPollingAPIFailure(error.toString()));
  }
}

export default function* proposalBioSaga() {
  yield takeLatest(PROPOSAL_BIO_ACTIONS.PROPOSAL_BIO_ACKNOWLEDGEMENT_API_PENDING, callAcknowledgementAPI);
  yield takeLatest(PROPOSAL_BIO_ACTIONS.PROPOSAL_DOWNLOAD_FILE_FROM_CLOUDFRONT, proposalDownloadFileFromCloudFront);
  yield takeLatest(PROPOSAL_BIO_ACTIONS.PROPOSAL_BIO_POLLING_API_START, callPollingAPI);
}
