import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import ReactHtmlParser from 'react-html-parser';

import Image from "components/shared/Image";
import { Tooltip } from "components/shared/Tooltip";
import editIcon from "assets/images/edit.svg";
import history from "redux/history";
import { getUserId } from "utils/auth/auth";
import deleteIcon from "assets/images/delete.svg";
import previewIcon from "assets/images/preview.svg";
import archiveIcon from "assets/images/archive.svg";
import lockIcon from "assets/images/lock.svg";
import CONFIG from "config";
import LABELS from "labels";
import GLOBAL_CONSTANTS from "globalConstants";

import "./KnowledgeEntityActions.scss";
import VersionKnowledgeObjectHOC from "components/HOC/VersionKnowledgeObjectHOC/VersionKnowledgeObjectHOC";


export class KnowledgeEntityActions extends PureComponent {
  constructor(props) {
    super(props);
    this.stopBubble = React.createRef();
    this.state = {
      isSameLockedPerson: false,
    }
    this.isSameLockedPerson();
  }

  isSameLockedPerson = async () => {
    const hrid = await getUserId();
    const { entity: { lockedBy } } = this.props;
    this.setState({
      isSameLockedPerson: !!lockedBy && lockedBy.toString() === hrid.toString()
    });
  }
  getIconImage = (action) => {
    let image;
    const { KNOWLEDGE_ENTITY_ACTIONS: { DELETE, EDIT, PREVIEW, ARCHIVE } } = GLOBAL_CONSTANTS;
    switch (action) {
      case EDIT:
        image = editIcon;
        break;
      case DELETE:
        image = deleteIcon;
        break;
      case PREVIEW:
        image = previewIcon;
        break;
      case ARCHIVE:
        image = archiveIcon;
        break;
      default:
        image = false;
        break;
    }
    return image;
  };

  handleDefault = (e) => {
    e.stopPropagation()
  }

  continueEditingHandler = (redirectUrl, isRpaReview) => {
    const pushObj = {
      pathname: redirectUrl
    };
    if (isRpaReview) {
      pushObj['search'] = "?isrpareview=true";
    }
    history.push(pushObj);
  }
  getIsKB = () => {
    const {
      KNOWLEDGE_TYPE_KEYS: {
        BUNDLES,
        COLLECTIONS
      },
    } = CONFIG;

    const { entityType } = this.props;
    const isBundle = entityType === BUNDLES;
    const isCollection = entityType === COLLECTIONS;
    return isBundle || isCollection;
  }

  redirectUrl = (id) => {
    const { STEP1 } = GLOBAL_CONSTANTS;
    const {
      UI_URL: {
        METADATA_KB,
        METADATA_KP
      }
    } = CONFIG;
    const isKB = this.getIsKB();
    let url = isKB ? METADATA_KB(STEP1, id) : METADATA_KP(STEP1, id);
    return url;
  }

  render() {
    const {
      MY_NAVIGATOR: {
        ENTITY_ACTIONS: {
          LOCKED,
          LOCKED_DRAFT,
          LOCKED_DRAFT_BUNDLE,
          LOCKED_DRAFT_COLLECTION,
          LOCKED_BUNDLE,
          LOCKED_COLLECTION,
          LOCKED_ALT,
          EDIT_ALT,
          DELETE_ALT,
          PREVIEW_ALT,
          ARCHIVE_ALT,
          UNPUBLISH_ALT,
          MOVE_TO_DRAFT,
          CONTINUE_EDITING,
          CLONE,
          ADDITIONAL_INFORMATION,
          CONTRIBUTE_KNOWLEDGE,
          MY_NAVIGATOR
        },
        ENTITY_ACTIONS_MODAL_TITLES: {
          EDIT_MODAL_TITLE_KB,
          EDIT_MODAL_TITLE_KP,
          EDIT_MODAL_TITLE_TC,

          DELETE_MODAL_TITLE_KB,
          DELETE_MODAL_TITLE_KP,
          DELETE_MODAL_TITLE_TC,

          UNPUBLISH_MODAL_TITLE_KB,
          UNPUBLISH_MODAL_TITLE_TC,
          UNPUBLISH_MODAL_TITLE_KP,

          MOVETODRAFT_UNPUBLISHED_MODAL_TITLE_KB,
          MOVETODRAFT_UNPUBLISHED_MODAL_TITLE_TC,
          MOVETODRAFT_UNPUBLISHED_MODAL_TITLE_KP,

          MOVETODRAFT_ARCHIVED_MODAL_TITLE_KB,
          MOVETODRAFT_ARCHIVED_MODAL_TITLE_TC,
          MOVETODRAFT_ARCHIVED_MODAL_TITLE_KP,

          ARCHIVE_MODAL_TITLE_KB,
          ARCHIVE_MODAL_TITLE_TC,
          ARCHIVE_MODAL_TITLE_KP
        },
        BUTTONS: {
          WARNING_MODAL_DELETE,
          WARNING_MODAL_EDIT,
          WARNING_MODAL_OK,
          WARNING_MODAL_UNPUBLISH,
        },
      },
    } = LABELS;
    const {
      KNOWLEDGE_ENTITY_ACTIONS: {
        DELETE
      }
    } = GLOBAL_CONSTANTS;

    const {
      KNOWLEDGE_STATUS: {
        DRAFT,
        PUBLISHED,
        ARCHIVED,
        UNPUBLISHED,
      },
      KNOWLEDGE_TYPE_KEYS: {
        BUNDLES,
        COLLECTIONS,
        KP,
        PAGES
      },
      UI_URL: {
        CREATION,
        HOME
      },
      IT_CONNECT_ACCESS,
      IS_ARCHIVE_VISIBLE
    } = CONFIG;
    const {
      editAction,
      editAction: {
        action: editName,
        analyticsCallBack
      },
      deleteAction,
      previewAction: {
        action: previewName,
        callBack: previewCallBack
      },
      unpublishAction,
      archiveAction,
      entity,
      entityType,
      flavor,
      handleClose,
      handleAnalytics,
      warningModalCallBack,
      openInNewTab,
      isCreateEditEntitlement,
      isEditPublishedEntitlement,
      isPublishContentEntitlement,
      isSiteAdmin,
      isArchiveVisible,
      isPreviewVisible,
      isAdminHistoryVisible,
      isEditVisible,
      isContinueEditVisible,
      adminHistoryCallback,
      openRedirectUrlCallback,
      isContributeKnowlegeVisible,
      isMyNavigatorVisible,
      isDeleteVisible,
      isDraftVisible,
      isRpaReview,
      isContributionPage,
      onCloneButtonClick,
      isCloneVisible,
      isTextClicked
    } = this.props;
    const isDraft = entity.status === DRAFT;
    const isBundle = entityType === BUNDLES;
    const isCollection = entityType === COLLECTIONS;
    const isLocked = !isDraft && !!entity.lockedBy;
    const lockedMsg = isLocked ? ReactHtmlParser(`${isBundle ? LOCKED_BUNDLE : isCollection ? LOCKED_COLLECTION : LOCKED} <u>${entity.lockedByName}</u>`) : "";
    const lockedMsgDraft = isLocked ? ReactHtmlParser(`${isBundle ? LOCKED_DRAFT_BUNDLE : isCollection ? LOCKED_DRAFT_COLLECTION : LOCKED_DRAFT} <u>${entity.lockedByName}</u>`) : "";
    //edit always visible
    const editDisabled = false;
    const deleteVisible = isDeleteVisible !== undefined ? isDeleteVisible : (isDraft && isCreateEditEntitlement) || entity?.status !== PUBLISHED;
    //preview visible for both kp and kb, for non-published items
    const previewDisabled = false;
    const previewVisible = isPreviewVisible !== undefined ? isPreviewVisible : !isDraft && entity.status;

    const { isSameLockedPerson } = this.state;
    const archiveVisible = isArchiveVisible !== undefined ? isArchiveVisible : entity?.status === PUBLISHED && IS_ARCHIVE_VISIBLE && (isCollection ? (isSiteAdmin && isPublishContentEntitlement) : isPublishContentEntitlement);
    const unpublishVisible = entity?.status === PUBLISHED && (isCollection ? (isSiteAdmin && isPublishContentEntitlement) : isPublishContentEntitlement);
    const moveToDraftVisible = isDraftVisible !== undefined ? isDraftVisible : (entity?.status === UNPUBLISHED || entity?.status === ARCHIVED);
    let moveToDraftModalTitle = '';
    const isContinueEditingVisible = isContinueEditVisible && isLocked && isSameLockedPerson && isEditVisible;

    if (entity?.status === UNPUBLISHED) {
      moveToDraftModalTitle = isBundle ? MOVETODRAFT_UNPUBLISHED_MODAL_TITLE_KB : isCollection ? MOVETODRAFT_UNPUBLISHED_MODAL_TITLE_TC : MOVETODRAFT_UNPUBLISHED_MODAL_TITLE_KP
    } else if (entity?.status === ARCHIVED) {
      moveToDraftModalTitle = isBundle ? MOVETODRAFT_ARCHIVED_MODAL_TITLE_KB : isCollection ? MOVETODRAFT_ARCHIVED_MODAL_TITLE_TC : MOVETODRAFT_ARCHIVED_MODAL_TITLE_KP
    }
    const entityId = entity?.kbId || entity.id;
    return (
      <div className="kea">
        {
          (flavor === GLOBAL_CONSTANTS.KNOWLEDGE_ACTION_FLAVOR.REGULAR) &&
          <div className="kea__regular">
            {
              previewVisible &&
              <button
                className={`kea__btn ${previewDisabled ? "kea__btn--disabled" : ""}`}
                onClick={() => previewCallBack(entity)}
                disabled={previewDisabled}
              >
                <Image
                  src={this.getIconImage(previewName)}
                  alt={PREVIEW_ALT}
                />
              </button>
            }
            {
              deleteVisible &&
              <VersionKnowledgeObjectHOC
                entity={entity}
                modalHeading={isBundle ? DELETE_MODAL_TITLE_KB : isCollection ? DELETE_MODAL_TITLE_TC : DELETE_MODAL_TITLE_KP}
                toOpenModal={true}
                handleClose={handleClose}
                warningModalCallBack={warningModalCallBack}
                isCreateEditEntitlement={isCreateEditEntitlement}
                isEditPublishedEntitlement={isEditPublishedEntitlement}
                isSiteAdmin={isSiteAdmin}
                apiBtnName={WARNING_MODAL_DELETE}
                actionData={deleteAction}
                isContributionPage={isContributionPage}
              >
                <Image
                  src={this.getIconImage(DELETE)}
                  alt={DELETE_ALT}
                />
              </VersionKnowledgeObjectHOC>
            }
            {
              !isLocked && isEditVisible && isCreateEditEntitlement &&
              <VersionKnowledgeObjectHOC
                entity={entity}
                modalHeading={isBundle ? EDIT_MODAL_TITLE_KB : isCollection ? EDIT_MODAL_TITLE_TC : EDIT_MODAL_TITLE_KP}
                toOpenModal={false}
                editDisabled={editDisabled}
                analyticsCallBack={analyticsCallBack}
                entityType={isBundle ? BUNDLES : isCollection ? COLLECTIONS : KP}
                handleClose={handleClose}
                warningModalCallBack={warningModalCallBack}
                openInNewTab={openInNewTab}
                itemType={isBundle ? BUNDLES : isCollection ? COLLECTIONS : PAGES}
                entityId={entityId}
                isCreateEditEntitlement={isCreateEditEntitlement}
                isEditPublishedEntitlement={isEditPublishedEntitlement}
                redirectUrl={this.redirectUrl}
                apiBtnName={WARNING_MODAL_EDIT}
                actionData={editAction}
                isSiteAdmin={isSiteAdmin}
                isRpaReview={isRpaReview}
                isContributionPage={isContributionPage}
              >
                <Image
                  src={this.getIconImage(editName)}
                  alt={EDIT_ALT}
                />
              </VersionKnowledgeObjectHOC>
            }
            {

              isLocked && isEditVisible &&
              <Tooltip
                position="left"
                id={`${entityId}-edit_locked`}
                icon={
                  <button
                    className="stopBubble kea__btn"
                  >
                    <Image src={lockIcon} className="stopBubble lockIcon" alt={LOCKED_ALT} />
                  </button>
                }
              >
                {lockedMsg}
              </Tooltip>
            }
          </div>
        }
        {
          (flavor === GLOBAL_CONSTANTS.KNOWLEDGE_ACTION_FLAVOR.LIST) &&
          <div className="kea__expanded">
            <ul className="kea__list">
              {
                isEditVisible && !isSameLockedPerson &&
                <li>
                  {
                    <VersionKnowledgeObjectHOC
                      entity={entity}
                      redirectUrl={this.redirectUrl}
                      openInNewTab={openInNewTab}
                      modalHeading={isBundle ? EDIT_MODAL_TITLE_KB : isCollection ? EDIT_MODAL_TITLE_TC : EDIT_MODAL_TITLE_KP}
                      toOpenModal={false}
                      editDisabled={isLocked}
                      handleClose={handleClose}
                      analyticsCallBack={handleAnalytics}
                      warningModalCallBack={warningModalCallBack}
                      entityType={isBundle ? BUNDLES : isCollection ? COLLECTIONS : KP}
                      itemType={isBundle ? BUNDLES : isCollection ? COLLECTIONS : PAGES}
                      entityId={entityId}
                      isCreateEditEntitlement={isCreateEditEntitlement}
                      isEditPublishedEntitlement={isEditPublishedEntitlement}
                      isSiteAdmin={isSiteAdmin}
                      apiBtnName={WARNING_MODAL_EDIT}
                      actionData={editAction}
                      btnDisabled={isLocked}
                      isRpaReview={isRpaReview}
                      isContributionPage={isContributionPage}
                    >
                      <section className="kea__list-item">
                        <div className="kea__list-item-text">
                          {EDIT_ALT}
                        </div>
                        <div className="kea__list-item-icon">
                          {
                            isLocked &&
                            <Tooltip
                              position="left"
                              id={`${entityId}-edit_locked_alt`}
                              icon={
                                <button
                                  className="kea__btn"
                                >
                                  <Image src={lockIcon} className="lockIcon" alt={LOCKED_ALT} />
                                </button>
                              }
                            >
                              {lockedMsg}
                            </Tooltip>
                          }
                        </div>
                      </section>
                    </VersionKnowledgeObjectHOC>
                  }
                </li>
              }
              {
                isContinueEditingVisible &&
                <li>
                  <button
                    className={`kea__btn`}
                    onClick={() => this.continueEditingHandler(this.redirectUrl(entityId), isRpaReview)}
                  >
                    <span className="kea__list-item">{CONTINUE_EDITING}</span>
                  </button>
                </li>
              }
              {
                isCloneVisible &&
                <li>
                  <button
                    className={`kea__btn`}
                    onClick={(e) => onCloneButtonClick(e, true, entityId, entity?.status)}
                  >
                    <span className="kea__list-item">{CLONE}</span>
                  </button>
                </li>
              }
              {
                moveToDraftVisible &&
                <li className="stopBubble">
                  <VersionKnowledgeObjectHOC
                    entity={entity}
                    redirectUrl={this.redirectUrl}
                    openInNewTab={openInNewTab}
                    modalHeading={moveToDraftModalTitle}
                    toOpenModal={false}
                    handleClose={handleClose}
                    analyticsCallBack={analyticsCallBack}
                    warningModalCallBack={warningModalCallBack}
                    entityType={isBundle ? BUNDLES : isCollection ? COLLECTIONS : KP}
                    itemType={isBundle ? BUNDLES : isCollection ? COLLECTIONS : PAGES}
                    entityId={entityId}
                    isCreateEditEntitlement={isCreateEditEntitlement}
                    isEditPublishedEntitlement={isEditPublishedEntitlement}
                    isSiteAdmin={isSiteAdmin}
                    apiBtnName={WARNING_MODAL_OK}
                    actionData={editAction}
                    btnDisabled={isLocked}
                    isContributionPage={isContributionPage}
                    isTextClicked={isTextClicked}
                  >
                    {isLocked ?
                      <div onClick={this.handleDefault}>
                        <span className="stopBubble kea__list-draft">{MOVE_TO_DRAFT}</span>
                        <Tooltip
                          position="left"
                          id={`${entityId}-edit_locked_alt`}
                          icon={
                            <button
                              className="kea__btn kea__btn-icon"
                            >
                              <Image src={lockIcon} className="lockIcon" alt={LOCKED_ALT} />
                            </button>
                          }
                        >
                          {lockedMsgDraft}

                        </Tooltip>
                      </div>
                      :
                      <p className="stopBubble kea__list-item">{MOVE_TO_DRAFT}</p>}
                  </VersionKnowledgeObjectHOC>
                </li>
              }
              {
                deleteVisible &&
                <li className={`stopBubble ${entity?.status === UNPUBLISHED || entity?.status === ARCHIVED ? 'stopBubble__btn--disabled' : ''} `}>
                  <VersionKnowledgeObjectHOC
                    entity={entity}
                    modalHeading={isBundle ? DELETE_MODAL_TITLE_KB : isCollection ? DELETE_MODAL_TITLE_TC : DELETE_MODAL_TITLE_KP}
                    toOpenModal={true}
                    handleClose={handleClose}
                    warningModalCallBack={warningModalCallBack}
                    isCreateEditEntitlement={isCreateEditEntitlement}
                    isEditPublishedEntitlement={isEditPublishedEntitlement}
                    isSiteAdmin={isSiteAdmin}
                    apiBtnName={WARNING_MODAL_DELETE}
                    actionData={deleteAction}
                    btnDisabled={entity?.status === UNPUBLISHED || entity?.status === ARCHIVED}
                    isContributionPage={isContributionPage}
                    isTextClicked={isTextClicked}
                  >
                    <p className="stopBubble kea__list-item">{DELETE_ALT}</p>
                  </VersionKnowledgeObjectHOC>
                </li>
              }
              {
                archiveVisible &&
                <li className="stopBubble">
                  <VersionKnowledgeObjectHOC
                    entity={entity}
                    modalHeading={isBundle ? ARCHIVE_MODAL_TITLE_KB : isCollection ? ARCHIVE_MODAL_TITLE_TC : ARCHIVE_MODAL_TITLE_KP}
                    toOpenModal={true}
                    handleClose={handleClose}
                    warningModalCallBack={warningModalCallBack}
                    isCreateEditEntitlement={isCreateEditEntitlement}
                    isEditPublishedEntitlement={isEditPublishedEntitlement}
                    isSiteAdmin={isSiteAdmin}
                    apiBtnName={WARNING_MODAL_OK}
                    actionData={archiveAction}
                    isContributionPage={isContributionPage}
                    isTextClicked={isTextClicked}
                  >
                    <p className="stopBubble kea__list-item">{ARCHIVE_ALT}</p>
                  </VersionKnowledgeObjectHOC>
                </li>
              }
              {
                unpublishVisible &&
                <li className="stopBubble">
                  <VersionKnowledgeObjectHOC
                    entity={entity}
                    modalHeading={isBundle ? UNPUBLISH_MODAL_TITLE_KB : isCollection ? UNPUBLISH_MODAL_TITLE_TC : UNPUBLISH_MODAL_TITLE_KP}
                    toOpenModal={true}
                    handleClose={handleClose}
                    warningModalCallBack={warningModalCallBack}
                    isCreateEditEntitlement={isCreateEditEntitlement}
                    isEditPublishedEntitlement={isEditPublishedEntitlement}
                    isSiteAdmin={isSiteAdmin}
                    apiBtnName={WARNING_MODAL_UNPUBLISH}
                    actionData={unpublishAction}
                    isContributionPage={isContributionPage}
                    isTextClicked={isTextClicked}
                  >
                    <p className="stopBubble kea__list-item">{UNPUBLISH_ALT}</p>
                  </VersionKnowledgeObjectHOC>

                </li>
              }
              {
                isAdminHistoryVisible && <li className="stopBubble kea__list-separated">
                  <button
                    className="stopBubble kea__btn"
                    onClick={adminHistoryCallback}
                  >
                    <p className="stopBubble kea__list-item">{ADDITIONAL_INFORMATION}</p>
                  </button>
                </li>
              }
              {
                isContributeKnowlegeVisible && (
                  isContinueEditVisible ?
                    <li className="stopBubble">
                      <button
                        className={`stopBubble kea__btn`}
                        onClick={(event) => openRedirectUrlCallback(event, CREATION)}
                      >
                        <p className="stopBubble kea__list-item">{CONTRIBUTE_KNOWLEDGE}</p>
                      </button>
                    </li>
                    :
                    <li className="stopBubble kea__contributeKnowledge">
                      <Tooltip
                        position="left"
                        id={`${entityId}-contribute-knowledge`}
                        icon={
                          <button
                            className={`stopBubble kea__btn`}
                            disabled
                          >
                            <p className="stopBubble kea__list-item">{CONTRIBUTE_KNOWLEDGE}</p>
                          </button>
                        }
                      >
                        <p>You do not have access to contribute knowledge. Please reach out to <strong><a href={IT_CONNECT_ACCESS} target="_blank" rel="noopener noreferrer">Service Center</a></strong> to get the access.</p>
                      </Tooltip>
                    </li>
                )
              }
              {
                isMyNavigatorVisible && <li className="stopBubble">
                  <button
                    className={`stopBubble kea__btn`}
                    onClick={(event) => openRedirectUrlCallback(event, HOME)}
                  >
                    <p className="stopBubble kea__list-item">{MY_NAVIGATOR}</p>
                  </button>
                </li>
              }
            </ul>
          </div >
        }
      </div>
    )
  }
};

KnowledgeEntityActions.defaultProps = {
  editAction: { action: "edit", callBack: () => { } },
  deleteAction: { action: "delete", callBack: () => { } },
  previewAction: { action: "preview", callBack: () => { } },
  archiveAction: { action: "archive", callBack: () => { } },
  isContinueEditVisible: false,
  openInNewTab: true,
  isEditPublishedEntitlement: false,
  isCreateEditEntitlement: false,
  isSiteAdmin: false,
  isContributionPage: false,
  isAdminHistoryVisible: false,
  adminHistoryCallback: () => { },
  openRedirectUrlCallback: () => { },
}

KnowledgeEntityActions.propTypes = {
  entity: PropTypes.shape().isRequired,
  actionName: PropTypes.string,
  apiBtnName: PropTypes.string,
  editAction: PropTypes.object,
  deleteAction: PropTypes.object,
  previewAction: PropTypes.object,
  archiveAction: PropTypes.object,
  flavor: PropTypes.oneOf([
    GLOBAL_CONSTANTS.KNOWLEDGE_ACTION_FLAVOR.REGULAR,
    GLOBAL_CONSTANTS.KNOWLEDGE_ACTION_FLAVOR.LIST,

  ]),
  openInNewTab: PropTypes.bool,
  isSiteAdmin: PropTypes.bool,
  isContinueEditVisible: PropTypes.bool,
  isAdminHistoryVisible: PropTypes.bool,
  isContributionPage: PropTypes.bool,
  adminHistoryCallback: PropTypes.func,
};