import React from "react";
import { connect } from "react-redux";
// REDUX 
import { bindActionCreators } from "redux";
import { AdditionalInfo } from "components/shared/AdditionalInfo";
import { TabComponent } from 'components/shared/TabComponent';
import FooterWorkflow from "components/shared/FooterWorkflow";
import Checkbox from "components/shared/Checkbox";
import Image from "components/shared/Image";
import { GlobalStyle, OverlayViewSubject, SUBSCRIBERS } from '@kdshared/docviz';
// Container
import { Layout } from "containers/Layout";

import { CPStep1 } from "containers/CasePage/CPStep1";
import { CPStep2 } from "containers/CasePage/CPStep2";
import { CPStep3 } from "containers/CasePage/CPStep3";
import { CPStep4 } from "containers/CasePage/CPStep4";

// ACTIONS
import { actions as peopleResultsActions } from 'redux/actions/peopleresults/peopleresults_actions';
import { actions as knowledgeItemActions } from "redux/actions/knowledgeitem/knowledgeitem_actions";
import { enableBodyOverflow, disableBodyOverflow } from "redux/actions/global/global.actions";
import { getCaseHistory } from 'redux/actions/caseHistory/caseHistory.actions';
import {
  notifyWarning,
  dismissNotifications,
} from "redux/actions/notification/notification_actions";
// Selectors
import { selectors as caseMetadataSelectors } from "redux/reducers/caseMetadata/caseMetadata_reducer";
import { selectors as associatedKPSelectors } from "redux/reducers/associatedKPs/associatedKPs_reducer";
import { selectors as typeAheadResultsSelectors } from "redux/reducers/peopleresults/peopleresults_reducer";
import { selectors as docvizSelectors } from "redux/reducers/docviz/docviz_reducer";
import { selectors as taxonomySelector } from "redux/reducers/taxonomy/taxonomy_reducer";
import { selectors as kpSelectors } from "redux/reducers/knowledgepage/knowledgepage_reducer"
import { Loader } from "components/shared/Loader";
import { CASE_METADATA, KNOWLEDGE_ITEMS, DOCVIZ, CCO_DATA, KP } from "redux/constants";
import { Helmet } from "react-helmet";
// Helper methods
import {
  getContentToDisplay,
  isContentRestrictedOrNull,
  areObjectsEqual,
  getConcatNames,
  copyObject,
  removeCurlyBrackets,
  isValidCaseGuid,
  openURL,
  getUtcToLocalDate,
  checkValidationsForArrayOfObjects,
  convertArrayToObject,
  getmyCurrentLocation
} from "utils/helpers/helpers";
import { isValidInput } from "utils/helpers/validation";
import moment from "moment";
import ANALYTICS from "utils/analytics/analytics";
import { PAGES, DTMRULE, TRIGGERS, SCREEN_NAMES, TOOLNAME, PRIMARY_CATEGORY } from "utils/analytics/analytics_constants";

import { Form } from "semantic-ui-react";

import GLOBAL_CONFIG from "config";
import DOCVIZ_CONFIG from '../Docviz/Docviz.config';
import LABELS from "labels";
import CONSTANTS from "globalConstants";
import CONFIG from "./CasePage.config";

// IMAGES
import profileIcon from "assets/images/profile_round.svg";
import connectionIcon from "assets/images/connection_pattern.svg";
import moneyIcon from "assets/images/money.svg";
import calenderIcon from "assets/images/calendar.svg";
import warningIcon from "assets/images/warning-icon.svg";

import "./CasePage.scss";
import { getCaseSam } from "redux/actions/caseMetadata/caseMetadata.actions";
import DocvizOverlayWrapper from "components/DocvizOverlayWrapper/DocvizOverlayWrapper";
import ErrorPage from "containers/ErrorPage/ErrorPage";
import { triggerClippingAnalytics } from "utils/analytics/utils_analytics";

const {
  CP_CONSUMPTION: {
    CLIENT_NAME,
    CLIENT_BU,
  },
  CP_CONTRIBUTION: {
    PAGE_TITLE,
    HEADER_RIGHT_BUTTON,
    STEP1_TITLE,
    STEP2_TITLE,
    STEP3_TITLE,
    STEP4_TITLE,
    TB_BILLING,
    TB_BILLING_IN_DOLLAR,
    BUDGET,
    EXPECTED_END_DATE,
    CCO,
    BILLING_PARTNER,
    EMPTY_VALUE,
    WITHDRAWN
  },
  USD,
} = LABELS;

const {
  GET_CASE_T_AND_B,
  GET_CASE_METADATA,
  GET_CASE_CMP,
  SAVE_CASE_METADATA,
  GET_CASE_FPA_DETAILS,
  SEARCH_CASES,
  RESET_SEARCH_RESULTS,
  GET_CASE_TYPES,
  GET_CASE_TB_DATES,
} = CASE_METADATA;

const { GET_CCO_RESULTS } = CCO_DATA;
const { validationDictionary, relatedCasesAndRelationshipField } = CONFIG;
const { CP_STEP1, CP_STEP2, CP_STEP3, CP_STEP4, CP_STEPS_VALIDATION } = CONSTANTS;
const STEPS = [CP_STEP1, CP_STEP2, CP_STEP3, CP_STEP4];
const { DOWNLOAD_APPLICATION_NAME: { CASE_PAGE_APP_NAME } } = GLOBAL_CONFIG;
class CasePageContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    const {
      match: { params },
      getCaseMetadata
      // history: { location: { search } }
    } = props;

    const { step, cid } = params;
    this.state = {
      activeStep: STEPS.indexOf(step),
      unSanitizedCaseData: [],
      // mainKPState: this.getMainKPstate(false, {}, modal_option),
      caseState: {},
      docvizOpen: false,
      errorState: {},
      partner: {
        img: '',
        name: EMPTY_VALUE,
      },
      cco: {
        img: '',
        name: EMPTY_VALUE,
      },
      kpDocvizAnalyticsData: {},
      // isWModalVisible: false,
      // isPublishModalVisible: false,
      isPageRestricted: true,
      showOfficeWarning: false,
      initialCaseOffice: null
    };
    if (cid) {
      getCaseMetadata(cid);
    }
  }

  getPersonInfo = async (hrId, type, callback) => {
    const {
      peopleResultsActions: {
        getPeopleDataUsingIDs
      }
    } = this.props;
    if (!hrId) {
      return "";
    }
    const ccoDataRes = await getPeopleDataUsingIDs([hrId], false);
    if (ccoDataRes && ccoDataRes.length) {
      const ccoData = ccoDataRes[0];
      const { picture, preferredLastName, preferredFirstName } = ccoData;

      this.setState({
        [type]: {
          img: picture,
          name: getConcatNames(preferredFirstName, preferredLastName),
        }
      }, () => {
        if (typeof callback == "function") {
          callback();
        }
      })
    }
  };

  getUnsanitizedCaseData = (resData = {}) => {
    const { caseSamData } = this.props;
    let expectedEndDate = EMPTY_VALUE;
    const { CP_UI_DATE_FORMAT } = GLOBAL_CONFIG;
    if (caseSamData && caseSamData.expectedEndDate) {
      expectedEndDate = moment(getUtcToLocalDate(caseSamData?.expectedEndDate)).format(CP_UI_DATE_FORMAT);
    }

    const {
      clientName,
      clientBUName,
      tbBillingLocal,
      budget,
      tbBillingUsd,
    } = resData;
    const {
      cco: {
        img: ccoImagePath,
        name: ccoName,
      },
      partner: {
        img: partnerImagePath,
        name: partnerName,
      }
    } = this.state;
    const billingUsd = tbBillingUsd && !isContentRestrictedOrNull(tbBillingUsd) ? `${USD} ${tbBillingUsd}` : EMPTY_VALUE;
    const clientBUValue = clientBUName?.toLowerCase() === clientName?.toLowerCase() ? "" : clientBUName;
    const unSanitizedCaseData = [
      { imgPath: profileIcon, label: CLIENT_NAME, value: getContentToDisplay(clientName, EMPTY_VALUE) },
      { imgPath: connectionIcon, label: CLIENT_BU, value: getContentToDisplay(clientBUValue, EMPTY_VALUE) },
      { imgPath: moneyIcon, label: TB_BILLING, value: getContentToDisplay(tbBillingLocal, EMPTY_VALUE), imgShape: "rectangle" },
      { imgPath: moneyIcon, label: TB_BILLING_IN_DOLLAR, value: billingUsd, imgShape: "rectangle" },
      { imgPath: moneyIcon, label: BUDGET, value: getContentToDisplay(budget, EMPTY_VALUE), imgShape: "rectangle" },
      { imgPath: calenderIcon, label: EXPECTED_END_DATE, value: expectedEndDate },
      { imgPath: ccoImagePath || profileIcon, label: CCO, value: ccoName, showFullImage: !!ccoImagePath },
      { imgPath: partnerImagePath || profileIcon, label: BILLING_PARTNER, value: partnerName, showFullImage: !!partnerImagePath },
    ];
    this.setState({
      unSanitizedCaseData,
      caseState: {
        ...this.state.caseState,
        clientBUName: getContentToDisplay(clientBUValue, ""),
        clientName,
      }
    });
  };

  getStepFormData = (step) => {
    const {
      caseState,
      caseState: {
        sanitizedCaseTitle,
        clientDescription,
        clientBusinessUnitDescription,
        confirmedConfidential,
        sanitizedCaseDescription,
        subjectTags,
        bcgInternalList,
        ipaTagList,
        fpaTagList,
        curated,
        proposalReceived,
        knowledgeAssets = [],
        caseVignettesKnowledgeAssets = [],
        caseTeam,
        office,
        id
      }
    } = this.state;
    if (step === CP_STEP1) {
      return {
        //step1 fields
        sanitizedCaseTitle,
        clientDescription,
        clientBusinessUnitDescription,
        confirmedConfidential,
        sanitizedCaseDescription,
        subjectTags,
        bcgInternalList,
        ipaTagList,
        fpaTagList,
        office,
        id: removeCurlyBrackets(id)
      };
    }
    if (step === CP_STEP4) {
      return {
        //step4 fields
        curated,
        proposalReceived,
        knowledgeAssets,
        caseVignettesKnowledgeAssets,
        caseTeam,
      };
    }
    return caseState;
  }

  componentDidMount = () => {
    const {
      match: { params },
    } = this.props;
    const { cid } = params;
    this.overlaySubscriber = OverlayViewSubject.subscribe(action => {
      switch (action.type) {
        case SUBSCRIBERS.CLOSE_CLICK:
          const { globalActions: { enableBodyOverflow } } = this.props;
          this.setState({
            docvizOpen: false
          });
          enableBodyOverflow();
          break;
        case SUBSCRIBERS.CLIP_DOWNLOAD_CLICK:
          const { slides } = action;
          const {
            downloadSelectedSlides,
            docvizDoc
          } = this.props;
          const slideNumbers = slides.map(s => s.slideNumber);
          downloadSelectedSlides(slideNumbers, docvizDoc.kpId, docvizDoc);
          triggerClippingAnalytics({
            id: cid,
            kpid: docvizDoc.kpId,
            isCases: true,
            paRecommended: docvizDoc?.paRecommended,
            pageName: PAGES.CP_CREATION,
            primaryCategory: PRIMARY_CATEGORY.CASE_PAGE_CREATION,
            slideNumber: slideNumbers.join("|")
          });
          break;
        default:
          break;
      }
    });
  }

  componentDidUpdate = (prevProps, prevState) => {
    const {
      // match: {
      //   params: { step, kpid },
      // },
      caseMetadata,
      // defaultLanguage,
      // history,
      timeAndBillingData,
      isLoadingMetadata,
      getTimeAndBilling,
      getSamData,
      getCmp,
      getCaseFPADetails,
      getCaseTypes,
      match: { params },
    } = this.props;
    const { cid } = params;

    const {
      // match: {
      //   params: { step: prevStep },
      // },
      // defaultLanguage: prevDefaultLanguage,
      caseMetadata: prevCaseMetadata,
      timeAndBillingData: prevTimeAndBillingData,
      isLoadingMetadata: prevIsLoadingMetadata
    } = prevProps;
    const validId = isValidCaseGuid(cid);
    if (validId && isLoadingMetadata !== prevIsLoadingMetadata && !isLoadingMetadata) {
      const isPageRestricted = caseMetadata?.isRestrictedCase === 'Y'
      this.setState({
        isPageRestricted
      });
      if (!isPageRestricted) {
        getTimeAndBilling(cid);
        getCmp(cid);
        getCaseFPADetails(cid);
        getCaseTypes(cid);
        getSamData(cid);
      }
    }

    if (timeAndBillingData && !!Object.keys(timeAndBillingData).length && (!prevTimeAndBillingData || !Object.keys(prevTimeAndBillingData).length)) {
      this.getUnsanitizedCaseData(timeAndBillingData);
      const { clientName, billingPartnerHrId } = timeAndBillingData;
      if (process.env.REACT_APP_IS_PVT_PEOPLE_API_ENABLED?.trim() === "true") {
        const { getCCOResults } = this.props;
        getCCOResults(clientName, true);
      }
      this.getPersonInfo(getContentToDisplay(billingPartnerHrId, ""), "partner", () => this.getUnsanitizedCaseData(timeAndBillingData))
      const { clientCoordinatingOfficer = null } = timeAndBillingData;
      if (clientCoordinatingOfficer && clientCoordinatingOfficer.hrId) {
        let { hrId } = clientCoordinatingOfficer;
        this.getPersonInfo(getContentToDisplay(hrId.toString(), ""), "cco", () => this.getUnsanitizedCaseData(timeAndBillingData));
      }
    }

    if (caseMetadata && !areObjectsEqual(caseMetadata, prevCaseMetadata)) {
      const { knowledgeAssets } = caseMetadata;
      caseMetadata["knowledgeAssets"] = getContentToDisplay(knowledgeAssets, [])
      this.setState({ caseState: { ...caseMetadata } });
      this.setState({ initialCaseOffice: copyObject(caseMetadata?.office) })
    }

    // if the docviz loading state changes from loading true to false
    // means the metadata has been fetched and we should open the docviz modal
    // and also call analytics
    if (prevProps.isDocvizLoading && !this.props.isDocvizLoading) {
      const { globalActions: { disableBodyOverflow } } = this.props;
      this.setState({
        docvizOpen: true
      });
      disableBodyOverflow();
    }
    const { caseState: { relatedCasesAndRelationshipType } } = this.state;
    const { caseState: { relatedCasesAndRelationshipType: prevRelatedData } } = prevState;
    if ((!prevRelatedData || !prevRelatedData.length) && relatedCasesAndRelationshipType && relatedCasesAndRelationshipType.length > 0) {
      const { getCaseOpenAndCloseDate } = this.props;
      const guidList = relatedCasesAndRelationshipType.map(item => item.caseGuid);
      getCaseOpenAndCloseDate(guidList);
    }


    const { caseOpenAndCloseDate } = this.props;
    const { caseOpenAndCloseDate: prevOpenAndCloseDate } = prevProps;
    if (relatedCasesAndRelationshipType && !areObjectsEqual(prevOpenAndCloseDate, caseOpenAndCloseDate)) {
      this.setOpenAndCloseDateForRelatedCase(relatedCasesAndRelationshipType, caseOpenAndCloseDate)
    }
  };

  setOpenAndCloseDateForRelatedCase = (relatedCasesAndRelationshipType, caseOpenAndCloseDate) => {
    let relatedCaseWithDates = [];
    const { CP_DATE_FORMAT, CP_UI_DATE_FORMAT } = GLOBAL_CONFIG;
    relatedCaseWithDates = relatedCasesAndRelationshipType.map(item => {
      const { caseGuid } = item;
      if (caseOpenAndCloseDate[caseGuid]) {
        item.openDate = moment(caseOpenAndCloseDate[caseGuid].openDate, CP_DATE_FORMAT).format(CP_UI_DATE_FORMAT);
        item.closedDate = moment(caseOpenAndCloseDate[caseGuid].closedDate, CP_DATE_FORMAT).format(CP_UI_DATE_FORMAT);
      }
      return item;
    })
    this.setState({ relatedCasesAndRelationshipType: relatedCaseWithDates })
  }

  updateMetadata = () => {
    const { saveCaseMetadata } = this.props;
    const { caseState } = this.state;
    saveCaseMetadata(caseState);
    this.setOfficeWarningFlag(false);
  };

  updateErrorState = (errorObj, isShowNotification) => {
    let messages = [];
    this.setState(
      {
        errorState: {
          ...errorObj,
        },
      },
      () => {
        const haveErrors = Object.keys(errorObj).length;
        const {
          notifyWarning,
          dismissNotifications,
        } = this.props;
        if (haveErrors) {
          Object.entries(errorObj).forEach(([key, obj]) => {
            let { errormessage } = obj;
            if (obj && errormessage && messages.indexOf(errormessage) === -1) {
              messages.push(errormessage)
            }
          });
          if (isShowNotification) {
            dismissNotifications();
            setTimeout(() => notifyWarning('', messages[0]), 200);
          }
        } else {
          dismissNotifications();
        }
      }
    );
  };

  validateForm = (dataObj) => {
    const errors = {};
    const { caseState } = this.state;
    for (const [key, obj] of Object.entries(dataObj)) {
      const inputValue = caseState[key];
      const { isValid, message } = isValidInput(inputValue, obj, caseState);
      if (!isValid) {
        errors[key] = {
          errormessage: message,
        };
      }
    }
    const haveErrors = Object.keys(errors).length;
    this.updateErrorState(errors, true);
    return !haveErrors;
  };

  handleInputUpdateError = (value, name, obj) => {
    const { errorState, caseState } = this.state;
    let temp = copyObject(errorState);
    const { isValid, message } = isValidInput(
      value,
      obj,
      caseState
    );
    if (isValid) {
      delete temp[name];
      this.updateErrorState(temp, false);
    } else if (Object.keys(errorState).length) {
      let showMessage = false || message !== temp[name]?.message;
      temp[name] = { errormessage: message };
      this.updateErrorState(temp, showMessage);
    }
  }

  handleChange = (value, name, step, callback = null, autoSave = true) => {
    const { caseState } = this.state;
    const {
      CP_CONTRIBUTION: {
        STEP1_FIELDS: {
          CONFIDENTIAL_DESC,
        },
      }
    } = LABELS;
    let newValueObj = {};
    // clear sanitizedCaseDescription if confirm confidential checkbox is changed
    if (name === "confirmedConfidential") {
      if (value) {
        newValueObj.sanitizedCaseDescription = CONFIDENTIAL_DESC;
      } else {
        newValueObj.sanitizedCaseDescription = "";
      }
      newValueObj.confirmedConfidential = value;
    }
    // Check if handle change is called for multiple fields
    else if (Array.isArray(name)) {
      name.forEach(key => {
        if (!areObjectsEqual(value[key], caseState[key])) {
          newValueObj[key] = value[key];
        }
      });
    }
    else if (!areObjectsEqual(value, caseState[name])) {
      newValueObj[name] = value;
    }
    // set the state finally
    this.setState(
      {
        caseState: { ...caseState, ...newValueObj },
      },
      () => {
        if (name === relatedCasesAndRelationshipField) {
          /**
           * This if condition is written so that on every handle change for relatedCasesAndRelationshipType
           * we don't have to show and hide the warning toaster
           * warning toaster should only hide if there is no validaiotn in the relatedCasesAndRelationshipType field
           */
          let isError = checkValidationsForArrayOfObjects(value, validationDictionary[CP_STEPS_VALIDATION][name].required_object_field.key);
          if (!isError) {
            this.handleInputUpdateError(value, name, validationDictionary[CP_STEPS_VALIDATION][name]);
          }
        } else {
          this.handleInputUpdateError(value, name, validationDictionary[CP_STEPS_VALIDATION][name]);
        }
      }
    );
  };


  handleSubmit = () => {
    const isValid = this.validateForm(validationDictionary[CP_STEPS_VALIDATION]);
    if (isValid) {
      this.updateMetadata();
    }
  };


  footerButtonsClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.target.id === "btn-consump") {
      const {
        match: {
          params: { cid },
        }
      } = this.props;
      openURL(GLOBAL_CONFIG.UI_URL.CP_CONSUMPTION_NEW(cid), true)
    } else if (e.target.id === "btn-publish") {
      this.handleSubmit();
      this.saveButtonAnalytics();
    }
  };
  saveButtonAnalytics = () => {
    const { CASE_PAGE } = TOOLNAME;
    const { COLLECTION_CREATION } = DTMRULE;
    const { activeStep } = this.state;
    const {
      CP_CREATION: {
        CONSUMPTION_SAVE
      }
    } = TRIGGERS;
    const {
      match: {
        params: { cid }
      }
    } = this.props;
    const { CASE_OVERVIEW, CASE_INSIGHTS, CASE_TEAM, KNOWLEDGE_ASSETS } = SCREEN_NAMES;
    const screenNameArray = [CASE_OVERVIEW, CASE_INSIGHTS, CASE_TEAM, KNOWLEDGE_ASSETS];
    /* event 215 click on save button */

    const newAdobeData = {
      search: {
        term: "NA", //evar25
      },
      casePage: {
        trigger: CONSUMPTION_SAVE //evar132
      },
      collection: {
        screenName: screenNameArray[activeStep] //evar112
      },
      caseID: cid, // Evar118
      page: {
        pageInfo: {
          pageName: PAGES.CP_CREATION, // evar1
          pageURL: getmyCurrentLocation(), //evar3
        },
        headerInfo: {
          staffEmailID: "NA", //eVar23
          toolName: TOOLNAME.CASE_PAGE, // eVar80
        },
        category: {
          primaryCategory: PRIMARY_CATEGORY.CASE_PAGE_CREATION, // evar50
        },
      },
    };
    ANALYTICS.kc.sendEventData(newAdobeData, COLLECTION_CREATION, CASE_PAGE);
  };

  setActiveStep = (step) => {
    this.setState({ activeStep: step });
  }
  stepChangeAnalytics = (newStep, oldStep, cid) => {
    const { CASE_PAGE } = TOOLNAME;
    const {
      CP_CREATION: {
        TAB_CASE_OVERVIEW,
        TAB_CASE_INSIGHTS,
        TAB_CASE_TEAM,
        TAB_CASE_KNOWLEDGEASSETS
      }
    } = TRIGGERS;
    const triggerArray = [
      TAB_CASE_OVERVIEW,
      TAB_CASE_INSIGHTS,
      TAB_CASE_TEAM,
      TAB_CASE_KNOWLEDGEASSETS
    ]
    const { CASE_OVERVIEW, CASE_INSIGHTS, CASE_TEAM, KNOWLEDGE_ASSETS } = SCREEN_NAMES;
    const screenNameArray = [CASE_OVERVIEW, CASE_INSIGHTS, CASE_TEAM, KNOWLEDGE_ASSETS];
    const { CP_CREATION } = PAGES;
    const { CP_CREATE } = DTMRULE;

    const newAdobeData = {
      page: {
        category: {
          primaryCategory: CP_CREATION,
        },
        pageInfo: {
          pageName: CP_CREATION,
          pageURL: getmyCurrentLocation(),
        },
      },
      collection: {
        screenName: screenNameArray[newStep]
      },
      caseID: cid,
      casePage: {
        trigger: triggerArray[newStep]
      },

    };
    setTimeout(() => {
      ANALYTICS.kc.sendEventData(
        newAdobeData, CP_CREATE, CASE_PAGE)
    }, 10);
  }

  stepChanged = (e, step, oldStep) => {
    const { activeStep } = this.state;
    const { UI_URL: { CP_METADATA } } = GLOBAL_CONFIG;
    const {
      match: {
        params: { cid },
      },
      history
    } = this.props;
    if (activeStep !== step) {
      history.replace({
        pathname: CP_METADATA(STEPS[step], cid),
      });
      this.stepChangeAnalytics(step, oldStep, cid);
    }
  }

  fetchRecentContributions = async () => {
    const { knowledgeItemActions: { searchRecentContributions } } = this.props;
    searchRecentContributions();
  }

  searchKnowledgeItems = async (legacyId, searchTerm) => {
    const { knowledgeItemActions: { searchKnowledgeObjDetails } } = this.props;
    searchKnowledgeObjDetails(legacyId, searchTerm);
  }

  addKnowledgeAsset = (item) => {
    const { saveAssociatedKPs } = this.props;
    const { caseState, caseState: { knowledgeAssets, caseVignettesKnowledgeAssets } } = this.state;
    const { id, contentType: { id: type } } = item;
    const { CP_VIGNETTES } = CONFIG;
    if (type === CP_VIGNETTES) {
      this.setState({
        caseState: {
          ...caseState,
          caseVignettesKnowledgeAssets: [...caseVignettesKnowledgeAssets, id]
        }
      });
    }
    else {
      this.setState({
        caseState: {
          ...caseState,
          knowledgeAssets: [...knowledgeAssets, id]
        }
      });
    }
    saveAssociatedKPs(item);
  };

  handleDocvizPreview = (kpId, ...kpData) => {
    const { getDocvizMetadata } = this.props;
    const {
      match: {
        params: { cid },
      }
    } = this.props;
    const { CP_CREATION } = PAGES;
    this.setState({
      kpDocvizAnalyticsData: {
        id: cid,
        paRecommended: kpData[2],
        category: CP_CREATION
      }
    });
    getDocvizMetadata(kpId);
  };

  fetchRelatedCases = (keyword) => {
    const { searchRelatedCases } = this.props;
    const {
      CASES_SEARCH_PAGINATION: {
        PAGE_SIZE,
        PAGE_NUMBER,
      }
    } = GLOBAL_CONFIG;
    const payload = {
      caseNumber: keyword,
      doExactMatch: false,
      pageNumber: PAGE_NUMBER,
      pageSize: PAGE_SIZE,
    };
    searchRelatedCases(payload);
  };

  onLoadAnalytics = () => {
    const {
      match: {
        params: { cid },
      }
    } = this.props;
    const {
      CASE_PAGE_CREATION: PAGES_CASE_PAGE_CREATION
    } = PAGES;
    const {
      CASE_PAGE_CREATION: PC_CASE_PAGE_CREATION
    } = PRIMARY_CATEGORY;
    const { CASE_PAGE } = TOOLNAME;
    ANALYTICS.page.initialize({ pageName: PAGES_CASE_PAGE_CREATION, category: PC_CASE_PAGE_CREATION, toolName: CASE_PAGE, trackPageLoad: true, pageId: { caseID: cid } });
  }

  /**
   * Common fucntion to check if serror state have same key which is present in
   * validationDictionary object if yes then return true else return false
   * @param {*} errorState 
   * @param {*} stepName 
   * @returns 
   */
  checkIsErrorStateHaveStepKey = (errorState, stepName) => {
    let isKeyVisible = false;
    let allValidationKeys = Object.keys(validationDictionary[stepName])
    for (let key in errorState) {
      if (allValidationKeys.indexOf(key) > -1) {
        isKeyVisible = true
      }
    }
    return isKeyVisible;
  }

  /**
   * Function to show validation validaiotn icon, if related case step have 
   * validation error
   * @param {*} errorState 
   * @param {*} case_step_name 
   * @returns 
   */
  headingValidationIconCheck = (errorState, case_step_name) => {
    let validationCheck = false
    if (errorState && Object.keys(errorState).length > 0) {
      if (case_step_name === CP_STEP1) {
        validationCheck = this.checkIsErrorStateHaveStepKey(errorState, CP_STEP1);
      } else if (case_step_name === CP_STEP2) {
        validationCheck = this.checkIsErrorStateHaveStepKey(errorState, CP_STEP2);
      }
    }
    return validationCheck;
  }

  setOfficeWarningFlag = (showOfficeWarning) => {
    this.setState({ showOfficeWarning })
  }

  render() {
    const {
      match: { params },
      // history,
      timeAndBillingData: {
        caseNumber,
        caseType,
        openDate,
        closeDate,
        cmsGuid,
        unsanitizedCaseTitle,
        clientName,
        clientBUName,
        industryPracticeAreas,
        functionalPracticeAreas,
        pipeFlag,
        bcgTurn,
        digitalAnalyticsFlag,
        fpaStatus,
        caseTeam,
        unsanitizedCaseDesc,
        source,
      },
      caseCaptureStatus,
      knwOwnerHrId,
      isSavingMetadata,
      isLoadingMetadata,
      FPAData,
      assetsDetails,
      resetSearchResults,
      searchResults,
      totalSearchResults,
      isSearchingCases,
      caseTypes,
      getCaseOpenAndCloseDate,
      peopleResultsActions: {
        getPeopleDataUsingIDs
      },
      isTBLoading,
      getOfficeList,
      getCaseHistory,
      expertTagsIds,
      subjectTagsIds,
      getKPFile,
      downloadAllStatus
    } = this.props;
    const isDisplaySection = true;
    let pillContent = EMPTY_VALUE;
    const {
      CP_CONTRIBUTION: {
        SAVING,
      },
      BUTTONS: {
        SAVE,
      },
    } = LABELS;
    const { otherProps } = DOCVIZ_CONFIG;

    const {
      // activeStep1,
      // activeStep2,
      // activeStep3,
      errorState,
      // mainKPState,
      activeStep,
      unSanitizedCaseData,
      caseState: {
        isWithdrawn,
        sanitizedCaseTitle,
        relatedCasesAndRelationshipType,
      },
      kpDocvizAnalyticsData,
      isPageRestricted,
      showOfficeWarning,
      initialCaseOffice
    } = this.state;

    const caseInfo = {
      id: cmsGuid,
      caseStatus: getContentToDisplay(caseCaptureStatus, ""),
      caseNumber: getContentToDisplay(caseNumber, ""),
      caseOpenDate: openDate,
      caseCloseDate: closeDate,
      caseType,
      unsanitizedCaseTitle: getContentToDisplay(unsanitizedCaseTitle, EMPTY_VALUE),
      unsanitizedCaseDesc: getContentToDisplay(unsanitizedCaseDesc, EMPTY_VALUE),
      clientName: getContentToDisplay(clientName, EMPTY_VALUE),
      clientBUName: getContentToDisplay(clientBUName, EMPTY_VALUE, (clientName?.toLowerCase() !== clientBUName?.toLowerCase())),
      knwOwnerHrId,
      industryPracticeAreas: getContentToDisplay(industryPracticeAreas, []),
      functionalPracticeAreas: getContentToDisplay(functionalPracticeAreas, []),
      FPAData: getContentToDisplay(FPAData, {}),
      pipeFlag: getContentToDisplay(pipeFlag, ""),
      bcgTurn: getContentToDisplay(bcgTurn, ""),
      digitalAnalyticsFlag: getContentToDisplay(digitalAnalyticsFlag, ""),
      fpaStatus: getContentToDisplay(fpaStatus, ""),
      source: getContentToDisplay(source, ""),
    };
    // Prepare Footer Buttons Config: Hide/Show/Disable Footer buttons
    const { footerWorkflow: CASE_FOOTER_CONFIG } = CONFIG;
    const saveBtn = CASE_FOOTER_CONFIG[1].children[0];
    if (isSavingMetadata || isLoadingMetadata) {
      saveBtn.disabled = true;
    }
    if (isSavingMetadata) {
      saveBtn.text = SAVING;
    }
    if (!isSavingMetadata && !isLoadingMetadata) {
      saveBtn.disabled = false;
      saveBtn.text = SAVE;
    }
    const {
      ERROR_CASE_RESTRICTED_MDP,
      ERROR_INVALID_CASE_GUID
    } = CONSTANTS;
    const { cid } = params;
    const validId = isValidCaseGuid(cid);


    const caseWithdrawn = <Form.Group>
      <Form.Field>
        <Checkbox
          onChange={() => this.handleChange(!isWithdrawn, "isWithdrawn")}
          checked={isWithdrawn}
          name="isCaseWithdrawn"
          label={WITHDRAWN}
          className="custom__checkbox"
        />
      </Form.Field>
    </Form.Group>
    return (
      <Layout
        id="layout__casepage"
        layoutClass={`layout__casepage ${isPageRestricted ? 'layout__casepage--restricted' : ''}`}
        additionalInfoFlag={() => (!isPageRestricted ? <AdditionalInfo label={HEADER_RIGHT_BUTTON} guid={cid} getCaseHistory={getCaseHistory} /> : null)}
        breadCrumbFlag={!isPageRestricted}
        bannerFlag={!isPageRestricted}
        contentStage={pillContent}
        // productName={LABELS.KNOWLEDGE_PRODUCT_NAME.KP}
        pageTitle={PAGE_TITLE}
      >

        {(isLoadingMetadata ?
          <Loader
            isVisible={true}
          /> :
          (!validId || isPageRestricted ?
            <ErrorPage
              errorCodeProp={!validId ? ERROR_INVALID_CASE_GUID : ERROR_CASE_RESTRICTED_MDP}
            />
            :
            <>
              <Helmet>
                <title>{`Edit Case${caseNumber ? ": " + caseNumber : ""}${sanitizedCaseTitle ? " - " + sanitizedCaseTitle : ""}`}</title>
              </Helmet>

              {this.state.docvizOpen && <GlobalStyle />}
              {this.state.docvizOpen && (
                <DocvizOverlayWrapper
                  downloadAllStatus={downloadAllStatus}
                  doc={this.props.docvizDoc}
                  {...otherProps}
                  downloadStatus={this.props.downloadStatus}
                  kpDocvizAnalyticsData={kpDocvizAnalyticsData}
                />
              )}
              {isDisplaySection &&
                (<Form widths="equal" autoComplete="off">
                  <div className="layout__page casepage">
                    <TabComponent
                      className="tabs__container tabs__casepage"
                      defaultActiveIndex={activeStep}
                      getActiveIndex={this.setActiveStep}
                      onTabChangeCallback={this.stepChanged}
                      menu={{ secondary: true, pointing: true }}
                      hideCount
                      arrayOfPaneElements={[
                        {
                          className: 'item-style',
                          heading: <span>{STEP1_TITLE} {this.headingValidationIconCheck(errorState, CP_STEP1) && <Image src={warningIcon} />}</span>,
                          component: <CPStep1
                            handleChange={(...params) =>
                              this.handleChange(...params, CP_STEP1)
                            }
                            unSanitizedCaseData={unSanitizedCaseData}
                            stepState={this.getStepFormData(CP_STEP1)}
                            caseInfo={caseInfo}
                            // mainKPState={mainKPState}
                            errorState={errorState}
                            subjectTagsIds={subjectTagsIds}
                            expertTagsIds={expertTagsIds}
                            onLoadAnalytics={this.onLoadAnalytics}
                            officeList={getOfficeList}
                            showOfficeWarning={showOfficeWarning}
                            setOfficeWarningFlag={this.setOfficeWarningFlag}
                            initialCaseOffice={initialCaseOffice}
                          // getFormData={this.getFormData}
                          />
                        },
                        {
                          className: 'item-style',
                          heading: <span>{STEP2_TITLE} {this.headingValidationIconCheck(errorState, CP_STEP2) && <Image src={warningIcon} />}</span>,
                          component: <CPStep2
                            handleChange={(...params) =>
                              this.handleChange(...params, CP_STEP2)
                            }
                            unSanitizedCaseData={unSanitizedCaseData}
                            caseInfo={caseInfo}
                            searchRelatedCases={this.fetchRelatedCases}
                            onLoadAnalytics={this.onLoadAnalytics}
                            resetSearchResults={resetSearchResults}
                            searchResults={searchResults}
                            totalSearchResults={totalSearchResults}
                            isSearching={isSearchingCases}
                            relatedCasesAndRelationshipType={relatedCasesAndRelationshipType}
                            caseTypes={caseTypes}
                            getCaseOpenAndCloseDate={getCaseOpenAndCloseDate}
                            cid={cid}
                            errorState={errorState}
                          // mainKPState={mainKPState}
                          // errorState={errorState}
                          // getFormData={this.getFormData}
                          />
                        },
                        {
                          className: 'item-style',
                          heading: STEP3_TITLE,
                          component: <CPStep3
                            handleChange={(...params) =>
                              this.handleChange(...params, CP_STEP3)
                            }
                            unSanitizedCaseData={unSanitizedCaseData}
                            caseInfo={caseInfo}
                            stepState={this.getStepFormData(CP_STEP3)}
                            getPeopleDataUsingIDs={getPeopleDataUsingIDs}
                            caseTeamHours={caseTeam}
                            isTBLoading={isTBLoading}
                            onLoadAnalytics={this.onLoadAnalytics}
                          // mainKPState={mainKPState}
                          // errorState={errorState}
                          // getFormData={this.getFormData}
                          />
                        },
                        {
                          className: 'item-style',
                          heading: STEP4_TITLE,
                          component: <CPStep4
                            handleChange={(...params) =>
                              this.handleChange(...params, CP_STEP4)
                            }
                            unSanitizedCaseData={unSanitizedCaseData}
                            caseInfo={caseInfo}
                            stepState={this.getStepFormData(CP_STEP4)}
                            fetchRecentContributions={this.fetchRecentContributions}
                            searchKnowledgeItems={this.searchKnowledgeItems}
                            assetsDetails={assetsDetails}
                            addKnowledgeAsset={this.addKnowledgeAsset}
                            handleDocvizPreview={this.handleDocvizPreview}
                            onLoadAnalytics={this.onLoadAnalytics}
                            getKPFile={getKPFile}
                          // mainKPState={mainKPState}
                          // errorState={errorState}
                          // getFormData={this.getFormData}
                          />
                        },
                        {
                          className: 'item-style',
                          heading: caseWithdrawn,
                          disable: true,
                          hiddenClassName: "tabs__casepage__withdrawn"
                        },
                        {
                          className: 'item-style',
                          disable: true,
                          heading: <FooterWorkflow
                            buttons={[...CASE_FOOTER_CONFIG]}
                            getEvent={(e) => this.footerButtonsClick(e)}
                            isButtonAtTop={true}
                          />,
                          hiddenClassName: 'tabs__casepage__footerWorkflow'
                        }
                      ]}
                    />
                  </div>
                </Form>)
              }
            </>
          )
        )}
      </Layout>
    );
  }
}

CasePageContainer.defaultProps = {
  timeAndBillingData: {},
};

const mapStateToProps = (state) => ({
  timeAndBillingData: caseMetadataSelectors.getTimeAndBillingData(state),
  caseSamData: caseMetadataSelectors.getCaseSamData(state),
  caseOpenAndCloseDate: caseMetadataSelectors.getCaseOpenAndCloseDate(state),
  caseCaptureStatus: caseMetadataSelectors.getCaseCaptureStatus(state),
  knwOwnerHrId: caseMetadataSelectors.getKnwOwnerHrId(state),
  caseMetadata: caseMetadataSelectors.getMetadata(state),
  isLoadingMetadata: caseMetadataSelectors.getMetadataLoading(state),
  isSavingMetadata: caseMetadataSelectors.getMetadataSaving(state),
  FPAData: caseMetadataSelectors.getFPAData(state),
  assetsDetails: associatedKPSelectors.getAssociatedKPs(state),
  docvizDoc: docvizSelectors.getDocvizDocument(state),
  isDocvizLoading: docvizSelectors.getLoading(state),
  downloadStatus: docvizSelectors.getDownloadStatus(state),
  expertTagsIds: taxonomySelector.getExpertTagsIdsOnly(state),
  subjectTagsIds: taxonomySelector.getSubjectTagsIdsOnly(state),
  searchResults: caseMetadataSelectors.getSearchResults(state),
  totalSearchResults: caseMetadataSelectors.getTotalSearchResults(state),
  isSearchingCases: caseMetadataSelectors.getSearching(state),
  caseTypes: caseMetadataSelectors.getCaseTypes(state),
  isTBLoading: caseMetadataSelectors.getTimeAndBillingLoading(state),
  getOfficeList: convertArrayToObject(typeAheadResultsSelectors.getTypeAheadResults(state, "office")),
  downloadAllStatus: kpSelectors.getLoadingDownloadAll(state)
});

const mapDispatchToProps = (dispatch) => ({
  notifyWarning: (title, msg) => dispatch(notifyWarning(title, msg)),
  dismissNotifications: () => dispatch(dismissNotifications()),
  peopleResultsActions: bindActionCreators(
    { ...peopleResultsActions },
    dispatch
  ),
  knowledgeItemActions: bindActionCreators(
    { ...knowledgeItemActions },
    dispatch
  ),
  globalActions: bindActionCreators({ enableBodyOverflow, disableBodyOverflow }, dispatch),
  getTimeAndBilling: (cid) => dispatch({ type: GET_CASE_T_AND_B, payload: { cid } }),
  getSamData: (cid) => dispatch(getCaseSam(cid)),
  getCCOResults: (data, isByName) => dispatch({ type: GET_CCO_RESULTS, payload: { data, isByName } }),
  getCaseTypes: () => dispatch({ type: GET_CASE_TYPES }),
  getCaseMetadata: (cid) => dispatch({ type: GET_CASE_METADATA, payload: { cid } }),
  getCaseOpenAndCloseDate: (cid) => dispatch({ type: GET_CASE_TB_DATES, payload: { cid } }),
  getCmp: (cid) => dispatch({ type: GET_CASE_CMP, payload: { cid } }),
  saveCaseMetadata: (payload) => dispatch({ type: SAVE_CASE_METADATA, payload }),
  getCaseFPADetails: (cid) => dispatch({ type: GET_CASE_FPA_DETAILS, payload: { cid } }),
  saveAssociatedKPs: (assets) => dispatch({ type: KNOWLEDGE_ITEMS.SAVE_ASSOCIATED_KP, payload: { kpdata: assets } }),
  getDocvizMetadata: (id) => dispatch({ type: DOCVIZ.GET_DOCVIZ_METADATA, payload: { id } }),
  downloadSelectedSlides: (pages, documentId, docvizDoc) => dispatch({
    type: DOCVIZ.DOWNLOAD_SELECTED_SLIDES,
    payload: {
      pages,
      documentId,
      doc: docvizDoc,
      appName: CASE_PAGE_APP_NAME
    }
  }),
  searchRelatedCases: (payload) => dispatch({ type: SEARCH_CASES, payload }),
  resetSearchResults: () => dispatch({ type: RESET_SEARCH_RESULTS }),
  getCaseHistory: (caseId, count) => dispatch(getCaseHistory(caseId, count)),
  getKPFile: (kpId) => dispatch({
    type: KP.GET_FILE,
    payload: {
      kpId: kpId,
      appName: CASE_PAGE_APP_NAME,
      type: GLOBAL_CONFIG.downloadTypeForKP
    }
  })
});

export const CasePage = connect(
  mapStateToProps,
  mapDispatchToProps
)(CasePageContainer);

export { CasePageContainer };
