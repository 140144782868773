//////////// Importing Constant Files ////////
import LABELS from "labels";

// Global constants

const KP_REQUIRED_FIELDS = {
  STEP1_REQUIRED_FIELDS: ['Title', 'Description', 'File', 'Url', 'Cases', 'BST', 'Subjects', 'KPType', 'MDPApproval'],
  STEP2_REQUIRED_FIELDS: ['Authors', 'MDPs'],
  STEP3_REQUIRED_FIELDS: ['Office', 'Regions', 'Language', 'InternalOffice'],
}

const KP_STEPS = {
  STEP1: 'step1',
  STEP2: 'step2',
  STEP3: 'step3'
}

const {
  KP: { STEPS: { STEP1, STEP2, STEP3 } },
  TAXONOMY: {
    TOOLTIPS: {
      TAG_SPECIFIC_TO_GEO_KNOWLEDGE_TEAM,
      TAG_SPECIFIC_FOR_CLIENT_AND_BUSINESS_UNIT,
      TAG_SPECIFIC_TO_GEO_AUTO_TAG,
      TAG_SPECIFIC_TO_REVENUE_ALLOCATION_TAG,
      TAG_SPECIFIC_TO_BCG_TRANSFORM_TAG,
      TAG_SPECIFIC_TO_BCG_PIPE_TAG
    }
  } } = LABELS;

const globalConstants = {
  ACTIONS: {
    GLOBAL_SAGA_FAILURE: "GLOBAL_SAGA_FAILURE",
    GLOBAL_MODULE_FAILURE: "GLOBAL_MODULE_FAILURE"
  },
  LABEL_VALUE: "<lv>",
  PUBLISHED: "Published",
  KP: "kp",
  KNOWLEDGEBUNDLE: "knowledgeBundle",
  TOPICCOLLECTION: "topicCollection",
  TAB: "tab",
  TAB1: "tab1",
  TAB2: "tab2",
  TAB3: "tab3",
  STEP1: "step1",
  STEP2: "step2",
  STEP3: "step3",
  STEP4: "step4",
  KNOWLEDGE_ACTION_FLAVOR: {
    // Knowledge modes
    REGULAR: "regular",
    LIST: "list"
  },
  ENTITLEMENTS: {
    KNOWLEDGE_CONTRIBUTIONS: "KNOWLEDGE_CONTRIBUTIONS",
    CASE_PAGES: "CASE_PAGES",
    VIEW: "VIEW",
    TAG_FRAMEWORK: 'TAG_FRAMEWORK',
    CREATE_EDIT_DRAFT: "CREATE_EDIT_DRAFT",
    PREVIEW_DOWNLOAD: "PREVIEW_DOWNLOAD",
    PREVIEW_DOWNLOAD_R: "PREVIEW_DOWNLOAD_R",
    EDIT_PUBLISHED_CONTENT: "EDIT_PUBLISHED_CONTENT",
    PUBLISH_CONTENT: "PUBLISH_CONTENT",
    SHOW_BUNDLE: "SHOW_BUNDLE",
    PRODUCTID: "PRODUCTID",
    ALL: "ALL",
    VIEW_CASE_DETAIL: "VIEW_CASE_DETAIL",
    VIEW_CLIENT_INFO: "VIEW_CLIENT_INFO",
    VIEW_BUDGET: "VIEW_BUDGET",
    EDIT: "EDIT",
    EDIT_TOPIC_TAGS: "EDIT_TOPIC_TAGS",
    VIEW_CASE_MATERIALS: "VIEW_CASE_MATERIALS",
  },
  API_STATUS: {
    START: "Start",
    PENDING: "Pending",
    COMPLETED: "Completed",
    ERROR: "Error"
  },
  KNOWLEDGE_ENTITY_ACTIONS: {
    DELETE: "delete",
    EDIT: "edit",
    PREVIEW: "preview",
    ARCHIVE: "archive",
    UNPUBLISH: "unpublish",
    MOVETODRAFT: "movetodraft"
  },
  REGIONS: {
    GLOBAL: "global",
    OTHERS: "others"
  },
  INTERNAL_OFFICE: {
    GLOBAL: "global",
    OTHERS: "others"
  },
  TAXONOMY_KEYS: {
    GLOBAL_REGION: "GLOBAL_REGION",
    GLOBAL_INTERNAL_OFFICE: "GLOBAL_INTERNAL_OFFICE"
  },
  ERROR_401: "401",
  ERROR_IE: "ie11",
  ERROR_ENTITLEMENT_API: "entitlementapi",
  ERROR_INVALID_CASE_GUID: "errorinvalidcaseguid",
  ERROR_CASE_RESTRICTED_MDP: "caserestrictedmdp",
  TBDBS: {
    SEARCH_SUBJECT: "SEARCH_SUBJECT",
    SEARCH_FPA_IPA_INTERNAL: "SEARCH_FPA_IPA_INTERNAL",
    FPA: "FPA",
    IPA: "IPA",
    BCG_INTERNAL: "BCG_INTERNAL"
  },
  CP_STEPS_VALIDATION: "cases_validation",
  CP_STEP1: "caseoverview",
  CP_STEP2: "caseinsights",
  CP_STEP3: "caseteam",
  CP_STEP4: "knowledgeassets",
  ON_CHANGE_MDP_STATUS: {
    ADD: 'add',
    DELETE: 'delete'
  },
  MYNAVIGATOR_TABS: {
    ACTIVITIES: "activities",
    CONTRIBUTIONS: "contributions",
    MY_CASES: "mycases",
    DRAFT: "draft"
  },
  CONSUMPTION_RATING: "CONSUMPTION_RATING",
  VIRTUAL_PAGE_VIEW: "virtualPageView",
  DOCVIZ_EXTNS: [
    "doc",
    "docx",
    "pdf",
    "ppt",
    "pptx",
    "ppsx"
  ],
  FILE_EXTN_RECOMMENDED_TAGS: [
    "ppt",
    "pptx"
  ],
  SECTION1: "section1",
  SECTION2: "section2",
  CASE_TYPE_LIST: {
    FOLLOW_ON: "Follow-on",
    CONVERTED: "Converted",
    RELATED: "Related",
    MIGRATED: "Migrated"
  },
  KNOWLEDGE_PAGE_TYPE: {
    DEFAULT_CONTENT_TYPE: "Content_Type",
    TOOLTIP_DESCRIPTION: "description",
    TOOLTIP_ADDITIONAL_INFO: "additional information"
  },
  SUGGESTIONS_TEXONOMY: 'taxonmy',
  SUGGESTIONS_AUTHORS: 'authors',
  KP_STEPS_VALIDATION: {
    [KP_STEPS.STEP1]: {
      PAGE_NAME: STEP1,
      FIELDS: [...KP_REQUIRED_FIELDS.STEP1_REQUIRED_FIELDS]
    },
    [KP_STEPS.STEP2]: {
      PAGE_NAME: STEP2,
      FIELDS: [...KP_REQUIRED_FIELDS.STEP2_REQUIRED_FIELDS]
    },
    [KP_STEPS.STEP3]: {
      PAGE_NAME: STEP3,
      FIELDS: [...KP_REQUIRED_FIELDS.STEP3_REQUIRED_FIELDS]
    }
  },
  VALIDATION_KEY: 'validate',
  PEOPLE_TITLE: {
    ASSOCIATE: 'Associate',
    CONSULTANT: 'Consultant',
    PROJECT_LEADER: 'Project Leader',
    PRINCIPAL: 'Principal',
    PARTNER: 'Partner'
  },
  USABILLA_SURVEY_KEYS: {
    TC_GRID: 'TC_GRID',
    FACECARD_DOWNLOAD: "facecard_download",
    AI_SUGGESTIONS: "ai_suggestions",
  },
  AUTO_TAG_TYPE_TOOLTIP_CONFIG: {
    KP: {
      KP_GEO: TAG_SPECIFIC_TO_GEO_KNOWLEDGE_TEAM,
    },
    CASE: {
      C_GEO: TAG_SPECIFIC_TO_GEO_AUTO_TAG,
      C_CNBU: TAG_SPECIFIC_FOR_CLIENT_AND_BUSINESS_UNIT,
      C_RA: TAG_SPECIFIC_TO_REVENUE_ALLOCATION_TAG,
      C_BCGT: TAG_SPECIFIC_TO_BCG_TRANSFORM_TAG,
      C_PIPE: TAG_SPECIFIC_TO_BCG_PIPE_TAG
    }
  },
  AUTO_TAG_TYPE_CONFIG: {
    KP_GEO: "KP_GEO",
    C_GEO: "C_GEO",
    C_CNBU: "C_CNBU",
    C_RA: "C_RA",
    C_BCGT: "C_BCGT",
    C_PIPE: "C_PIPE"
  }
}

export default globalConstants;