import { getLengthOfMultiArray, findCommonElements, checkForFieldSpecificValidation, getNumbersOfWords, checkValidationsForArrayOfObjects, getLengthOfMultiArrayWithPoly } from "utils/helpers/helpers";
import CONFIG from 'config';
import { getDescriptionCharLength } from "components/shared/RichEditor/RichEditor.helper.js"

export const isValidInput = (inputValue, obj, mainState, extraValidationConfig) => {
  let isValid = true;
  let message = '';
  let wordsCount = 0;

  if (obj && obj.onEmpty) {
    if (obj?.numberOfWordsCheck && inputValue.length > 0) {
      wordsCount = getNumbersOfWords(inputValue);
    }

    if (
      (obj.type === "array" || obj.type === "string") && !(inputValue &&
        inputValue.length)
    ) {
      isValid = false;
      message = obj.onEmpty.message;
    } else if (obj.type !== "richHTML" && obj.minLengthCheck && inputValue.length < obj.minLengthCheck.value && inputValue.length > 0) {
      isValid = false;
      message = obj.onEmpty.message;
    } else if (extraValidationConfig?.isInternal && obj?.numberOfWordsCheck && wordsCount < obj.numberOfWordsCheck.value && inputValue.length > 0) {
      isValid = false;
      message = obj.onEmpty.message;
    } else if (obj.maxLengthCheck && inputValue.length > obj.maxLengthCheck.value && inputValue.length > 0) {
      isValid = false;
      message = obj.onEmpty.message;
    } else if (obj.type === "multipleArray" && !getLengthOfMultiArray(inputValue[0])) {
      isValid = false;
      message = obj.onEmpty.message;
    } else if (obj.type === "multipleArray" && obj.maxLengthCheckOnTotal && getLengthOfMultiArrayWithPoly(inputValue) > obj.maxLengthCheckOnTotal.value) {
      isValid = false;
      message = obj.onEmpty.message;
    } else if (obj.type === "array" && checkForFieldSpecificValidation(obj, mainState, inputValue)) {
      isValid = false;
      message = obj.onEmpty.message;
    } else if (obj.type === "file" && (!(inputValue && inputValue.name && !!obj.fileExistsOnBucket) || obj.uploadFlag)) {
      isValid = false;
      message = obj.onEmpty.message;
    } else if (obj.type === "arrayOfObjects") {
      if (inputValue && Array.isArray(inputValue) && obj && obj.required_object_field && obj.required_object_field.key) {
        let validation_response = checkValidationsForArrayOfObjects(inputValue, obj.required_object_field.key);
        if (validation_response) {
          isValid = false;
          message = obj.onEmpty.message;
        }
      }
    }
    else if (
      obj.type === "richHTML" && extraValidationConfig
    ) {
      let descriptionLength = getDescriptionCharLength(inputValue);
      if (extraValidationConfig.descriptionLength === 0 && !inputValue.replace(/<\/?[a-zA-Z][a-zA-Z0-9]*(?:\s+[a-zA-Z_:][a-zA-Z0-9:_.-]*(?:="[^"]*")?)*\s*\/?>/g, "").trim().length) {
        isValid = false;
        message = obj.onEmpty.message;
      }
      else if (obj.minLengthCheck && descriptionLength < obj.minLengthCheck.value && extraValidationConfig?.isInternal) {
        isValid = false;
        message = obj.onEmpty.message;
      }
    } else if (obj.isPartOf) {
      const matchdata = mainState[obj.isPartOf.value];
      if (inputValue.length && matchdata.length && matchdata.indexOf(inputValue[0]) === -1) {
        isValid = false;
        message = obj.isPartOf.message;
      }
    } else if (obj.isContains) {
      const matchdata = mainState[obj.isContains.value];
      if (inputValue.length && matchdata.length && inputValue.indexOf(matchdata[0]) === -1) {
        isValid = false;
        message = obj.isContains.message;
      }
    } else if ((obj.type === "object") && (Object.keys(inputValue).length === 0 || !(Object.keys(inputValue).some((k) => inputValue[k] !== "")))) {
      isValid = false;
      message = obj.onEmpty.message;
    }
    else if (obj.type === "boolean" && !inputValue) {
      isValid = false;
      message = obj.onEmpty.message;
    } else if (obj.isKeyDependent) {
      const checkState = mainState[obj.isKeyDependent.value];
      if (checkState && (obj.isKeyDependent.type === "array" || obj.isKeyDependent.type === "string") && !inputValue.length) {
        isValid = false;
        message = obj.onEmpty.message;
      }
    } else if (extraValidationConfig?.isAPIValidation && obj.uniqueTitleCheck) {
      //This is for unique title error message
      isValid = false;
      message = obj.uniqueTitleCheck.message;
    }
  }
  return { isValid, message };
};

/**
 * 
 * @param {*} msgList : {data: [{field: 'Title', 'message' : 'Filed Required'}], id:'abc'}
 * @param {*} stepsValidationConfig constanr array
 * @returns [{title: 'STEP 1', isSuccess: false, link: '' }]
 */
export const setKpStepsValidationData = (msgList, stepsValidationConfig) => {
  let { data, id: kpId } = msgList;

  let allStepsKeys = [];
  data.map(item => {
    allStepsKeys.push(item.field)
    return item
  })

  let stepsList = [];
  for (let key in stepsValidationConfig) {
    const { PAGE_NAME, FIELDS } = stepsValidationConfig[key];
    let obj = {
      title: PAGE_NAME,
      isSuccess: true,
      link: ''
    }
    if (findCommonElements(FIELDS, allStepsKeys)) {
      obj.isSuccess = false;
      obj.link = CONFIG.UI_URL.METADATA_KP_VALIDATE(key, kpId);
    }
    stepsList.push(obj)
  }
  return stepsList
}
