import React from 'react';
import { Image, Button } from 'semantic-ui-react';
import { connect } from "react-redux";
// Adobe Analytics file
import ANALYTICS from "utils/analytics/analytics";
import {
  DTMRULE,
  PRIMARY_CATEGORY,
  PAGES,
  TRIGGERS,
  NOT_AVAILABLE
} from "utils/analytics/analytics_constants";
import { WarningModal } from "components/WarningModal/WarningModal";
import CONFIG from "config";
import LABELS from "labels";
import { getUserId } from "utils/auth/auth";
import './ConsumptionOptions.scss';
import GLOBAL_CONSTANTS from "globalConstants";
import history from "redux/history";
import { KnowledgeEntityActions } from "components/KnowledgeEntityActions";
import dotsImage from 'assets/images/dots-image.svg';
import PropTypes from 'prop-types';
import { KNOWLEDGEOBJECT_VERSIONING } from 'redux/constants';
import {
  notifyError,
  dismissNotifications,
} from "redux/actions/notification/notification_actions";
import { selectors as mynavigatorSelectors } from "redux/reducers/mynavigator/mynavigator_reducer";
import { selectors as versioningSelectors } from "redux/reducers/versioning/versioning_reducer";
import { selectors as knowledgehistory } from "redux/reducers/knowledgehistory/knowledgehistory_reducer";
import { getAdminHistory } from "redux/actions/knowledgehistory/knowledgehistory.actions";
import AdminHistoryModal from 'components/AdminHistoryModal/AdminHistoryModal';
import { formatDate, getFileIcon, getNumberInTwoDigits, routeToInQueueStatus } from 'utils/helpers/helpers';



const {
  MY_NAVIGATOR: {
    ENTITY_ACTIONS_MODAL_TITLES: {
      KP_NEW_UNPUBLISHED_MODAL_TITLE
    }
  },
} = LABELS;
const { SITECORE_XM_ENABLED } = CONFIG;
export class ConsumptionOptionsContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      menuIsOpen: false,
      isModalOpen: false,
      updatedEntity: {},
      showAdminModal: false,
      isUnPublishSuccess: false
    };
    this.myRef = React.createRef();
  }

  handleAnalytics = async (id, trigeer) => {
    const hrid = await getUserId();
    const newAdobeData = {
      category: {
        primaryCategory: PRIMARY_CATEGORY.KP
      },
      collection: {
        trigger: trigeer ? trigeer : TRIGGERS.KP_EDIT,
        screenName: PAGES.KP_CONSUMPTION,
      },
      authorStaffID: hrid,
      search: {
        term: NOT_AVAILABLE
      },
      KCpage: {
        id: id,
      }
    };
    ANALYTICS.kc.sendEventData(newAdobeData, DTMRULE.KNOWLEDGE_PAGE_ACTION);
  }

  handleCloseFromModal = () => {
    this.setState({ menuIsOpen: false });
  }

  warningModalCallBack = (isOpen) => {
    this.setState({ isModalOpen: isOpen })
  }

  toggleOptions = (event) => {
    event.stopPropagation();
    this.setState({ menuIsOpen: !this.state.menuIsOpen });
  }

  componentDidMount = () => {
    const {
      getAdminHistory,
      entity: {
        id
      },
      isBundle,
    } = this.props;
    if (id && !isBundle) {
      getAdminHistory(id)
    }
    document.addEventListener('mousedown', this.handleClickOutside);
    document.addEventListener('scroll', this.handleScroll);
  }

  componentWillUpdate = (prevProps) => {
    if (prevProps.apiErrorStatus !== this.props.apiErrorStatus) {
      this.showErrorNotification();
    }
    if (prevProps.apiStatus !== this.props.apiStatus) {
      setTimeout(
        () => this.props.getConsumptionData()
        , 2000);
    }
  }

  componentWillUnmount = () => {
    document.removeEventListener('mousedown', this.handleClickOutside);
    document.removeEventListener('scroll', this.handleScroll);
  }

  /**
   * Hide Popup if outside is clicked
   */
  handleClickOutside = (event) => {
    if (this.myRef && this.myRef.current && !this.myRef.current.contains(event.target) && !event.target.className.includes('kbeWarning') && !event.target.className.includes('ellipsiswrapper__')) {
      this.setState({ menuIsOpen: false });
    }
  }

  /**
   * Hide Popup on scroll
   */
  handleScroll = () => {
    this.setState({ menuIsOpen: false });
  }

  showErrorNotification = () => {
    const {
      notifyError,
      dismissNotifications,
    } = this.props;
    const { ERROR_MESSAGES: { DOWNLOAD_ERROR: generalErrorMSg } } = LABELS;
    dismissNotifications();
    setTimeout(() => notifyError('', generalErrorMSg), 200);
  }

  actionHandler = (event, source, entity) => {
    event && event.stopPropagation();
    const {
      unpublishKnowledgeAsset,
      isBundle,
      isCollection,
      archiveKnowledgeAsset
    } = this.props;
    const {
      KNOWLEDGE_ENTITY_ACTIONS: {
        UNPUBLISH,
        ARCHIVE
      }
    } = GLOBAL_CONSTANTS;

    if (entity) {
      const id = isBundle || isCollection ? entity.kbId : entity.id;
      try {
        if (source === UNPUBLISH) {
          unpublishKnowledgeAsset(id, true);
        } else if (source === ARCHIVE) {
          archiveKnowledgeAsset(id, true);
        }
      } catch (error) {
        this.showErrorNotification(error);
      }
    }
  }

  unpublishHandler = (event, entity) => {
    const {
      KNOWLEDGE_ENTITY_ACTIONS: {
        UNPUBLISH
      }
    } = GLOBAL_CONSTANTS;
    this.setState({ isUnPublishSuccess: true });
    const { id } = entity;
    const { KP_UNPUBLISH } = TRIGGERS
    this.handleAnalytics(id, KP_UNPUBLISH)
    this.actionHandler(event, UNPUBLISH, entity);
  }
  archiveHandler = (event, entity) => {
    const {
      KNOWLEDGE_ENTITY_ACTIONS: {
        ARCHIVE
      }
    } = GLOBAL_CONSTANTS;

    this.actionHandler(event, ARCHIVE, entity);
  };

  openAdminHistoryModal = (e) => {
    e.stopPropagation();
    this.setState({ showAdminModal: true });
  }

  openRedirectUrlFromEntityActions = (e, redirectUrl) => {
    e.stopPropagation();
    history.push({
      pathname: redirectUrl
    });
  }

  closeAdminHistoryModal = () => {
    this.setState({ showAdminModal: false });
  }

  getKnowledgeTypeForAdmin = () => {
    const {
      entity,
      isBundle,
      isCollection,
    } = this.props;
    const { materialUrl, isURLAdded } = entity;
    const { address } = materialUrl || {};
    const {
      ADMIN_HISTORY_SECTION: {
        FIELDS: {
          KB,
          TC,
          KP_FILE,
          KP_URL,
        }
      }
    } = LABELS;
    if (isBundle) {
      return KB;
    }
    if (isCollection) {
      return TC;
    }
    if (isURLAdded === '1' && address) {
      return KP_URL;
    }
    else {
      return KP_FILE;
    }
  }

  getKnowledgeAttachmentInfo = () => {
    const type = this.getKnowledgeTypeForAdmin();
    const {
      ADMIN_HISTORY_SECTION: {
        FIELDS: {
          KP_FILE,
          KP_URL,
        }
      }
    } = LABELS;
    const { entity: { attachment, materialUrl } } = this.props;
    if (type === KP_FILE) {
      return {
        icon: getFileIcon(attachment?.fileName),
        text: attachment?.fileName,
        type: KP_FILE,
      };
    }
    if (type === KP_URL) {
      return {
        icon: getFileIcon('link'),
        text: materialUrl?.title,
        type: KP_URL,
      };
    }
    return null;
  }

  getAdminFields = () => {
    const {
      ADMIN_HISTORY_SECTION: {
        FIELDS: {
          CREATED,
          OFFICE,
          DOWNLOADS,
          KP_FILE,
          KP_URL,
          TITLE,
          PUBLISHER,
        },
        KEYS: {
          CREATED: COL3,
          PERSON: COL5,
          OFFICE: COL4,
          DOWNLOADS: COL2,
          TITLE: COL1,
          KB_TITLE: COL6,
        },
      },
    } = LABELS;
    const { isBundle } = this.props;
    const ktype = this.getKnowledgeTypeForAdmin();
    const ADMIN_FIELDS = {
      col3: {
        key: COL3,
        label: CREATED,
        fixed: true,
      },
      col4: {
        key: COL4,
        label: OFFICE,
        fixed: true,
      },
      col5: {
        key: COL5,
        label: PUBLISHER,
      }
    };
    const KB_ADMIN_FIELDS = {
      col1: {
        key: COL6,
        label: TITLE,
      },
      col2: {
        key: COL2,
        label: CREATED,
        fixed: true,
      },
      col3: {
        key: COL5,
        label: PUBLISHER,
      }
    };
    if (isBundle) {
      return KB_ADMIN_FIELDS;
    }
    else if (ktype === KP_FILE) {
      return {
        col1: {
          key: COL1,
          label: KP_FILE,
        },
        col2: {
          key: COL2,
          label: DOWNLOADS,
          fixed: true,
        },
        ...ADMIN_FIELDS,
      };
    }
    else {
      return {
        col1: {
          key: COL1,
          label: KP_URL,
        },
        ...ADMIN_FIELDS,
      };
    }
  }

  setAdminValues = () => {
    const {
      downloads,
      entity,
      isBundle,
    } = this.props;
    if (isBundle) {
      return [{
        col1: entity?.title,
        col2: formatDate(entity?.originalPublishDate, "MMMM D, YYYY"),
        col3: entity?.createdBy?.hrid,
      }];
    } else {
      return [{
        col1: this.getKnowledgeAttachmentInfo(),
        col2: getNumberInTwoDigits(downloads),
        col3: formatDate(entity?.originalPublishDate, "MMMM D, YYYY"),
        col4: entity.office?.name,
        col5: entity?.createdBy
      }];
    }
  }

  render() {
    const {
      entity,
      isBundle,
      isCollection,
      isSiteAdmin,
      title,
      editIconEntitlement,
      publishEntitlement,
      isContinueEditVisible,
      adminHistoryData,
      isContributeKnowlegeVisible,
      isMyNavigatorVisible,
      isArchiveVisible,
      isEditVisible,
      isDeleteVisible,
      isDraftVisible,
      isRpaReview,
      isUnpublishLoader
    } = this.props;
    const {
      KNOWLEDGE_ENTITY_ACTIONS: {
        UNPUBLISH,
        ARCHIVE
      }
    } = GLOBAL_CONSTANTS;
    const {
      KNOWLEDGE_TYPE_KEYS: {
        BUNDLES,
        COLLECTIONS,
        PAGES: KP,
      },
      KNOWLEDGE_STATUS: {
        PUBLISHED,
      },
    } = CONFIG;
    const {
      BUNDLE_TYPE: { TOPIC_COLLECTION },
      ADMIN_HISTORY_SECTION: {
        HEADER,
      }
    } = LABELS;
    const sortedData = adminHistoryData.sort((prev, current) => new Date(current.date) - new Date(prev.date));
    const { menuIsOpen, showAdminModal, isUnPublishSuccess } = this.state;
    const isEditHidden = !isEditVisible || (entity?.status === PUBLISHED && (!editIconEntitlement || (entity?.bundleType && entity?.bundleType?.name === TOPIC_COLLECTION && !isSiteAdmin)));
    const { ADMIN_HISTORY_SECTION: { TABLE_KEYS } } = LABELS;
    return (
      <div className="ellipsiswrapper">
        <Button onClick={this.toggleOptions} id="btn-dots" className="ellipsiswrapper__ellipsis">
          <Image src={dotsImage} title={title} className="ellipsiswrapper__dots" />
        </Button>
        {menuIsOpen &&
          <div className="ui popup ellipsiswrapper__content" ref={this.myRef}>
            <KnowledgeEntityActions
              flavor={GLOBAL_CONSTANTS.KNOWLEDGE_ACTION_FLAVOR.LIST}
              entity={entity}
              entityType={isBundle ? BUNDLES : isCollection ? COLLECTIONS : KP}
              handleClose={this.handleCloseFromModal}
              handleAnalytics={!isBundle && !isCollection ? this.handleAnalytics : undefined}
              warningModalCallBack={this.warningModalCallBack}
              openInNewTab={false}
              isEditPublishedEntitlement={editIconEntitlement}
              isSiteAdmin={isSiteAdmin}
              isEditVisible={!isEditHidden && editIconEntitlement}
              isAdminHistoryVisible={true}
              isPublishContentEntitlement={publishEntitlement}
              unpublishAction={{
                callBack: this.unpublishHandler,
                action: UNPUBLISH
              }}
              archiveAction={{
                callBack: this.archiveHandler,
                action: ARCHIVE
              }}
              adminHistoryCallback={this.openAdminHistoryModal}
              openRedirectUrlCallback={this.openRedirectUrlFromEntityActions}
              isContinueEditVisible={isContinueEditVisible}
              isContributeKnowlegeVisible={isContributeKnowlegeVisible}
              isMyNavigatorVisible={isMyNavigatorVisible}
              isArchiveVisible={isArchiveVisible}
              isDeleteVisible={isDeleteVisible}
              isDraftVisible={isDraftVisible}
              isRpaReview={isRpaReview}
            />
          </div>
        }
        {SITECORE_XM_ENABLED &&
          (<WarningModal
            heading={KP_NEW_UNPUBLISHED_MODAL_TITLE}
            button1={{
              text: LABELS.BUTTONS.XMC_HOME,
              clickHandler: () => routeToInQueueStatus(history),
              colorClass: "black"
            }}
            isOpen={isUnPublishSuccess && !isUnpublishLoader}
            onRequestClose={() => { }}
            shouldCloseOnOverlayClick={false}
            shouldCloseOnEsc={false}
            iconName={"check"}
          />
          )}
        <AdminHistoryModal
          isOpen={showAdminModal}
          onRequestClose={this.closeAdminHistoryModal}
          header={HEADER}
          fields={this.getAdminFields()}
          data={this.setAdminValues()}
          adminHistoryData={sortedData}
          adminHistoryTableKey={TABLE_KEYS}
        />
      </div>
    )
  }
}
ConsumptionOptionsContainer.propTypes = {
  entity: PropTypes.object,
  inputByEmail: PropTypes.bool,
  downloads: PropTypes.number,
  editIconEntitlement: PropTypes.bool,
};

ConsumptionOptionsContainer.defaultProps = {
  entity: {},
  downloads: 0,
  inputByEmail: false,
  editIconEntitlement: false
};

const mapStateToProps = (state) => ({
  searchCriteria: mynavigatorSelectors.getsearchCriteria(state),
  apiErrorStatus: versioningSelectors.getErrorStatus(state),
  apiStatus: versioningSelectors.getApiStatus(state),
  adminHistoryData: knowledgehistory.getAdminHistory(state),
});

const mapDispatchToProps = (dispatch) => ({
  unpublishKnowledgeAsset: (id) => dispatch({ type: KNOWLEDGEOBJECT_VERSIONING.UNPUBLISH_KNOWLEDGE_ASSET, payload: { knowledgeId: id, isContributionPage: false } }),
  archiveKnowledgeAsset: (id) => dispatch({ type: KNOWLEDGEOBJECT_VERSIONING.ARCHIVE_KNOWLEDGE_ASSET, payload: { knowledgeId: id, isContributionPage: false } }),
  notifyError: (title, msg) => dispatch(notifyError(title, msg)),
  dismissNotifications: () => dispatch(dismissNotifications()),
  getAdminHistory: (KPId) => dispatch(getAdminHistory(KPId)),
});

export const ConsumptionOptions = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConsumptionOptionsContainer);