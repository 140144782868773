const CASE_VERSION_CONFIG = {
    FIELD_MAPPING_CASES: {
        "title": "Case Title",
        "description": "Case Description",
        "clientname": "Client Name",
        "clientbuname": "Client BU",
        "clientdescription": "Client Description",
        "clientbudescription": "Client Business-Unit Description",
        "office": "Office",
        "confirmedconfidential": "Confirmed Confidential",
        "curated": "Curated",
        "proposalreceived": "Proposal Received",
        "iswithdrawn": "Withdrawn",
        "caseteam": "Case Team",
        "relatedcaseandtype": "Related Case Type",
        "ipa": "Administrative & Reporting Tags",
        "fpa": "Administrative & Reporting Tags",
        "bcginternal": "Administrative & Reporting Tags",
        "subjects": "Search and Discovery Tags"
    }
}
export default CASE_VERSION_CONFIG;