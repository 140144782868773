import { PROPOSAL_BIO_ACTIONS } from "redux/constants";
export const name = "proposalbio"; 

const initialState = {
  requestId: {
    loading: false,
    error: false,
    id: ""
},
pollingAPI: {
    loading: false,
    error: null,
    data: null
},
experienceFields: {
    role: "",
    summaryRelevantExperience: "",
    profileSummary: "",
    relevantExperience: "",
    priorExperienceAndEducation: "",
},
}
export const selectors = {
getExperienceFields: (state) => state[name].experienceFields,
  getAcknowledgmentLoading: state => state[name].requestId.loading,
  getAcknowledgmentError: state => state[name].requestId.error,
  getAcknowledgmentRequestID: state => state[name].requestId?.id,

  getPollingLoading: state => state[name].pollingAPI.loading,
  getPollingError: state => state[name].pollingAPI.error,
  getPollingData: state => state[name].pollingAPI.data,
  getPeopleData: state => state[name].peopleData,
  getPeoplePayloadData: state => state[name].peoplePayloadData,

};


export function reducer(state = initialState, action) {
  const { requestId, pollingAPI } = initialState;

  switch (action.type) {
    case PROPOSAL_BIO_ACTIONS.PROPOSAL_BIO_ACKNOWLEDGEMENT_API_PENDING:
        return {
          ...state,
          requestId: {
            ...state.requestId,
            loading: true,
            error: null,
          },
        };
    case PROPOSAL_BIO_ACTIONS.PROPOSAL_BIO_ACKNOWLEDGEMENT_API_SUCCESS:
        return {
              ...state,
              requestId: {
                ...state.requestId,
                id: action.payload.requestId,
                loading: false,
              },
            };
    case PROPOSAL_BIO_ACTIONS.PROPOSAL_BIO_ACKNOWLEDGEMENT_API_FAILURE:
        return {
                  ...state,
                  requestId: {
                    ...state.requestId,
                    error: action.error,
                    loading: false,
                  },
                };


    case PROPOSAL_BIO_ACTIONS.PROPOSAL_BIO_POLLING_API_START:
        return {
                      ...state,
                      pollingAPI: {
                        ...state.pollingAPI,
                        loading: true,
                        error: null,
                      },
                    };
    case PROPOSAL_BIO_ACTIONS.PROPOSAL_BIO_POLLING_API_SUCCESS:
        return {
                          ...state,
                          pollingAPI: {
                            ...state.pollingAPI,
                            data: action.payload,
                            loading: false,
                          },
                        };
    case PROPOSAL_BIO_ACTIONS.PROPOSAL_BIO_POLLING_API_FAILURE:
        return {
                              ...state,
                              pollingAPI: {
                                ...state.pollingAPI,
                                error: action.error,
                                loading: false,
                              },
                            };

    case PROPOSAL_BIO_ACTIONS.UPDATE_EXPERIENCE_FIELDS:
        return {
                ...state,
                experienceFields: {
                                ...state.experienceFields,
                                ...action.payload, 
                                },
                };

      default:
          return state;
  }
};