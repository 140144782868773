import React from 'react';
import Unsupported from "assets/images/unsupported-preview-noslider.svg";
import { Image, Icon } from 'semantic-ui-react';
import { RestrictedDownloadIcon } from "components/shared/RestrictedDownloadIcon";
import download from 'assets/images/download.svg';
import { checkIfAllQueryStringsExist, getFileIcon, checkOrAddProtocolToUrl, showDownloadIcon } from "utils/helpers/helpers";
import PropTypes from "prop-types";
import CONFIG from 'config';
import './PreviewFileConsumption.scss';
import LABELS from 'labels';
export class PreviewFileConsumption extends React.PureComponent {
  handleLinkClick = () => {
    const { linkClickAnalytics, materialUrl: { address } } = this.props;
    linkClickAnalytics(address, '');
  }
  render() {
    const {
      isInPreviewMode,
      isMDPRestricted,
      isPreviewDownloadEntitlement,
      isPreviewDownloadREntitlement,
      previewUrl,
      fileDetails: {
        fileName,
        startFileDownload
      },
      materialUrl: {
        address,
        title: materialTitle
      }
    } = this.props;
    const { KPCONSUMPTIONGROUP: { KP_RESTRICTEDTEXT } } = LABELS;
    const { PREVIEW_MESSAGE } = LABELS.KP;
    const attachmentIcon = getFileIcon(fileName);
    const { CONSUMPTION_MAIL: { EMAIL, SUBJECT, LABELTEXT, BODY }, EMBEDDED_VIDEO_URL_TYPE } = CONFIG;
    const downloadIconVisible = showDownloadIcon(isPreviewDownloadEntitlement, isPreviewDownloadREntitlement, isMDPRestricted);
    const mailto = `mailto:${EMAIL}?subject=${SUBJECT}&body=${BODY} ${fileName} - ${previewUrl}`;
    const isEmbeddedUrl = checkIfAllQueryStringsExist(address, EMBEDDED_VIDEO_URL_TYPE);
    return (
      <div className="preview">
        {
          isEmbeddedUrl ? <iframe title={materialTitle} src={address} className="preview__frame" /> :
            <>
              <Image className="fileIcon"
                src={Unsupported}
                alt="KP Placeholder Docviz Image"
              />
              <div className="unsupportedText">
                {PREVIEW_MESSAGE}
              </div>
            </>
        }
        <div className={`preview__document ${isEmbeddedUrl && 'preview__bottomfixed'}`}>
          {fileName && (
            <>
              <Image className="preview__document-icon" src={attachmentIcon} alt="icon" />
              <span className="preview__document-filename">{fileName}</span>
              {!isInPreviewMode && isPreviewDownloadEntitlement &&
                (
                  downloadIconVisible ?
                    <Image
                      className="preview__document-download"
                      src={download}
                      onClick={startFileDownload}
                    />
                    :
                    <RestrictedDownloadIcon
                      restrictedText={KP_RESTRICTEDTEXT}
                      mailToText={LABELTEXT}
                      toolTipPosition="left"
                      mailTo={mailto}
                    />
                )}
            </>
          )}
          {materialTitle && (
            <>
              <Icon name="linkify" bordered className="slink__icon--link" />
              <a href={checkOrAddProtocolToUrl(address)} target="_blank" rel="noopener noreferrer" onClick={this.handleLinkClick} className="preview__materialLink">{materialTitle}</a>
            </>
          )}
        </div>
      </div>
    );
  }
}
PreviewFileConsumption.propTypes = {
  isInPreviewMode: PropTypes.bool,
  fileDetails: PropTypes.object,
  isPreviewDownloadEntitlement: PropTypes.object,
  isPreviewDownloadREntitlement: PropTypes.object
};

PreviewFileConsumption.defaultProps = {
  isInPreviewMode: false,
  fileDetails: {
    fileName: "", startFileDownload: () => { },
  },
  isPreviewDownloadEntitlement: false,
  isPreviewDownloadREntitlement: false
};