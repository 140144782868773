// utility for calling axios
import { doAxiosRequest } from "config-axios";
// Constant
import { META_DATA } from "redux/constants";
import GLOBAL_CONSTANTS from "globalConstants";
// Config
import CONFIG from "config";

// actions
export const actions = {
  // get Image
  getImage: () => async (dispatch) => {
    // dispatch pending state
    dispatch({ type: META_DATA.API_GET_IMAGE_PENDING, payload: "" });
    // try to get image
    try {
      const { SITECORE_XM_ENABLED } = CONFIG;
      const axiosConfig = {
        method: 'get',
        endpoint: SITECORE_XM_ENABLED ? CONFIG.API_URL.GET_IMAGE_XM : CONFIG.API_URL.GET_IMAGE,
        headers: {
          'x-api-key': CONFIG.XA
        }
      };
      // local variable with http service
      const imageList = await doAxiosRequest(axiosConfig);
      let imageData = SITECORE_XM_ENABLED ? imageList.data : imageList.value;
      // dispatch success state
      dispatch({
        type: META_DATA.API_GET_IMAGE_SUCCESS,
        payload: imageData,
      });
    } catch (error) {
      // dispatch failure state
      dispatch({
        type: META_DATA.API_GET_IMAGE_FAILURE,
        payload: error.toString(),
      });
      throw error;
    }
  },
  // save metadata
  saveMetaData: (metadata, historyObj) => async (dispatch) => {
    dispatch({
      type: META_DATA.API_SAVE_METADATA_PENDING,
      payload: metadata,
    });
    /**
       * axiosConfig: request object to collect all http request related data
       * @param method: get, post, put etc
       * @param endpoint: api url
       * @param headers: custom headers
       * @param params: data to be sent in post calls
       */

    const axiosConfig = {
      method: 'post',
      endpoint: CONFIG.API_URL.SAVE_METADATA,
      params: metadata,
      headers: {
        'x-api-key': CONFIG.XA
      }
    };
    await doAxiosRequest(axiosConfig)
      .then((response) => {
        dispatch({
          type: META_DATA.API_SAVE_METADATA_SUCCESS,
          payload: response.data,
        });
        if (historyObj) {
          const metaData = response.data;
          const { STEP2 } = GLOBAL_CONSTANTS;
          historyObj.push({
            pathname: CONFIG.UI_URL.METADATA_KB(STEP2, metaData?.kbId),
            state: { mode: historyObj.location?.state?.mode },
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: META_DATA.API_SAVE_METADATA_FAILURE,
          payload: error.toString(),
        });
        throw error;
      });
  },
  // clear meta data
  clearMetaData: () => async (dispatch) => {
    dispatch({ type: META_DATA.CLEAR_METADATA, payload: "" });
  }

};
