import { put, takeLatest, call } from 'redux-saga/effects';
// Constant
import { ENTITLEMENTS, AUTH } from "redux/constants";
// Config
import CONFIG from "config";
// Axios Util
import { doAxiosRequest } from "config-axios";
import {
    getEntitlementsPending,
    getEntitlementsSuccess,
    getEntitlementsFailure,
    checkSiteAdminPending,
    checkSiteAdminSuccess,
    checkSiteAdminKPSuccess,
    checkSiteAdminFailure,
    getSmartLogicBearerTokenPending,
    getSmartLogicBearerTokenFailure,
    getSmartLogicBearerTokenSuccess
} from "./authorization.actions";
import { moduleFailure } from "redux/global.action.js";
import { sendToLocalStorage, getFromLocalStorageAsJSON } from 'utils/localStorage/localStorage';

export function* getEntitlements() {
    try {
        const { XA, API_URL: { GET_ENTITLEMENT } } = CONFIG;
        yield put(getEntitlementsPending());
        const res = yield call(doAxiosRequest, {
            method: 'get',
            endpoint: GET_ENTITLEMENT(),
            headers: {
                'x-api-key': XA
            }
        });
        yield put(getEntitlementsSuccess(res));
        sendToLocalStorage(`entitlements`, res)
    } catch (error) {
        const res = getFromLocalStorageAsJSON(`entitlements`);
        if (res) {
            yield put(getEntitlementsSuccess(res));
        }
        else {
            yield put(getEntitlementsFailure(error));
        }
    }
}
// Saga to check if the user is site admin/site editor or not
export function* checkSiteAdmin(action) {
    try {
        const { isKPPage } = action;
        const { XA, API_URL: { SITE_ADMIN_EDITOR } } = CONFIG;
        yield put(checkSiteAdminPending());
        const res = yield call(doAxiosRequest, {
            method: 'get',
            endpoint: SITE_ADMIN_EDITOR,
            headers: {
                'x-api-key': XA
            }
        });
        if (!isKPPage) {
            if (res?.message === "true" || res?.message === "false") {
                yield put(checkSiteAdminSuccess(JSON.parse(res?.message)));
            }
            else {
                yield put(checkSiteAdminFailure(res?.message));
            }
        } else {
            yield put(checkSiteAdminKPSuccess(res));
        }
    } catch (error) {
        yield put(checkSiteAdminFailure(error));
    }
}

export function* getSmartLogicBearerToken() {
    const { DISPLAY_TEXT: { ERROR_MESSAGES: { NOT_FOUND } } } = CONFIG;
    try {
        const {
            HTTP_CONTENT_TYPES: { urlencoded },
            XA,
            API_URL: { GST
            } } = CONFIG;
        yield put(getSmartLogicBearerTokenPending());
        const res = yield call(doAxiosRequest, {
            method: 'post',
            endpoint: GST,
            params: { key: process.env.REACT_APP_SAK, grant_type: "apikey" },
            headers: {
                noAuthHeader: true,
                'content-type': urlencoded,
                'x-api-key': XA
            }
        });
        if (res && res['access_token'] && res['.expires']) {
            sendToLocalStorage(CONFIG.OKTA.STK, res['access_token']);
            yield put(getSmartLogicBearerTokenSuccess(true));
        } else {
            yield put(getSmartLogicBearerTokenFailure(NOT_FOUND));
        }
    } catch (error) {
        yield put(getSmartLogicBearerTokenFailure(NOT_FOUND));
    }
}

export default function* authorizationSaga() {
    try {
        yield takeLatest(ENTITLEMENTS.GET_ENTITLEMENTS, getEntitlements);
        yield takeLatest(ENTITLEMENTS.CHECK_SITE_ADMIN, checkSiteAdmin);
        yield takeLatest(AUTH.GET_SMARTLOGIC_BEARER_TOKEN, getSmartLogicBearerToken);

    } catch (error) {
        yield put(moduleFailure(error, 'authorizationSaga'));
    }
}