import GLOBAL_CONSTANTS from "globalConstants";
import { stripHtml } from "utils/helpers/helpers";
import GLOBAL_CONFIG from "config";
const { KNOWLEDGE_BUNDLE_LIMITS: { KBPAGE_MIN_TITLE_CHARLIMIT }, SITECORE_XM_ENABLED } = GLOBAL_CONFIG;

export const validateForm = (localMetaData, errors, config, isKnowledgeBundle, step) => {
  let err = [];
  const { STEP1, STEP3 } = GLOBAL_CONSTANTS;
  const {
    MANDATORY_FIELDS: {
      COLLECTION_TITLE,
      DESCRIPTION,
      // ORIGINAL_REQUESTOR_FIELD,
      COVER_IMAGE,
      IPA_FPA,
      IPA_FPA_CUSTODIAN,
      SUBJECT,
      CREATED_BY,
      COLLECTION_TITLE_MIN_LENGTH,
    }
  } = config;

  if (step === STEP1) {
    const {
      title,
      // originalRequestor 
    } = localMetaData;
    if (
      title.trim().length === 0 &&
      err.indexOf(COLLECTION_TITLE) === -1
    ) {
      err = [...err, COLLECTION_TITLE];
    }
    if (
      title.trim().length > 0 &&
      title.trim().length < KBPAGE_MIN_TITLE_CHARLIMIT &&
      err.indexOf(COLLECTION_TITLE_MIN_LENGTH) === -1
    ) {
      err = [...err, COLLECTION_TITLE_MIN_LENGTH];
    }
    if (
      stripHtml(localMetaData.description).trim().length ===
      0 &&
      err.indexOf(DESCRIPTION) === -1
    ) {
      err = [...err, DESCRIPTION];
    }

    if (SITECORE_XM_ENABLED) {
      if (
        (!localMetaData.coverImage || !localMetaData.coverImage.Id || !localMetaData.coverImage.Name)) {
        if ((err.indexOf(COVER_IMAGE) === -1) && !isKnowledgeBundle) {
          err = [...err, COVER_IMAGE];
        }
      }
    }
    else if (localMetaData.coverImage.length === 0 && err.indexOf(COVER_IMAGE) === -1 && !isKnowledgeBundle) {
      err = [...err, COVER_IMAGE];
    }

    if (
      localMetaData.createdBy?.hrid.length === 0 &&
      err.indexOf(CREATED_BY) === -1
    ) {
      err = [...err, CREATED_BY];
    }
  }
  else if (step === STEP3) {
    if (
      localMetaData?.industryPATags.length === 0 &&
      localMetaData?.functionalPATags.length === 0 &&
      localMetaData?.bcgInternals.length === 0 &&
      err.indexOf(IPA_FPA) === -1
    ) {
      err = [...err, IPA_FPA];
    }

    if (
      !(
        (localMetaData.industryPATags.length > 0 && localMetaData.industryPATags.some(tag => tag.isOwner)) ||
        (localMetaData.functionalPATags.length > 0 && localMetaData.functionalPATags.some(tag => tag.isOwner)) ||
        (localMetaData.bcgInternals.length > 0 && localMetaData.bcgInternals.some(tag => tag.isOwner))
      ) && err.indexOf(IPA_FPA_CUSTODIAN) === -1
    ) {
      err = [...err, IPA_FPA_CUSTODIAN];
    }

    if (localMetaData.subjectTags.length === 0 && err.indexOf(SUBJECT) === -1) {
      err = [...err, SUBJECT];
    }
  }

  return err;
};

export const validateField = (val, type, config, isKnowledgeBundle) => {
  let errors = [];
  const {
    MANDATORY_FIELDS: {
      COLLECTION_TITLE,
      // ORIGINAL_REQUESTOR_FIELD,
      DESCRIPTION,
      COVER_IMAGE,
      IPA_FPA,
      IPA_FPA_CUSTODIAN,
      SUBJECT,
      CREATED_BY
    }
  } = config;

  if (val.length && type === "title") {
    errors = errors.filter((item) => item !== COLLECTION_TITLE);
  }

  if (
    type === "description" &&
    val.trim().replace(/<\/?[a-zA-Z][a-zA-Z0-9]*(?:\s+[a-zA-Z_:][a-zA-Z0-9:_.-]*(?:="[^"]*")?)*\s*\/?>/g, "").length
  ) {
    errors = errors.filter((item) => item !== DESCRIPTION);
  }

  if (val.length && type === "coverImage" && !isKnowledgeBundle) {
    errors = errors.filter((item) => item !== COVER_IMAGE);
  }

  if (
    (val.length && type === "industryPATags") ||
    (val.length && type === "functionalPATags") ||
    (val.length && type === "bcgInternals")
  ) {
    errors = errors.filter((item) => item !== IPA_FPA && item !== IPA_FPA_CUSTODIAN);
  }

  if (val.length && type === "subjectTags") {
    errors = errors.filter((item) => item !== SUBJECT);
  }

  if (type === "createdBy" && val?.hrid.length) {
    errors = errors.filter((item) => item !== CREATED_BY);
  }
  return errors;
};