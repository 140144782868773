import React, { useState } from "react";
import { useDispatch } from 'react-redux';
import { updateExperienceFields } from 'redux/actions/proposalBio/proposalBio.actions';
import "./ProposalBioSlide.scss";
import LABELS from "labels";
import { BulletEditor } from "components/BulletEditor/BulletEditor";
import { replaceInLabel } from 'utils/helpers/helpers';
import PropTypes from 'prop-types';


const ProposalBioSlide = ({ peopleData, isGrayscale, isSquareShape, isGreenTheme }) => {
	const dispatch = useDispatch();
	const [isEdit, setEdit] = useState(false);
	const {
		PROPOSAL_BIO: {
			ROLE,
			SUMMARY_RELEVANT_EXPERIENCE,
			RELEVANT_EXPERIENCE,
			PRIOR_EXPERIENCE_EDUCATION,
			PROFILE_SUMMARY,
			DEFAULT_MESSAGE,
			COPY_RIGHT,
		},
	} = LABELS;

	if (!peopleData) {
		return <p>{DEFAULT_MESSAGE}</p>;
	}


	const { picture, preferredFirstName, preferredLastName, businessTitle, hostOfficeLocation } =
		peopleData;
	const currentYear = new Date().getFullYear();

	const handleFieldChange = (field, value) => {
		dispatch(updateExperienceFields({ [field]: value }));
	};

	return (
		<div className={`proposalbio ${isGreenTheme ? 'proposalbio--green' : ''}`}>
			<div className="proposalbio__profile_section">
				<div className={`proposalbio__image_container ${isSquareShape ? "proposalbio__image_container--square" : ""}`}>
					<img src={picture} alt="Profile Image" className={`proposalbio__image ${isGrayscale ? "proposalbio__image--gray" : ""}`} />
				</div>
				<h2 className="proposalbio__name">{preferredFirstName} {preferredLastName}</h2>
				<div className="proposalbio__position">{businessTitle}</div>
				<div className="proposalbio__location">{hostOfficeLocation.name}</div>

				<div className="proposalbio__profile_section__label">
					<BulletEditor
						baseHtml={`<ul><li>Enter text here.</li></ul>`}
						maximumLines={4}
						oneLineHeight={21}
						id="role"
						heading={ROLE}
						nonGreenHeading
						callbackEdit={setEdit}
						disableEdit={isEdit}
						onChange={(value) => { handleFieldChange("role", value) }}
						isGreenTheme={isGreenTheme}
					/>
				</div>

				<div className="proposalbio__profile_section__label">
					<BulletEditor
						baseHtml={`<ul><li>Enter text here.</li></ul>`}
						maximumLines={5}
						oneLineHeight={21}
						id="summary"
						heading={SUMMARY_RELEVANT_EXPERIENCE}
						nonGreenHeading
						callbackEdit={setEdit}
						disableEdit={isEdit}
						onChange={(value) => handleFieldChange("summaryRelevantExperience", value)}
						isGreenTheme={isGreenTheme}
					/>
				</div>
				<p className="proposalbio__source">Source: BCG</p>
			</div>

			<div className="proposalbio__details_section">
				<div className="proposalbio__details_section__label">
					<BulletEditor
						baseHtml={`<p>Enter text here.</p>`}
						maximumLines={5}
						oneLineHeight={21}
						toolbarHidden
						id="profilesummary"
						heading={PROFILE_SUMMARY}
						callbackEdit={setEdit}
						disableEdit={isEdit}
						onChange={(value) => handleFieldChange("profileSummary", value)}
					/>
				</div>
				<div className="proposalbio__details_section__label">
					<BulletEditor
						baseHtml={`<ul><li>Enter text here.</li></ul>`}
						maximumLines={15}
						oneLineHeight={21}
						id="relevantexperience"
						heading={RELEVANT_EXPERIENCE}
						callbackEdit={setEdit}
						disableEdit={isEdit}
						onChange={(value) => handleFieldChange("relevantExperience", value)}
					/>
				</div>
				<div className="proposalbio__details_section__label">
					<BulletEditor
						baseHtml={`<ul><li>Enter text here.</li></ul>`}
						maximumLines={4}
						oneLineHeight={21}
						id="experienceeducation"
						heading={PRIOR_EXPERIENCE_EDUCATION}
						callbackEdit={setEdit}
						disableEdit={isEdit}
						onChange={(value) => handleFieldChange("priorExperienceAndEducation", value)}
					/>
				</div>
				<p className="proposalbio__copyright">{replaceInLabel(COPY_RIGHT, "{year}", currentYear)}</p>
			</div>
		</div>
	);
};

ProposalBioSlide.defaultProps = {
	isGreenTheme: false,
}

ProposalBioSlide.PropTypes = {
	isGreenTheme: PropTypes.bool,
}

export default ProposalBioSlide;
