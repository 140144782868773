import React from 'react';
import LABELS from 'labels';
import './FcgTemplate.scss';
import FcgGridTemplate from 'components/shared/FcgGridTemplate/FcgGridTemplate';
import ProposalWhiteTheme from "../../assets/images/ProposalWhiteTheme.svg";
import ProposalGreenTheme from "../../assets/images/ProposalGreenTheme.svg";

const {
    FACE_CARD_GENERATOR: { TEMPLATE_MESSAGE, TEMPLATE_MESSAGE_2 },
    PROPOSAL_BIO: { PROPOSAL_TEMPLATE_MESAGE_1, PROPOSAL_TEMPLATE_MESAGE_2, WHITE_THEME }
} = LABELS;

class FcgTemplate extends React.PureComponent {
    constructor(props) {
        super();
    }
    render() {
        const { onTemplateClick, templateObj, rowIndex } = this.props;

        const themeClr = { white: ProposalWhiteTheme, green: ProposalGreenTheme };

        return (
            <div key={`row-${rowIndex}`} className="faceCardTemplate__rowContainer">
                <div className="faceCardTemplate__templateMessage">
                    {rowIndex === 0 ? TEMPLATE_MESSAGE : PROPOSAL_TEMPLATE_MESAGE_1}
                </div>
                <div className="faceCardTemplate__gridContainer">
                    <p>
                        {rowIndex === 0 ? TEMPLATE_MESSAGE_2 : PROPOSAL_TEMPLATE_MESAGE_2}
                    </p>
                    <div className="faceCardTemplate__templatesGrid">
                        {Array.isArray(templateObj) && templateObj.map((template) => {
                            const { id, rows, columns, label, shape, theme } = template;

                            return (
                                <div key={id} className={`faceCardTemplate__templateSection  ${rows === 2 ? 'faceCardTemplate__templateSection--two-rows' : ''}`}

                                    onClick={() => onTemplateClick(template)}
                                >
                                    {shape ? (
                                        <div className="faceCardTemplate__gridBox">
                                            <FcgGridTemplate
                                                rows={rows}
                                                columns={columns}
                                                shape={shape}
                                            />
                                        </div>
                                    ) : (
                                        <div className="faceCardTemplate__imageContainer">
                                            <img
                                                src={themeClr[theme]}
                                                alt={label}
                                                className="faceCardTemplate__image"
                                            />
                                        </div>
                                    )}
                                    <h5 className='faceCardTemplate__templateLabel'>{label}</h5>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        );
    }
}

FcgTemplate.defaultProps = {
    rowIndex: 0
}

export default FcgTemplate;