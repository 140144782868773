import { doAxiosRequest } from "config-axios";
import { KNOWLEDGEOBJECT_VERSIONING, GLOBAL } from "redux/constants";
import { showContributionLoader, hideContributionLoader } from "redux/actions/mynavigator/mynavigator.actions";
import CONFIG from "config";

export const actions = {

  editPublishedKP: (kpid, isRpaReview, isContributionPage) => async (dispatch) => {
    const {
      EDIT_PUBLISHED_KP_PENDING,
      EDIT_PUBLISHED_KP_SUCCESS,
      EDIT_PUBLISHED_KP_FAILURE
    } = KNOWLEDGEOBJECT_VERSIONING;

    try {

      isContributionPage ? dispatch(showContributionLoader()) : dispatch({ type: GLOBAL.SHOW_PAGELOADER });
      dispatch({
        type: EDIT_PUBLISHED_KP_PENDING,
        payload: {
          id: kpid
        }
      });
      const { XA, API_URL: { EDIT_PUBLISHED_KP } } = CONFIG
      const axiosConfig = {
        method: 'get',
        endpoint: EDIT_PUBLISHED_KP(kpid),
        headers: {
          'x-api-key': XA
        }
      };

      if (isRpaReview) {
        axiosConfig.endpoint = EDIT_PUBLISHED_KP(kpid) + "?isrpareview=true"
      }
      const response = await doAxiosRequest(axiosConfig);
      dispatch({
        type: EDIT_PUBLISHED_KP_SUCCESS,
        payload: {
          data: response?.data
        }
      });
      dispatch({ type: GLOBAL.HIDE_PAGELOADER });
      return response;
    } catch (error) {
      dispatch({
        type: EDIT_PUBLISHED_KP_FAILURE,
        payload: { error: error }
      });
      dispatch(hideContributionLoader());
      dispatch({ type: GLOBAL.HIDE_PAGELOADER });
      throw error;

    }
  },

  editPublishedKB: (kbid, isRpaReview, isContributionPage) => async (dispatch) => {
    const {
      EDIT_PUBLISHED_KB_PENDING,
      EDIT_PUBLISHED_KB_SUCCESS,
      EDIT_PUBLISHED_KB_FAILURE
    } = KNOWLEDGEOBJECT_VERSIONING;
    const { API_URL: { EDIT_PUBLISHED_KB } } = CONFIG;
    try {
      isContributionPage ? dispatch(showContributionLoader()) : dispatch({ type: GLOBAL.SHOW_PAGELOADER });
      dispatch({
        type: EDIT_PUBLISHED_KB_PENDING,
        payload: {
          id: kbid
        }
      });
      const axiosConfig = {
        method: 'get',
        endpoint: EDIT_PUBLISHED_KB(kbid),
        headers: {
          'x-api-key': CONFIG.XA
        }
      };
      if (isRpaReview) {
        axiosConfig.endpoint = EDIT_PUBLISHED_KB(kbid) + "?isrpareview=true"
      }
      const response = await doAxiosRequest(axiosConfig);
      dispatch({
        type: EDIT_PUBLISHED_KB_SUCCESS,
        payload: {
          data: response?.data
        }
      });
      dispatch(hideContributionLoader());
      dispatch({ type: GLOBAL.HIDE_PAGELOADER });
      return response;
    } catch (error) {
      dispatch({
        type: EDIT_PUBLISHED_KB_FAILURE,
        payload: { error: error }
      });
      dispatch(hideContributionLoader());
      dispatch({ type: GLOBAL.HIDE_PAGELOADER });
      throw error;

    }
  }
};