import React, { Fragment } from "react";
import { connect } from "react-redux";

import { Form } from "semantic-ui-react";

import { bindActionCreators } from "redux";
import { actions as KPActions } from "redux/actions/knowledgepage/knowledgepage_actions";
import SupportingLinks from "components/SupportingLinks/AddSupportingLinks";
import { selectors as kpSelectors } from "redux/reducers/knowledgepage/knowledgepage_reducer";
import { Tooltip } from "components/shared/Tooltip";
import { NudgePopUp } from "components/shared/NudgePopUp";

import { TypeAheadSelector } from "components/TypeAheadSelector";
import { HierarchicalDropdown } from "components/shared/HierarchicalDropdown";
import { MultiSelectDropdown } from "components/MultiSelectDropdown";

import LABELS from 'labels';
import GLOBAL_CONFIG from "config";
// Adobe Analytics file
import ANALYTICS from "utils/analytics/analytics";
import { DTMRULE, PAGES, PRIMARY_CATEGORY, TRIGGERS, SCREEN_NAMES } from "utils/analytics/analytics_constants";
import "./KPStep3.scss";
import { filterRegionsDataByGlobal, filterInternalOfficeDataByGlobal, isFileTypePPT } from "utils/helpers/helpers";
import GLOBAL_CONSTANTS from "globalConstants";
import { ENTITLEMENTS } from "redux/constants";
const { STEP3 } = GLOBAL_CONSTANTS;

class KPStep3Container extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            regionSelected: '',
            internalOfficeSelected: '',
            additionalUrls: []
        }
    }

    componentDidMount = () => {
        const {
            mainKPState: {
                additionalURLs,
                isInternal,
                caseNumbers,
                uniqueId,
                file,
                id
            },
            getTagsSuggestions,
            callTaggingStatus,
            validationCheck,
            isPublishContentEntitlement,
            checkSiteAdmin
        } = this.props;
        const { IS_RECOMMENDED_TAGGING_ON, SITECORE_XM_ENABLED } = GLOBAL_CONFIG;
        if (caseNumbers?.length) {
            getTagsSuggestions(caseNumbers);
        }
        ANALYTICS.page.initialize({ pageName: PAGES.KP_CREATION, category: PRIMARY_CATEGORY.KP, trackPageLoad: true, pageId: { KCpage: { id } } });

        isInternal ? this.preSelectInternalOfficeRadio() : this.preSelectRegionRadio();
        this.setState({
            additionalUrls: additionalURLs
        });

        validationCheck(STEP3)
        if (IS_RECOMMENDED_TAGGING_ON && !!uniqueId && uniqueId !== "0" && !!isPublishContentEntitlement && isFileTypePPT(file?.name)) {
            callTaggingStatus(uniqueId);
        }
        if (SITECORE_XM_ENABLED) {
            checkSiteAdmin();
        }
    }

    sendRegionasGlobal = () => {
        const { handleChange } = this.props;
        const { TAXONOMY_KEYS: { GLOBAL_REGION } } = GLOBAL_CONSTANTS;
        const globalGUID = GLOBAL_CONFIG.TAXONOMY_IDS[GLOBAL_REGION].toLowerCase();
        const data = [{
            "Id": globalGUID,
            "Name": "Global"
        }];

        handleChange(data, "regions", STEP3, () => {
            handleChange([], "internalOfficeLocation", STEP3);
        }, true);
    }
    sendInternalOfficeAsGlobal = () => {
        const { handleChange } = this.props;
        const { TAXONOMY_KEYS: { GLOBAL_INTERNAL_OFFICE } } = GLOBAL_CONSTANTS;
        const globalGUID = GLOBAL_CONFIG.TAXONOMY_IDS[GLOBAL_INTERNAL_OFFICE].toLowerCase();
        const data = [{
            "Id": globalGUID,
            "Name": "Global"
        }];

        handleChange(data, "internalOfficeLocation", STEP3, () => {
            handleChange([], "officeLocation", STEP3, () => {
                handleChange([], "regions", STEP3);
            });
        }, true);
    }
    preSelectRegionRadio = () => {
        const { mainKPState: { regions } } = this.props;
        const { REGIONS: { GLOBAL, OTHERS } } = GLOBAL_CONSTANTS;
        const otherRegions = filterRegionsDataByGlobal(regions, "id");

        if (otherRegions.length) {
            this.setState({
                regionSelected: OTHERS
            });
        } else {
            this.setState({
                regionSelected: GLOBAL
            }, () => {
                if (!regions?.length) {
                    this.sendRegionasGlobal();
                }
            });
        }
    }
    preSelectInternalOfficeRadio = () => {
        const { mainKPState: { internalOfficeLocation } } = this.props;

        if (internalOfficeLocation.length) {
            const { INTERNAL_OFFICE: { GLOBAL, OTHERS } } = GLOBAL_CONSTANTS;
            const otherInternalLocation = filterInternalOfficeDataByGlobal(internalOfficeLocation, "id");
            if (otherInternalLocation.length) {
                this.setState({
                    internalOfficeSelected: OTHERS
                });
            } else {
                this.setState({
                    internalOfficeSelected: GLOBAL
                }, () => {
                    // if (!internalOfficeLocation?.length) {
                    //     this.sendInternalOfficeAsGlobal();
                    // }
                });
            }
        }
    }
    handleRadioChange = (value) => {
        const { handleChange } = this.props;
        this.setState({
            regionSelected: value
        },
            () => {
                const { REGIONS: { GLOBAL, OTHERS } } = GLOBAL_CONSTANTS;
                if (value === GLOBAL) {
                    this.sendRegionasGlobal();
                } else if (value === OTHERS) {
                    handleChange([], "regions", STEP3);
                }
            });
    }
    handleInternalOfficeRadioChange = (value) => {
        const { handleChange } = this.props;
        this.setState({
            internalOfficeSelected: value
        },
            () => {
                const { INTERNAL_OFFICE: { GLOBAL, OTHERS } } = GLOBAL_CONSTANTS;
                if (value === GLOBAL) {
                    this.sendInternalOfficeAsGlobal();
                } else if (value === OTHERS) {
                    handleChange([], "internalOfficeLocation", STEP3);
                }
            });
    }

    rgnCntrySearchResultClickCallBack = (searchfilterTerm, countOfSearchedValues, selectedFilterValue) => {
        const { mainKPState: { id } } = this.props;
        ANALYTICS.kc.sendEventData({
            page: {
                pageInfo: {
                    pageName: PAGES.KP_CREATION //evar1
                },
                category: {
                    primaryCategory: PRIMARY_CATEGORY.KP //evar50
                }
            },
            search: {
                searchfilterTerm: searchfilterTerm, //evar52
                searchfilterResultCount: countOfSearchedValues, //evar53
                filterLabel: selectedFilterValue, // evar63        
            },
            collection: {
                screenName: SCREEN_NAMES.KP_STEP_3, //evar112
            },
            KCpage: {
                id: id //evar123
            }
        }, DTMRULE.FILTER_TERM_CLICKED);
    };

    regionCountrySearchCallback = (searchfilterTerm, countOfSearchedValues) => {
        const { mainKPState: { id } } = this.props;
        ANALYTICS.kc.sendEventData({
            page: {
                pageInfo: {
                    pageName: PAGES.KP_CREATION //evar1
                },
                category: {
                    primaryCategory: PRIMARY_CATEGORY.KP //evar50
                }
            },
            search: {
                searchfilterTerm: searchfilterTerm, //evar52
                searchfilterResultCount: countOfSearchedValues, //evar53
                filterTerm: TRIGGERS.KP_SEARCH_REGIONS, // evar62        
            },
            collection: {
                screenName: SCREEN_NAMES.KP_STEP_3, //evar112
            },
            KCpage: {
                id: id //evar123
            }
        }, DTMRULE.SEARCH_WITHIN_FILTER);
    };
    sendLinksToApi = (links) => {
        const { handleChange } = this.props;
        if (Array.isArray(links)) {
            this.setState({
                additionalUrls: links
            });
            handleChange(links, "additionalURLs");
        }
    };

    render() {
        const {
            mainKPState: {
                regions,
                otherlanguage,
                officeLocation,
                id,
                isRestricted,
                isCMPUser,
                isGTMUser,
                relatedToCMP,
                relatedToGTM,
                isInternal,
                internalOfficeLocation,
                knowledgeType
            },
            handleChange, errorState,
            thumbsUpDownAnalyticsCallback,
            getInRoleData
        } = this.props;

        const {
            REGIONS: {
                GLOBAL,
                OTHERS
            },
            STEP3
        } = GLOBAL_CONSTANTS;

        const {
            KP_LIMITS: {
                ADDITIONAL_URLS_MAXLIMIT,
            },
            BLACK_LISTED_LINKS,
            IGNORE_OFFICE_GUIDS,
            REGEX: { SUPP_LINKS_URL },
            WHITE_LISTED_LINKS,
            SITECORE_XM_ENABLED
        } = GLOBAL_CONFIG;

        const { regionSelected, internalOfficeSelected, additionalUrls } = this.state;
        let regionsSelections = filterRegionsDataByGlobal(regions, "id");
        let internalOfficeSelections = filterInternalOfficeDataByGlobal(internalOfficeLocation, "id");

        const {
            OPTIONAL_TEXT,
            KP: {
                STEP3: {
                    OFC_HEADING,
                    BCG_INTERNAL_OFC_HEADING,
                    ADMIN_ASSOCIATEDOFC_PLACEHOLDER,
                    COUNTRIES_HEADING,
                    GLOBAL_TEXT,
                    OTHERS_TEXT,
                    LANGUAGE_HEADING,
                    RESTRICTED_HEADING,
                    RESTRICTED_YES,
                    RESTRICTED_NO,
                    CMP_HEADING,
                    GTM_HEADING,
                    RADIO_YES,
                    RADIO_NO,
                    ADDITIONAL_URL
                }
            },
            TOOLTIPS: {
                KP_OFFICE,
                KP_REGION_COUNTRY,
                KP_ADDITIONAL_URL
            },
            THUMBSUPDOWN: {
                HEADING_FEEDBACK
            }
        } = LABELS;

        return (
            <div className="kpstep3__wrap">
                <Fragment>
                    <Form.Group>
                        <Form.Field>
                            <label>{RESTRICTED_HEADING}</label>
                            <div className="knowledgepage__isrestricted">
                                <Form.Radio
                                    label={RESTRICTED_YES}
                                    name='isRestricted'
                                    value={true}
                                    checked={isRestricted}
                                    onChange={(e, { value }) => {
                                        handleChange(value, "isRestricted");
                                    }}
                                />
                                <Form.Radio
                                    label={RESTRICTED_NO}
                                    name='isRestricted'
                                    value={false}
                                    checked={!isRestricted}
                                    onChange={(e, { value }) => {
                                        handleChange(value, "isRestricted");
                                    }}
                                />
                            </div>
                        </Form.Field>
                    </Form.Group>
                    {(SITECORE_XM_ENABLED ? (!!getInRoleData.IsCMPUser) : (!!isCMPUser)) && (
                        <Form.Group>
                            <Form.Field>
                                <label>{CMP_HEADING}</label>
                                <div className="knowledgepage__isrestricted">
                                    <Form.Radio
                                        label={RADIO_YES}
                                        name='relatedToCMP'
                                        value={true}
                                        checked={relatedToCMP}
                                        onChange={(e, { value }) => {
                                            handleChange(value, "relatedToCMP");
                                        }}
                                    />
                                    <Form.Radio
                                        label={RADIO_NO}
                                        name='relatedToCMP'
                                        value={false}
                                        checked={!relatedToCMP}
                                        onChange={(e, { value }) => {
                                            handleChange(value, "relatedToCMP");
                                        }}
                                    />
                                </div>
                            </Form.Field>
                        </Form.Group>
                    )}
                    {(SITECORE_XM_ENABLED ? (!!getInRoleData.IsGTMUser) : (!!isGTMUser)) && (
                        <Form.Group>
                            <Form.Field>
                                <label>{GTM_HEADING}</label>
                                <div className="knowledgepage__isrestricted">
                                    <Form.Radio
                                        label={RADIO_YES}
                                        name='relatedToGTM'
                                        value={true}
                                        checked={relatedToGTM}
                                        onChange={(e, { value }) => {
                                            handleChange(value, "relatedToGTM");
                                        }}
                                    />
                                    <Form.Radio
                                        label={RADIO_NO}
                                        name='relatedToGTM'
                                        value={false}
                                        checked={!relatedToGTM}
                                        onChange={(e, { value }) => {
                                            handleChange(value, "relatedToGTM");
                                        }}
                                    />
                                </div>
                            </Form.Field>
                        </Form.Group>
                    )}
                    {!isInternal ?
                        <>
                            <Form.Group>
                                <Form.Field>
                                    <label className="inline-label">{OFC_HEADING}</label>
                                    <Tooltip id="officelocation">
                                        <p>{KP_OFFICE}</p>
                                    </Tooltip>
                                    <TypeAheadSelector
                                        // label=
                                        name="officeLocation"
                                        placeholder={ADMIN_ASSOCIATEDOFC_PLACEHOLDER}
                                        apiUrl={GLOBAL_CONFIG.API_URL.GET_OFFICES_DATA}
                                        preFillData={officeLocation}
                                        hideList={IGNORE_OFFICE_GUIDS}
                                        onFocusShowList={true}
                                        characterCount={2}
                                        responseKeyName={{
                                            id: "Id",
                                            name: "Name",
                                        }}
                                        prefillKeyName={{
                                            id: "id",
                                            name: "name",
                                        }}
                                        onChange={(data) => handleChange(data, "officeLocation", STEP3)}
                                        className={errorState && errorState['officeLocation'] ? 'customhighlight officeLocation' : 'officeLocation'}
                                        maxLimit={1}
                                        getSuggestionValueCallback={({ valueText, displayName }) => {
                                            ANALYTICS.kc.sendEventData({
                                                search: {
                                                    term: valueText,
                                                    typeHeadOptionText: displayName,
                                                },
                                                page: {
                                                    pageInfo: {
                                                        pageName: PAGES.KP_PAGES,
                                                    },
                                                    category: {
                                                        primaryCategory: PRIMARY_CATEGORY.KP
                                                    }
                                                },
                                                collection: {
                                                    trigger: TRIGGERS.TYPEAHEAD_BCG_OFFICE,
                                                    screenName: SCREEN_NAMES.KP_STEP_3,
                                                },
                                                KCpage: {
                                                    id: id
                                                },
                                            }, DTMRULE.KP);
                                        }}
                                    />
                                </Form.Field>
                            </Form.Group>
                            <Form.Group>
                                <Form.Field>
                                    {COUNTRIES_HEADING && <label className="inline-label">{COUNTRIES_HEADING}</label>}
                                    <Tooltip id="region-country">
                                        <p>{KP_REGION_COUNTRY}</p>
                                    </Tooltip>
                                    <div className="radio-wrapper">
                                        <Form.Radio
                                            label={GLOBAL_TEXT}
                                            name='regionsSelection'
                                            value={GLOBAL}
                                            checked={regionSelected === GLOBAL}
                                            onChange={(e, { value }) => {
                                                this.handleRadioChange(value);
                                            }}
                                        />
                                        <Form.Radio
                                            label={OTHERS_TEXT}
                                            name='regionsSelection'
                                            value={OTHERS}
                                            checked={regionSelected === OTHERS}
                                            onChange={(e, { value }) => {
                                                this.handleRadioChange(value);
                                            }}
                                        />
                                    </div>
                                    <MultiSelectDropdown
                                        preFillData={regionsSelections}
                                        isGlobalSelected={regionSelected === GLOBAL}
                                        responseKeyName={{
                                            id: "Id",
                                            name: "Name",
                                            children: "Children"
                                        }}
                                        onChange={(data) => { handleChange(data, "regions", STEP3) }}
                                        className={`multiselect ${regionSelected === GLOBAL ? ' disabled' : ''} ${errorState && errorState['regions'] ? ' customhighlight' : ''}  ${regionsSelections.length ? ' datapresent' : ''}`}
                                        taxonomyName={[{ label: "Regions", key: "REGIONS_COUNTRIES" }]}
                                        countrySearchCallback={this.regionCountrySearchCallback}
                                        countrySearchTermClickCallback={this.rgnCntrySearchResultClickCallBack}
                                    />
                                </Form.Field>
                            </Form.Group>
                        </>
                        :
                        <Form.Group>
                            <Form.Field>
                                <label>{BCG_INTERNAL_OFC_HEADING}<span className="kpstep3__wrap--underline">applicable</span></label>
                                <div className={`radio-wrapper kpstep3__wrap__makeCheckbox ${errorState && errorState['internalOfficeLocation'] ? ' kpstep3__wrap__makeCheckbox--error' : ''}`}>
                                    <Form.Radio
                                        label={GLOBAL_TEXT}
                                        name='internalOffice'
                                        value={GLOBAL}
                                        checked={internalOfficeSelected === GLOBAL}
                                        onChange={(e, { value }) => {
                                            this.handleInternalOfficeRadioChange(value);
                                        }}
                                    />
                                    <Form.Radio
                                        label={OTHERS_TEXT}
                                        name='internalOffice'
                                        value={OTHERS}
                                        checked={internalOfficeSelected === OTHERS}
                                        onChange={(e, { value }) => {
                                            this.handleInternalOfficeRadioChange(value);
                                        }}
                                    />
                                </div>
                                {internalOfficeSelected === OTHERS && (
                                    <NudgePopUp
                                        nodeName="internalOfficeSelections"
                                        value={internalOfficeSelections}
                                        feedbackHeading={HEADING_FEEDBACK}
                                        finalSuccess={(text) => thumbsUpDownAnalyticsCallback(text, knowledgeType.isPARecommended, OFC_HEADING)}
                                        heading={OFC_HEADING}
                                        handleOutsideClick={true}
                                        nudgeCustomClass={'nudgepopup__suggestions__highZIndex'}
                                    >
                                        <MultiSelectDropdown
                                            preFillData={internalOfficeSelections}
                                            isGlobalSelected={internalOfficeSelected === GLOBAL}
                                            responseKeyName={{
                                                id: "Id",
                                                name: "Name",
                                                children: "Children"
                                            }}
                                            onChange={(data) => {
                                                handleChange(data, "internalOfficeLocation", STEP3)
                                            }}
                                            className={`multiselect office__wrapinput ${internalOfficeSelected === GLOBAL ? ' disabled' : ''} ${internalOfficeSelections.length ? ' datapresent' : ''} ${errorState && errorState['internalOfficeLocation'] ? ' customhighlight' : ''}`}
                                            taxonomyName={[{ label: "Internal Office", key: "INTERNAL_OFFICE_COUNTRIES" }]}
                                            countrySearchCallback={() => { }}
                                            countrySearchTermClickCallback={() => { }}
                                        />
                                    </NudgePopUp>
                                )}
                            </Form.Field>
                        </Form.Group>
                    }
                    <Form.Group>
                        <Form.Field>
                            <HierarchicalDropdown
                                data={this.props.getLanguageData}
                                placeholder="Select"
                                name="otherlanguage"
                                label={LANGUAGE_HEADING}
                                position={"top"}
                                itemId={process.env.REACT_APP_KPLANGUAGE_API_KEY}
                                slectedValue={otherlanguage}
                                onChange={(data) => { handleChange(data, "otherlanguage", STEP3) }}
                                className={errorState && errorState['otherlanguage'] ? 'customhighlight' : ''}
                            />
                        </Form.Field>
                    </Form.Group>
                    <Form.Group>
                        <Form.Field>
                            <label className="inline-label">{ADDITIONAL_URL} <span className="optional-text">{OPTIONAL_TEXT}</span></label>
                            <Tooltip id="additional-url">
                                <p>{KP_ADDITIONAL_URL}</p>
                            </Tooltip>
                            <div className="additionalUrls">
                                <SupportingLinks
                                    defaultState={additionalUrls}
                                    limit={ADDITIONAL_URLS_MAXLIMIT}
                                    getLinks={this.sendLinksToApi}
                                    urlSanitization={BLACK_LISTED_LINKS}
                                    urlWhiteList={WHITE_LISTED_LINKS}
                                    urlValidation={SUPP_LINKS_URL}
                                />
                            </div>
                        </Form.Field>
                    </Form.Group>
                </Fragment>
            </div>
        );
    }
}

KPStep3Container.defaultProps = {
    validationCheck: () => { }
};

const mapStateToProps = (state) => ({
    getLanguageData: kpSelectors.getLanguageData(state),
    getInRoleData: kpSelectors.getInRoleData(state),
});

const mapDispatchToProps = (dispatch) => ({
    KPActions: bindActionCreators({ ...KPActions }, dispatch),
    checkSiteAdmin: () => dispatch({ type: ENTITLEMENTS.CHECK_SITE_ADMIN, isKPPage: true }),
});

export const KPStep3 = connect(
    mapStateToProps,
    mapDispatchToProps
)(KPStep3Container);

export { KPStep3Container };
