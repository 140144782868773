import React, { useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { FCG_ProposalBiov1 } from "containers/FCG_ProposalBio/FCG_ProposaBio";
import ProposalBioSlide from "containers/ProposalBioSlide/ProposalBioSlide";
import FcgTemplate from "components/FcgTemplate/FcgTemplate";
import CONFIG from "config";
import Modal from "components/shared/Modal";
import { selectors as proposalBioSelectors } from 'redux/reducers/proposalBio/proposalBio_reducer';
import { updateExperienceFields } from 'redux/actions/proposalBio/proposalBio.actions';

const ProposalBioContainer = ({ selectedTemplateTitle, handleClose, templateId, showModal, history }) => {
	const [isGrayscale, setIsGrayscale] = useState(true);
	const [isSquareShape, setIsSquareShape] = useState(true);
	const [proposalPeopleData, setProposalPeopleData] = useState(null);

	const dispatch = useDispatch();
	const hrid = new URLSearchParams(window.location.search).get('hrid');

	let experienceFields = useSelector(proposalBioSelectors.getExperienceFields);

	const handleToggleGrayscale = () => {
		setIsGrayscale((prev) => !prev);
	};

	const handleToggleShape = () => {
		setIsSquareShape((prev) => !prev);
	};

	const handleClearProposalData = () => {
		setProposalPeopleData(null);
		setIsGrayscale(false);
		setIsSquareShape(false);
		dispatch(updateExperienceFields({
			role: "",
			summaryRelevantExperience: "",
			relevantExperience: "",
			priorExperienceAndEducation: "",
			profileSummary: ""
		}));
	};

	const handleTemplateClick = (template) => {
		const {
			UI_URL: {
				FACE_CARD_GENERATOR_V1
			}
		} = CONFIG;
		history.replace({
			pathname: FACE_CARD_GENERATOR_V1(false, template.id),
			state: { mode: history.location?.state?.mode },
			search: hrid ? "?hrid=" + hrid : ""
		});
	};

	const isGreenTheme = templateId === "proposalbiog";
	const { PROPOSAL_TEMPLATES } = CONFIG;

	return (
		<div className="ProposalBioContainer">
			<Modal
				isOpen={showModal}
				className="proposalbio__modal"
				onRequestClose={() => { }}
			>
				<FcgTemplate
					onTemplateClick={handleTemplateClick}
					templateObj={PROPOSAL_TEMPLATES}
					rowIndex={1}
				/>
			</Modal>
			<FCG_ProposalBiov1
				selectedTemplateTitle={selectedTemplateTitle}
				onParentClose={handleClose}
				handleClearProposalData={handleClearProposalData}
				onToggleGrayscale={handleToggleGrayscale}
				onToggleShape={handleToggleShape}
				isGrayscale={isGrayscale}
				isSquareShape={isSquareShape}
				onPreviewData={setProposalPeopleData}
				proposalPeopleData={proposalPeopleData}
				experienceFields={experienceFields}
				isGreenTheme={isGreenTheme}
				hrid={hrid}
			/>
			<ProposalBioSlide
				peopleData={proposalPeopleData}
				isGrayscale={isGrayscale}
				isSquareShape={isSquareShape}
				isGreenTheme={isGreenTheme}
			/>
		</div>
	);
};

export default ProposalBioContainer;
