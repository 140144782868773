// Notification toastify
export const ERROR = "ERROR";
export const INFO = "INFO";
export const SUCCESS = "SUCCESS";
export const WARNING = "WARNING";
export const DISMISS_NOTIFICATIONS = "DISMISS_NOTIFICATIONS";

// Upload
export const UPLOAD_S3 = {
  GET_UPLOAD_S3: "GET_UPLOAD_S3",
  GET_UPLOAD_S3_PENDING: "GET_UPLOAD_S3_PENDING",
  GET_UPLOAD_S3_SUCCESS: "GET_UPLOAD_S3_SUCCESS",
  GET_UPLOAD_S3_FAILURE: "GET_UPLOAD_S3_FAILURE",
  FETCH_PRESIGNURL_PENDING: "FETCH_PRESIGNURL_PENDING",
  FETCH_PRESIGNURL_FAILURE: "FETCH_PRESIGNURL_FAILURE",
  FETCH_PRESIGNURL_SUCCESS: "FETCH_PRESIGNURL_SUCCESS",
}

export const API_GET_ALL_MATERIALS_PENDING = "API_GET_ALL_MATERIALS_PENDING";
export const API_GET_ALL_MATERIALS_FAILURE = "API_GET_ALL_MATERIALS_FAILURE";
export const API_GET_ALL_MATERIALS_SUCCESS = "API_GET_ALL_MATERIALS_SUCCESS";


export const PROPOSAL_BIO_ACTIONS = {
  PROPOSAL_BIO_ACKNOWLEDGEMENT_API_PENDING: "PROPOSAL_BIO_ACKNOWLEDGEMENT_API_PENDING",
  PROPOSAL_BIO_ACKNOWLEDGEMENT_API_SUCCESS: "PROPOSAL_BIO_ACKNOWLEDGEMENT_API_SUCCESS",
  PROPOSAL_BIO_ACKNOWLEDGEMENT_API_FAILURE: "PROPOSAL_BIO_ACKNOWLEDGEMENT_API_FAILURE",
  PROPOSAL_BIO_POLLING_API_START: "PROPOSAL_BIO_POLLING_API_START",
  PROPOSAL_BIO_POLLING_API_SUCCESS:"PROPOSAL_BIO_POLLING_API_SUCCESS",
  PROPOSAL_BIO_POLLING_API_FAILURE: "PROPOSAL_BIO_POLLING_API_FAILURE",
  UPDATE_EXPERIENCE_FIELDS:"UPDATE_EXPERIENCE_FIELDS",
  PROPOSAL_DOWNLOAD_FILE_FROM_CLOUDFRONT: 'PROPOSAL_DOWNLOAD_FILE_FROM_CLOUDFRONT',
};

export const API_GET_MATERIAL_PENDING = "API_GET_MATERIAL_PENDING";
export const API_GET_MATERIAL_FAILURE = "API_GET_MATERIAL_FAILURE";
export const API_GET_MATERIAL_SUCCESS = "API_GET_MATERIAL_SUCCESS";

export const API_SAVE_MATERIAL_PENDING = "API_SAVE_MATERIAL_PENDING";
export const API_SAVE_MATERIAL_SUCCESS = "API_SAVE_MATERIAL_SUCCESS";
export const API_SAVE_MATERIAL_FAILURE = "API_SAVE_MATERIAL_FAILURE";

export const API_SAVE_UPLOAD_MATERIAL_PENDING =
  "API_SAVE_UPLOAD_MATERIAL_PENDING";
export const API_SAVE_UPLOAD_MATERIAL_SUCCESS =
  "API_SAVE_UPLOAD_MATERIAL_SUCCESS";
export const API_SAVE_UPLOAD_MATERIAL_FAILURE =
  "API_SAVE_UPLOAD_MATERIAL_FAILURE";

export const REDIRECT_METADATA_PAGE = "REDIRECT_METADATA_PAGE";

// Metadata
export const META_DATA = {
  API_GET_METADATA: "API_GET_METADATA",
  API_GET_METADATA_PENDING: "API_GET_METADATA_PENDING",
  API_GET_METADATA_SUCCESS: "API_GET_METADATA_SUCCESS",
  API_GET_METADATA_FAILURE: "API_GET_METADATA_FAILURE",

  API_GET_IMAGE_PENDING: "API_GET_IMAGE_PENDING",
  API_GET_IMAGE_SUCCESS: "API_GET_IMAGE_SUCCESS",
  API_GET_IMAGE_FAILURE: "API_GET_IMAGE_FAILURE",

  API_GET_DOC_VIZ_PENDING: "API_GET_DOC_VIZ_PENDING",
  API_GET_DOC_VIZ_SUCCESS: "API_GET_DOC_VIZ_SUCCESS",
  API_GET_DOC_VIZ_FAILURE: "API_GET_DOC_VIZ_FAILURE",

  API_SAVE_METADATA_PENDING: "API_SAVE_METADATA_PENDING",
  API_SAVE_METADATA_SUCCESS: "API_SAVE_METADATA_SUCCESS",
  API_SAVE_METADATA_FAILURE: "API_SAVE_METADATA_FAILURE",

  API_GET_KO_DETAILS_PENDING: "API_GET_KO_DETAILS_PENDING",
  API_GET_KO_DETAILS_SUCCESS: "API_GET_KO_DETAILS_SUCCESS",
  API_GET_KO_DETAILS_FAILURE: "API_GET_KO_DETAILS_FAILURE",

  CLEAR_METADATA: "CLEAR_METADATA",
  API_GET_KB_DRAFT_PENDING: "API_GET_KB_DRAFT_PENDING",
  API_GET_KB_DRAFT_SUCCESS: "API_GET_KB_DRAFT_SUCCESS",
  API_GET_KB_DRAFT_FAILURE: "API_GET_KB_DRAFT_FAILURE",

  API_GET_KB_PUBLISHED_PENDING: "API_GET_KB_PUBLISHED_PENDING",
  API_GET_KB_PUBLISHED_SUCCESS: "API_GET_KB_PUBLISHED_SUCCESS",
  API_GET_KB_PUBLISHED_FAILURE: "API_GET_KB_PUBLISHED_FAILURE",

  GET_CONSUMPTION_KB_DATA: "GET_CONSUMPTION_KB_DATA",
  GET_CONSUMPTION_KB_DATA_PENDING: "GET_CONSUMPTION_KB_DATA_PENDING",
  GET_CONSUMPTION_KB_DATA_SUCCESS: "GET_CONSUMPTION_KB_DATA_SUCCESS",
  GET_PREVIEW_CONSUMPTION_KB_DATA_SUCCESS: "GET_PREVIEW_CONSUMPTION_KB_DATA_SUCCESS",
  GET_CONSUMPTION_KB_DATA_FAILURE: "GET_CONSUMPTION_KB_DATA_FAILURE",
};

// Taxonomy Actions
export const TAXONOMY = {
  GET_TAXONOMY: "GET_TAXONOMY",
  GET_TYPE_SEARCH_DATA: "GET_TYPE_SEARCH_DATA",
  API_GET_TAXONOMY_PENDING: "API_GET_FUNCTIONAL_TAXONOMY_PENDING",
  API_GET_TAXONOMY_SUCCESS: "API_GET_FUNCTIONAL_TAXONOMY_SUCCESS",
  API_GET_TAXONOMY_FAILURE: "API_GET_FUNCTIONAL_TAXONOMY_FAILURE",

  API_GET_TYPESEARCH_PENDING: "API_GET_FUNCTIONAL_TYPESEARCH_PENDING",
  API_GET_TYPESEARCH_SUCCESS: "API_GET_FUNCTIONAL_TYPESEARCH_SUCCESS",
  API_GET_TYPESEARCH_FAILURE: "API_GET_FUNCTIONAL_TYPESEARCH_FAILURE",

  GET_SL_PARENT_TAGS_DATA: "GET_SL_PARENT_TAGS_DATA",
  GET_SL_PARENT_TAGS_PENDING: "GET_SL_PARENT_TAGS_PENDING",
  GET_SL_PARENT_TAGS_SUCCESS: "GET_SL_PARENT_TAGS_SUCCESS",
  GET_SL_PARENT_TAGS_FAILURE: "GET_SL_PARENT_TAGS_FAILURE",
  RESET_ALL_SL_PARENT_TAGS: "RESET_ALL_SL_PARENT_TAGS",


  GET_EXPERT_TAGS_DATA: "GET_EXPERT_TAGS_DATA",
  GET_EXPERT_TAGS_PENDING: "GET_EXPERT_TAGS_PENDING",
  GET_EXPERT_TAGS_SUCCESS: "GET_EXPERT_TAGS_SUCCESS",
  GET_EXPERT_TAGS_FAILURE: "GET_EXPERT_TAGS_FAILURE",

  GET_SUBJECT_TAGS_DATA: "GET_SUBJECT_TAGS_DATA",
  GET_SUBJECT_TAGS_PENDING: "GET_SUBJECT_TAGS_PENDING",
  GET_SUBJECT_TAGS_SUCCESS: "GET_SUBJECT_TAGS_SUCCESS",
  GET_SUBJECT_TAGS_FAILURE: "GET_SUBJECT_TAGS_FAILURE",

  CREATE_POLY_HIERARCHI_GUIDS_OBJ_CONFIG: "CREATE_POLY_HIERARCHI_GUIDS_OBJ_CONFIG",
  CALL_SL_PARENT_TAG_FLAG: "CALL_SL_PARENT_TAG_FLAG"
};

// Recommended Tagging
export const RECOMMENDED_TAGGING = {
  GET_TASK_REGISTER: "GET_TASK_REGISTER",
  API_TASK_REGISTER_PENDING: "API_TASK_REGISTER_PENDING",
  API_TASK_REGISTER_SUCCESS: "API_TASK_REGISTER_SUCCESS",
  API_TASK_REGISTER_FAILURE: "API_TASK_REGISTER_FAILURE",

  GET_TAGGING_STATUS: "GET_TAGGING_STATUS",
  API_TAGGING_STATUS_PENDING: "API_TAGGING_STATUS_PENDING",
  API_TAGGING_STATUS_SUCCESS: "API_TAGGING_STATUS_SUCCESS",
  API_TAGGING_STATUS_FAILURE: "API_TAGGING_STATUS_FAILURE",

  GET_RECOMMENDED_TAGS: "GET_RECOMMENDED_TAGS",
  API_RECOMMENDED_TAGS_PENDING: "API_RECOMMENDED_TAGS_PENDING",
  API_RECOMMENDED_TAGS_SUCCESS: "API_RECOMMENDED_TAGS_SUCCESS",
  API_RECOMMENDED_TAGS_FAILURE: "API_RECOMMENDED_TAGS_FAILURE",
  API_RECOMMENDED_TAGS_RESET: "API_RECOMMENDED_TAGS_RESET",

  POST_FEEDBACK_RECOMMENDED_TAGS: "POST_FEEDBACK_RECOMMENDED_TAGS",
  API_FEEDBACK_TAGS_PENDING: "API_FEEDBACK_TAGS_PENDING",
  API_FEEDBACK_TAGS_SUCCESS: "API_FEEDBACK_TAGS_SUCCESS",
  API_FEEDBACK_TAGS_FAILURE: "API_FEEDBACK_TAGS_FAILURE",

}

// Enitilement Actions
export const ENTITLEMENTS = {
  GET_ENTITLEMENTS: "GET_ENTITLEMENTS",
  API_GET_ENTITLEMENTS_PENDING: "API_GET_ENTITLEMENTS_PENDING",
  API_GET_ENTITLEMENTS_SUCCESS: "API_GET_ENTITLEMENTS_SUCCESS",
  API_GET_ENTITLEMENTS_FAILURE: "API_GET_ENTITLEMENTS_FAILURE",

  // Actions for checking whether the user is site admin/editor or not
  CHECK_SITE_ADMIN: "CHECK_SITE_ADMIN",
  API_CHECK_SITE_ADMIN_PENDING: "API_CHECK_SITE_ADMIN_PENDING",
  API_CHECK_SITE_ADMIN_SUCCESS: "API_CHECK_SITE_ADMIN_SUCCESS",
  API_CHECK_SITE_ADMIN_KP_SUCCESS: "API_CHECK_SITE_ADMIN_KP_SUCCESS",
  API_CHECK_SITE_ADMIN_FAILURE: "API_CHECK_SITE_ADMIN_FAILURE",
}

export const CASES = {
  GET_CASES_META_DETAILS: "GET_CASES_META_DETAILS",
  API_GET_CASES_META_DETAILS_PENDING: "API_GET_CASES_META_DETAILS_PENDING",
  API_GET_CASES_META_DETAILS_SUCCESS: "API_GET_CASES_META_DETAILS_SUCCESS",
  API_GET_CASES_META_DETAILS_FAILURE: "API_GET_CASES_META_DETAILS_FAILURE"
}

export const CASE_KMSOWNER_LIST = {
  GET_CASE_KMSOWNER_LIST: "GET_CASE_KMSOWNER_LIST",
  GET_CASE_KMSOWNER_LIST_PENDING: "GET_CASE_KMSOWNER_LIST_PENDING",
  GET_CASE_KMSOWNER_LIST_SUCCESS: "GET_CASE_KMSOWNER_LIST_SUCCESS",
  GET_CASE_KMSOWNER_LIST_FAILURE: "GET_CASE_KMSOWNER_LIST_FAILURE",
}

export const MYCASES = {
  GET_CASES_BT: "GET_CASES_BT",
  GET_CASES_BT_PENDING: "GET_CASES_BT_PENDING",
  GET_CASES_BT_SUCCESS: "GET_CASES_BT_SUCCESS",
  GET_CASES_BT_FAILURE: "GET_CASES_BT_FAILURE",

  GET_CASES_DURATION: "GET_CASES_DURATION",
  GET_CASES_DURATION_PENDING: "GET_CASES_DURATION_PENDING",
  GET_CASES_DURATION_SUCCESS: "GET_CASES_DURATION_SUCCESS",
  GET_CASES_DURATION_FAILURE: "GET_CASES_DURATION_FAILURE",

  SAVE_CASE_BT: "SAVE_CASE_BT",
  SAVE_CASE_BT_PENDING: "SAVE_CASE_BT_PENDING",
  SAVE_CASE_BT_SUCCESS: "SAVE_CASE_BT_SUCCESS",
  SAVE_CASE_BT_FAILURE: "SAVE_CASE_BT_FAILURE",

}

// constants for case contribution (metadata)
export const CASE_METADATA = {
  GET_CASE_METADATA: "GET_CASE_METADATA",
  GET_CASE_METADATA_PENDING: "GET_CASE_METADATA_PENDING",
  GET_CASE_METADATA_SUCCESS: "GET_CASE_METADATA_SUCCESS",
  GET_CASE_METADATA_FAILURE: "GET_CASE_METADATA_FAILURE",

  GET_CASE_TYPES: "GET_CASE_TYPES",
  GET_CASE_TYPES_PENDING: "GET_CASE_TYPES_PENDING",
  GET_CASE_TYPES_SUCCESS: "GET_CASE_TYPES_SUCCESS",
  GET_CASE_TYPES_FAILURE: "GET_CASE_TYPES_FAILURE",

  GET_CASE_TB_DATES: "GET_CASE_TB_DATES",
  GET_CASE_TB_DATES_PENDING: "GET_CASE_TB_DATES_PENDING",
  GET_CASE_TB_DATES_SUCCESS: "GET_CASE_TB_DATES_SUCCESS",
  GET_CASE_TB_DATES_FAILURE: "GET_CASE_TB_DATES_FAILURE",

  GET_CASE_CONSUMPTION_DATA_PENDING: "GET_CASE_CONSUMPTION_DATA_PENDING",
  GET_CASE_CONSUMPTION_DATA_SUCCESS: "GET_CASE_CONSUMPTION_DATA_SUCCESS",
  GET_CASE_CONSUMPTION_DATA_FAILURE: "GET_CASE_CONSUMPTION_DATA_FAILURE",

  GET_CASE_T_AND_B: "GET_CASE_T_AND_B",
  GET_CASE_T_AND_B_PENDING: "GET_CASE_T_AND_B_PENDING",
  GET_CASE_T_AND_B_SUCCESS: "GET_CASE_T_AND_B_SUCCESS",
  GET_CASE_T_AND_B_FAILURE: "GET_CASE_T_AND_B_FAILURE",

  GET_CASE_SAM: "GET_CASE_SAM",
  GET_CASE_SAM_PENDING: "GET_CASE_SAM_PENDING",
  GET_CASE_SAM_SUCCESS: "GET_CASE_SAM_SUCCESS",
  GET_CASE_SAM_FAILURE: "GET_CASE_SAM_FAILURE",

  GET_CASE_CMP: "GET_CASE_CMP",
  GET_CASE_CMP_PENDING: "GET_CASE_CMP_PENDING",
  GET_CASE_CMP_SUCCESS: "GET_CASE_CMP_SUCCESS",
  GET_CASE_CMP_FAILURE: "GET_CASE_CMP_FAILURE",

  SAVE_CASE_METADATA: "SAVE_CASE_METADATA",
  SAVE_CASE_METADATA_PENDING: "SAVE_CASE_METADATA_PENDING",
  SAVE_CASE_METADATA_SUCCESS: "SAVE_CASE_METADATA_SUCCESS",
  SAVE_CASE_METADATA_FAILURE: "SAVE_CASE_METADATA_FAILURE",

  GET_CASE_FPA_DETAILS: "GET_CASE_FPA_DETAILS",
  GET_CASE_FPA_DETAILS_PENDING: "GET_CASE_FPA_DETAILS_PENDING",
  GET_CASE_FPA_DETAILS_SUCCESS: "GET_CASE_FPA_DETAILS_SUCCESS",
  GET_CASE_FPA_DETAILS_FAILURE: "GET_CASE_FPA_DETAILS_FAILURE",

  SEARCH_CASES: "SEARCH_CASES",
  SEARCH_CASES_PENDING: "SEARCH_CASES_PENDING",
  SEARCH_CASES_SUCCESS: "SEARCH_CASES_SUCCESS",
  SEARCH_CASES_FAILURE: "SEARCH_CASES_FAILURE",
  RESET_SEARCH_RESULTS: "RESET_SEARCH_RESULTS",
}
export const CASE_HISTORY = {
  GET_CASE_HISTORY: "GET_CASE_HISTORY",
  GET_CASE_HISTORY_SUCCESS: "GET_CASE_HISTORY_SUCCESS",
  GET_CASE_HISTORY_PENDING: "GET_CASE_HISTORY_PENDING",
  GET_CASE_HISTORY_FAILURE: "GET_CASE_HISTORY_FAILURE"
}
// Global Actions
export const GLOBAL = {
  SHOW_PAGELOADER: "SHOW_PAGELOADER",
  HIDE_PAGELOADER: "HIDE_PAGELOADER",
  SHOW_FULLPAGELOADER: "SHOW_FULLPAGELOADER",
  HIDE_FULLPAGELOADER: "HIDE_FULLPAGELOADER",
  FULLPAGELOADER_ERROR: "FULLPAGELOADER_ERROR",
  DISABLE_BODY_OVERFLOW: "DISABLE_BODY_OVERFLOW",
  ENABLE_BODY_OVERFLOW: "ENABLE_BODY_OVERFLOW",
  LOGGEDIN_USER_DATA: "LOGGEDIN_USER_DATA"
};
// Authorization Actions
export const AUTH = {
  GET_SMARTLOGIC_BEARER_TOKEN: "GET_SMARTLOGIC_BEARER_TOKEN",
  GET_SMARTLOGIC_BEARER_TOKEN_PENDING: "GET_SMARTLOGIC_BEARER_TOKEN_PENDING",
  GET_SMARTLOGIC_BEARER_TOKEN_SUCCESS: "GET_SMARTLOGIC_BEARER_TOKEN_SUCCESS",
  GET_SMARTLOGIC_BEARER_TOKEN_FAILURE: "GET_SMARTLOGIC_BEARER_TOKEN_FAILURE"
};

// Knowledge Page Actions
export const KP = {
  KP_SAVE_PENDING: "KP_SAVE_PENDING",
  KP_SAVE_SUCCESS: "KP_SAVE_SUCCESS",
  KP_SAVE_FAILURE: "KP_SAVE_FAILURE",
  // Dropdown Data
  DROPDOWN_DATA_PENDING: "DROPDOWN_DATA_PENDING",
  KNOWLEDGE_PAGE_TYPE_DROPDOWN_DATA_SUCCESS: "KNOWLEDGE_PAGE_TYPE_DROPDOWN_DATA_SUCCESS",
  DROPDOWN_DATA_FAILURE: "DROPDOWN_DATA_FAILURE",
  // Knowledge Page Language Dropdown
  KNOWLEDGE_PAGE_LANGUAGE_DROPDOWN_DATA_SUCCESS: "KNOWLEDGE_PAGE_LANGUAGE_DROPDOWN_DATA_SUCCESS",
  // set selected knowledge type and language dropwn
  SET_SELECTED_KNOWLEDGE_TYPE_VALUE: "SET_SELECTED_KNOWLEDGE_TYPE_VALUE",
  SET_SELECTED_LANGUAGE_VALUE: "SET_SELECTED_LANGUAGE_VALUE",
  KP_CLEAR: "KP_CLEAR",
  GET_FILE: "GET_FILE",
  GET_FILE_PENDING: "GET_FILE_PENDING",
  GET_FILE_SUCCESS: "GET_FILE_SUCCESS",
  GET_FILE_FAILURE: "GET_FILE_FAILURE",

  GET_FILES_STATUS: "GET_FILES_STATUS",
  GET_FILES_STATUS_SUCCESS: "FILES_STATUS_SUCCESS",
  GET_FILES_STATUS_FAILURE: "FILES_STATUS_FAILURE",
  GET_FILES_STATUS_PENDING: "FILES_STATUS_PENDING",

  GET_TAGS_SUGGESTIONS: "GET_TAGS_SUGGESTIONS",
  GET_TAGS_SUGGESTIONS_SUCCESS: "GET_TAGS_SUGGESTIONS_SUCCESS",
  GET_TAGS_SUGGESTIONS_FAILURE: "GET_TAGS_SUGGESTIONS_FAILURE",
  GET_TAGS_SUGGESTIONS_PENDING: "GET_TAGS_SUGGESTIONS_PENDING",
  RESET_TAGS_SUGGESTIONS: "RESET_TAGS_SUGGESTIONS",

  KP_PUBLISHED_SUCCESS: "KP_PUBLISHED_SUCCESS",
  RESET_DOWNLOAD_ERROR: "RESET_DOWNLOAD_ERROR",

  GET_DOWNLOADS: "GET_DOWNLOADS",
  GET_DOWNLOADS_PENDING: "GET_DOWNLOADS_PENDING",
  GET_DOWNLOADS_SUCCESS: "GET_DOWNLOADS_SUCCESS",
  GET_DOWNLOADS_FAILURE: "GET_DOWNLOADS_FAILURE",

  GET_ASSOCIATED_BUNDLES: "GET_ASSOCIATED_BUNDLES",
  GET_ASSOCIATED_BUNDLES_SUCCESS: "GET_ASSOCIATED_BUNDLES_SUCCESS",
  GET_ASSOCIATED_BUNDLES_FAILURE: "GET_ASSOCIATED_BUNDLES_FAILURE",
  GET_ASSOCIATED_BUNDLES_PENDING: "GET_ASSOCIATED_BUNDLES_PENDING",

  GET_UNIQUE_TITLE_CONFIRMATION: "GET_UNIQUE_TITLE_CONFIRMATION",
  GET_UNIQUE_TITLE_CONFIRMATION_SUCCESS: "GET_UNIQUE_TITLE_CONFIRMATION_SUCCESS",
  GET_UNIQUE_TITLE_CONFIRMATION_FAILURE: "GET_UNIQUE_TITLE_CONFIRMATION_FAILURE",
  GET_UNIQUE_TITLE_CONFIRMATION_PENDING: "GET_UNIQUE_TITLE_CONFIRMATION_PENDING",
  RESET_UNIQUE_TITLE_CONFIRMATION: "RESET_UNIQUE_TITLE_CONFIRMATION",
  SHOW_WARNING_MODAL: "SHOW_WARNING_MODAL",
  RESET_WARNING_MODAL: "RESET_WARNING_MODAL",

  GET_SUBJECTS_SEARCH_DATA: "GET_SUBJECTS_SEARCH_DATA",
  SUBJECTS_SEARCH_PENDING: "SUBJECTS_SEARCH_PENDING",
  SUBJECTS_SEARCH_SUCCESS: "SUBJECTS_SEARCH_SUCCESS",
  SUBJECTS_SEARCH_FAILURE: "SUBJECTS_SEARCH_FAILURE",

  TITLE_FILED_UPDATED: "TITLE_FILED_UPDATED"
};

// For Knowledge Items Actions
export const KNOWLEDGE_ITEMS = {
  // to search knowledge pages based on material id, etc
  KP_SEARCH_PENDING: "KP_SEARCH_PENDING",
  KP_SEARCH_SUCCESS: "KP_SEARCH_SUCCESS",
  KP_SEARCH_FAILURE: "KP_SEARCH_FAILURE",
  KP_SEARCH_NORECORD: "KP_SEARCH_NORECORD",

  // to push selected KO to bundle
  ADD_KNOWLEDGE_OBJECT_TO_BUNDLE_PENDING:
    "ADD_KNOWLEDGE_OBJECT_TO_BUNDLE_PENDING",
  ADD_KNOWLEDGE_OBJECT_TO_BUNDLE_SUCCESS:
    "ADD_KNOWLEDGE_OBJECT_TO_BUNDLE_SUCCESS",
  ADD_KNOWLEDGE_OBJECT_TO_BUNDLE_FAILURE:
    "ADD_KNOWLEDGE_OBJECT_TO_BUNDLE_FAILURE",

  // to push supporting links to bundle
  ADD_LINK_TO_BUNDLE_PENDING: "ADD_LINK_TO_BUNDLE_PENDING",
  ADD_LINK_TO_BUNDLE_SUCCESS: "ADD_LINK_TO_BUNDLE_SUCCESS",
  ADD_LINK_TO_BUNDLE_FAILURE: "ADD_LINK_TO_BUNDLE_FAILURE",

  // To save supporting links to metadata
  SAVE_LINK_TO_METADATA_PENDING: "SAVE_LINK_TO_METADATA_PENDING",
  SAVE_LINK_TO_METADATA_SUCCESS: "SAVE_LINK_TO_METADATA_SUCCESS",
  SAVE_LINK_TO_METADATA_FAILURE: "SAVE_LINK_TO_METADATA_FAILURE",

  //Get all knowledge items from bundle
  FETCH_ALL_KNOWLEDGEOBJECTS_FROM_BUNDLE_SUCCESS:
    "FETCH_ALL_KNOWLEDGEOBJECTS_FROM_BUNDLE_SUCCESS",
  FETCH_ALL_KNOWLEDGEOBJECTS_FROM_BUNDLE_PENDING:
    "FETCH_ALL_KNOWLEDGEOBJECTS_FROM_BUNDLE_PENDING",
  FETCH_ALL_KNOWLEDGEOBJECTS_FROM_BUNDLE_FAILURE:
    "FETCH_ALL_KNOWLEDGEOBJECTS_FROM_BUNDLE_FAILURE",


  //to save searched knowledge item to metadata
  SAVE_ASSOCIATED_KP: "SAVE_ASSOCIATED_KP",
  SAVE_ASSOCIATED_KP_PENDING:
    "SAVE_ASSOCIATED_KP_PENDING",
  SAVE_ASSOCIATED_KP_SUCCESS:
    "SAVE_ASSOCIATED_KP_SUCCESS",
  SAVE_ASSOCIATED_KP_FAILURE:
    "SAVE_ASSOCIATED_KP_FAILURE",

  // to reorder the associated Kps/ items inside Bundle
  REORDER_ASSOCIATED_KPS: "REORDER_ASSOCIATED_KPS",
  REORDER_ASSOCIATED_KPS_PENDING: "REORDER_ASSOCIATED_KPS_PENDING",
  REORDER_ASSOCIATED_KPS_SUCCESS: "REORDER_ASSOCIATED_KPS_SUCCESS",
  REORDER_ASSOCIATED_KPS_FAILURE: "REORDER_ASSOCIATED_KPS_FAILURE",

  // reset
  RESET_LOCALSEARCH_STATE_PENDING: "RESET_LOCALSEARCH_STATE_PENDING",
  RESET_LOCALSEARCH_STATE_SUCCESS: "RESET_LOCALSEARCH_STATE_SUCCESS",

  // to retrieve user's recent contributions when adding knowledge page to bundle
  GET_RECENT_CONTRIBUTIONS_PENDING: "GET_RECENT_CONTRIBUTIONS_PENDING",
  GET_RECENT_CONTRIBUTIONS_FAILURE: "GET_RECENT_CONTRIBUTIONS_FAILURE",
  GET_RECENT_CONTRIBUTIONS_SUCCESS: "GET_RECENT_CONTRIBUTIONS_SUCCESS",
  GET_RECENT_CONTRIBUTIONS_NORECORD: "GET_RECENT_CONTRIBUTIONS_NORECORD",

  // to retrieve associated KPs with a KB
  CLEAR_ASSOCIATED_KNOWLEDGE_PAGES: "CLEAR_ASSOCIATED_KNOWLEDGE_PAGES",
  GET_ASSOCIATED_KNOWLEDGE_PAGES:
    "GET_ASSOCIATED_KNOWLEDGE_PAGES",
  GET_ASSOCIATED_KNOWLEDGE_PAGES_SUCCESS:
    "GET_ASSOCIATED_KNOWLEDGE_PAGES_SUCCESS",
  GET_ASSOCIATED_KNOWLEDGE_PAGES_FAILURE:
    "GET_ASSOCIATED_KNOWLEDGE_FAILURE",
  GET_ASSOCIATED_KNOWLEDGE_PAGES_PENDING:
    "GET_ASSOCIATED_KNOWLEDGE_PENDING"

};

// For Knowledge Items Actions
export const KNOWLEDGE_HISTORY = {
  GET_ADMIN_HISTORY: "GET_ADMIN_HISTORY",
  GET_ADMIN_HISTORY_SUCCESS: "GET_ADMIN_HISTORY_SUCCESS",
  GET_ADMIN_HISTORY_PENDING: "GET_ADMIN_HISTORY_PENDING",
  GET_ADMIN_HISTORY_FAILURE: "GET_ADMIN_HISTORY_FAILURE"
}

// For paRecommended Items Actions
export const PA_RECOMMENDED = {
  IS_PA_RECOMMENDED: "IS_PA_RECOMMENDED",
  IS_PA_RECOMMENDED_SUCCESS: "IS_PA_RECOMMENDED_SUCCESS",
  IS_PA_RECOMMENDED_PENDING: "IS_PA_RECOMMENDED_PENDING",
  IS_PA_RECOMMENDED_FAILURE: "IS_PA_RECOMMENDED_FAILURE",
  UPDATE_RECOMMEND_PA: "UPDATE_RECOMMEND_PA",
  UPDATE_RECOMMEND_PA_SUCCESS: "UPDATE_RECOMMEND_PA_SUCCESS",
  UPDATE_RECOMMEND_PA_PENDING: "UPDATE_RECOMMEND_PA_PENDING",
  UPDATE_RECOMMEND_PA_FAILURE: "UPDATE_RECOMMEND_PA_FAILURE",

  IS_ROLE_PA_RECOMMENDER: "IS_ROLE_PA_RECOMMENDER",
  IS_ROLE_PA_RECOMMENDER_PENDING: "IS_ROLE_PA_RECOMMENDER_PENDING",
  IS_ROLE_PA_RECOMMENDER_SUCCESS: "IS_ROLE_PA_RECOMMENDER_SUCCESS",
  IS_ROLE_PA_RECOMMENDER_FAILURE: "IS_ROLE_PA_RECOMMENDER_FAILURE",
}

export const PEOPLE_DATA = {
  API_PEOPLE_RESULTS_PENDING: "API_PEOPLE_RESULTS_PENDING",
  API_PEOPLE_RESULTS_SUCCESS: "API_PEOPLE_RESULTS_SUCCESS",
  API_PEOPLE_RESULTS_FAILURE: "API_PEOPLE_RESULTS_FAILURE",
  API_TYPEAHEAD_DATA_SUCCESS: "API_TYPEAHEAD_DATA_SUCCESS"
};

export const CCO_DATA = {
  GET_CCO_RESULTS: "GET_CCO_RESULTS",
  GET_CCO_RESULTS_PENDING: "GET_CCO_RESULTS_PENDING",
  GET_CCO_RESULTS_SUCCESS: "GET_CCO_RESULTS_SUCCESS",
  GET_CCO_RESULTS_FAILURE: "GET_CCO_RESULTS_FAILURE",
};

export const TYPEAHEAD_DATA = {
  API_TYPEAHEAD_RESULTS_PENDING: "API_PEOPLE_RESULTS_PENDING",
  API_TYPEAHEAD_RESULTS_FAILURE: "API_PEOPLE_RESULTS_FAILURE",
  API_TYPEAHEAD_DATA_SUCCESS: "API_TYPEAHEAD_DATA_SUCCESS",
  API_TYPEAHEAD_DATA_CLEAR: "API_TYPEAHEAD_DATA_CLEAR"
};

// My Navigator Actions
export const MY_NAVIGATOR = {
  // KP Search events
  KP_SEARCH_PENDING: "KP_SEARCH_PENDING",
  KP_SEARCH_SUCCESS: "KP_SEARCH_SUCCESS",
  KP_SEARCH_FAILURE: "KP_SEARCH_FAILURE",
  // KB Search events
  KB_SEARCH_PENDING: "KB_SEARCH_PENDING",
  KB_SEARCH_SUCCESS: "KB_SEARCH_SUCCESS",
  KB_SEARCH_FAILURE: "KB_SEARCH_FAILURE",
  // Change search type between KP/KB
  CHANGE_SEARCH_TYPE: "CHANGE_SEARCH_TYPE",
  // Change search status filter
  CHANGE_STATUS_FILTER: "CHANGE_STATUS_FILTER",
  // Change search page
  CHANGE_PAGE: "CHANGE_PAGE",

  // Search PA recommend - Result/Activity Result/List
  GET_MY_ACTIVITY_LIST: "GET_MY_ACTIVITY_LIST",
  GET_MY_ACTIVITY_LIST_PENDING: "GET_MY_ACTIVITY_LIST_PENDING",
  GET_MY_ACTIVITY_LIST_SUCCESS: "GET_MY_ACTIVITY_LIST_SUCCESS",
  GET_MY_ACTIVITY_LIST_FAILURE: "GET_MY_ACTIVITY_LIST_FAILURE",

  // download count 
  GET_DOWNLOADS_COUNT: "GET_DOWNLOADS_COUNT",
  GET_DOWNLOADS_COUNT_PENDING: "GET_DOWNLOADS_COUNT_PENDING",
  GET_DOWNLOADS_COUNT_SUCCESS: "GET_DOWNLOADS_COUNT_SUCCESS",
  GET_DOWNLOADS_COUNT_FAILURE: "GET_DOWNLOADS_COUNT_FAILURE",

  // Change activities list page
  CHANGE_ACTIVITIES_PAGE: "CHANGE_ACTIVITIES_PAGE",
  SHOW_CONTRIBUTION_LOADER: "SHOW_CONTRIBUTION_LOADER",
  HIDE_CONTRIBUTION_LOADER: "HIDE_CONTRIBUTION_LOADER"

};

export const CASE_CODE = {
  CASE_CODE_PENDING: "CASE_CODE_PENDING",
  CASE_CODE_SUCCESS: "CASE_CODE_SUCCESS",
  CASE_CODE_WITH_NO_CONTENT: "CASE_CODE_WITH_NO_CONTENT",
  CASE_CODE_FAILURE: "CASE_CODE_FAILURE",
  CASE_CODE_ERROR_RESET: "CASE_CODE_ERROR_RESET"
};

export const KNOWLEDGEOBJECT_VERSIONING = {
  EDIT_PUBLISHED_KP_PENDING: "EDIT_PUBLISHED_KP_PENDING",
  EDIT_PUBLISHED_KP_SUCCESS: "EDIT_PUBLISHED_KP_SUCCESS",
  EDIT_PUBLISHED_KP_FAILURE: "EDIT_PUBLISHED_KP_FAILURE",

  EDIT_PUBLISHED_KB_PENDING: "EDIT_PUBLISHED_KB_PENDING",
  EDIT_PUBLISHED_KB_SUCCESS: "EDIT_PUBLISHED_KB_SUCCESS",
  EDIT_PUBLISHED_KB_FAILURE: "EDIT_PUBLISHED_KB_FAILURE",

  DELETE_KNOWLEDGE_ASSET: "DELETE_KNOWLEDGE_ASSET",
  DELETE_KNOWLEDGE_ASSET_PENDING: "DELETE_KNOWLEDGE_ASSET_PENDING",
  DELETE_KNOWLEDGE_ASSET_SUCCESS: "DELETE_KNOWLEDGE_ASSET_SUCCESS",
  DELETE_KNOWLEDGE_ASSET_FAILURE: "DELETE_KNOWLEDGE_ASSET_FAILURE",

  UNPUBLISH_KNOWLEDGE_ASSET: "UNPUBLISH_KNOWLEDGE_ASSET",
  UNPUBLISH_KNOWLEDGE_ASSET_PENDING: "UNPUBLISH_KNOWLEDGE_ASSET_PENDING",
  UNPUBLISH_KNOWLEDGE_ASSET_SUCCESS: "UNPUBLISH_KNOWLEDGE_ASSET_SUCCESS",
  UNPUBLISH_KNOWLEDGE_ASSET_FAILURE: "UNPUBLISH_KNOWLEDGE_ASSET_FAILURE",

  ARCHIVE_KNOWLEDGE_ASSET: "ARCHIVE_KNOWLEDGE_ASSET",
  ARCHIVE_KNOWLEDGE_ASSET_PENDING: "ARCHIVE_KNOWLEDGE_ASSET_PENDING",
  ARCHIVE_KNOWLEDGE_ASSET_SUCCESS: "ARCHIVE_KNOWLEDGE_ASSET_SUCCESS",
  ARCHIVE_KNOWLEDGE_ASSET_FAILURE: "ARCHIVE_KNOWLEDGE_ASSET_FAILURE",

  CLONE_KNOWLEDGE_ASSET: "CLONE_KNOWLEDGE_ASSET",
  CLONE_KNOWLEDGE_ASSET_PENDING: "CLONE_KNOWLEDGE_ASSET_PENDING",
  CLONE_KNOWLEDGE_ASSET_SUCCESS: "CLONE_KNOWLEDGE_ASSET_SUCCESS",
  CLONE_KNOWLEDGE_ASSET_FAILURE: "CLONE_KNOWLEDGE_ASSET_FAILURE",
};

export const CLASSES = {
  EXPANDED: 'expanded'
};

// Docviz Actions
export const DOCVIZ = {
  GET_DOCVIZ_METADATA: "GET_DOCVIZ_METADATA",
  GET_DOCVIZ_METADATA_MULTI: "GET_DOCVIZ_METADATA_MULTI",
  GET_DOCVIZ_METADATA_PENDING: "GET_DOCVIZ_METADATA_PENDING",
  GET_DOCVIZ_METADATA_SUCCESS: "GET_DOCVIZ_METADATA_SUCCESS",
  GET_DOCVIZ_METADATA_MULTI_SUCCESS: "GET_DOCVIZ_METADATA_MULTI_SUCCESS",
  GET_DOCVIZ_METADATA_FAILURE: "GET_DOCVIZ_METADATA_FAILURE",
  RESET_DOCVIZ_METADATA: "RESET_DOCVIZ_METADATA",
  DOWNLOAD_CLIPPED_SLIDES_STARTED: "DOWNLOAD_CLIPPED_SLIDES_STARTED",
  CLIPPED_SLIDES_FAILURE: "CLIPPED_SLIDES_FAILURE",
  DOWNLOAD_SELECTED_SLIDES: "DOWNLOAD_SELECTED_SLIDES",
  DOWNLOAD_CLIPPED_SLIDES_FAILED: "DOWNLOAD_CLIPPED_SLIDES_FAILED",
  FETCH_CLIPPED_ID_FAILED: "FETCH_CLIPPED_ID_FAILED",
  GENERATING_CLIPPED_SLIDES: "GENERATING_CLIPPED_SLIDES",
  UPDATE_ONLY_FRAMEWORK_SLIDES: "UPDATE_ONLY_FRAMEWORK_SLIDES",
  DOWNLOAD_CLIPPED_SLIDES_COMPLETE: "DOWNLOAD_CLIPPED_SLIDES_COMPLETE",
  DOWNLOAD_CLIPPED_SLIDES_INPROGRESS: "DOWNLOAD_CLIPPED_SLIDES_INPROGRESS",
  CLIPPED_FILE_STATUS_ERROR: "CLIPPED_FILE_STATUS_ERROR",
  GET_DOCVIZ_METADATA_MULTI_PENDING: "GET_DOCVIZ_METADATA_MULTI_PENDING",
  GET_DOCVIZ_METADATA_MULTI_FAILURE: "GET_DOCVIZ_METADATA_MULTI_FAILURE",
  UPDATE_DOCVIZ_METADATA_MULTI: "UPDATE_DOCVIZ_METADATA_MULTI"
};

// FaceCardGenerator Actions
export const FACE_CARD_GENERATOR = {
  GET_FACECARD_SLIDE_DATA: 'GET_FACECARD_SLIDE_DATA',
  FACECARD_SLIDE_PENDING: 'FACECARD_SLIDE_PENDING',
  FACECARD_SLIDE_SUCCESS: 'FACECARD_SLIDE_SUCCESS',
  FACECARD_SLIDE_FAILURE: 'FACECARD_SLIDE_FAILURE',
  FACECARD_SLIDE_PEOPLE_DATA: 'FACECARD_SLIDE_PEOPLE_DATA'
}
// Polyhierarchy Actions
export const POLYHIERACHY = {
  GET_POLYHIERARCHY_API_DATA: 'GET_POLYHIERARCHY_API_DATA',
  POLYHIERARCHY_PENDING: 'POLYHIERARCHY_PENDING',
  POLYHIERARCHY_SUCCESS: 'POLYHIERARCHY_SUCCESS',
  POLYHIERARCHY_FAILURE: 'POLYHIERARCHY_FAILURE'
}


export const FCG_NEW = {
  FC_REQUEST_API_PENDING: 'FC_REQUEST_API_PENDING',
  FC_REQUEST_API_SUCCESS: 'FC_REQUEST_API_SUCCESS',
  FC_REQUEST_API_FAILURE: 'FC_REQUEST_API_FAILURE',
  FC_POLLING_API_PENDING: 'FC_POLLING_API_PENDING',
  FC_POLLING_API_SUCCESS: 'FC_POLLING_API_SUCCESS',
  FC_POLLING_API_FAILURE: 'FC_POLLING_API_FAILURE',
  DOWNLOAD_FILE_FROM_CLOUDFRONT: 'DOWNLOAD_FILE_FROM_CLOUDFRONT',
  FC_SLIDE_PEOPLE_DATA: 'FC_SLIDE_PEOPLE_DATA',
  GET_FC_SLIDE_DATA: 'GET_FC_SLIDE_DATA',
}

export const GEN_AI = {
  INIT_GENAI_KP_API: 'INIT_GENAI_KP_API',
  KP_METAGENAI_SUCCESS: 'KP_METAGENAI_SUCCESS',
  KP_METAGENAI_PROGRESS: 'KP_METAGENAI_PROGRESS',
  SET_SHOW_GEN_AI_ICON: 'SET_SHOW_GEN_AI_ICON',
  ADD_SUCCESSFUL_FILE_ID: 'ADD_SUCCESSFUL_FILE_ID',
  KP_METAGEN_AI_FAILURE: 'KP_METAGEN_AI_FAILURE',
}

export const MATERIALS_STATUS = {
  GET_MATERIALS_STATUS: 'GET_MATERIALS_STATUS',
  GET_MATERIALS_STATUS_PENDING: 'GET_MATERIALS_STATUS_PENDING',
  GET_MATERIALS_STATUS_SUCCESS: 'GET_MATERIALS_STATUS_SUCCESS',
  GET_MATERIALS_STATUS_FAILURE: 'GET_MATERIALS_STATUS_FAILURE',
}