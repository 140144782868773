import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Layout } from "containers/Layout";
import { TabComponent } from "components/shared/TabComponent";
import { ContributeKnwldgBtn } from "components/shared/ContributeKnwldgBtn";
import { MyCases } from "containers/MyCases";
import CONSTANTS from "globalConstants";
import { Helmet } from "react-helmet";
import CONFIG from "config";
import LABELS from "labels";
import ANALYTICS from "utils/analytics/analytics";
import { DTMRULE, PAGES, PRIMARY_CATEGORY, TRIGGERS } from "utils/analytics/analytics_constants";
import { checkAllEntitlementValue, getmyCurrentLocation, scrollToTop } from "utils/helpers/helpers";
import { actions as mynavigatorActions } from "redux/actions/mynavigator/mynavigator_actions";
import { selectors as mynavigatorSelectors } from "redux/reducers/mynavigator/mynavigator_reducer";
import { getCaseOwnerList } from "redux/actions/caseOwners/caseOwners.actions";
import { selectors as BTSelectors } from 'redux/reducers/casesBT/casesBT_reducer';

import {
  notifyError,
  dismissNotifications,
} from "redux/actions/notification/notification_actions";
import { selectors as caseOwners } from "redux/reducers/caseOwners/caseOwners_reducer";
import { selectors as entitlementSelectors } from "redux/reducers/authorization/authorization_reducer";
import { selectors as versioningSelectors } from "redux/reducers/versioning/versioning_reducer";
import { selectors as isPaRecommendedSelectors } from "redux/reducers/parecommened/parecommened_reducer";
import { isRolePARecommender } from "redux/actions/parecommened/parecommended.actions";
import { changeActivitiesPage } from "redux/actions/mynavigator/mynavigator.actions";

import "./MyNavigator.scss";
import { getUserId } from "utils/auth/auth";
import { MyContribution } from "containers/MyContribution";
import { MyActivites } from "containers/MyActivites";

const {
  ENTITLEMENTS: {
    KNOWLEDGE_CONTRIBUTIONS,
    ALL,
    VIEW,
    CREATE_EDIT_DRAFT
  }
} = CONSTANTS;

class MyNavigatorContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      activeIndexTab: this.getIndextab(),
      userId: "false"
    }
  }

  componentDidMount = async () => {
    const {
      getRolePARecommender,
      fetchCaseOwnerList,
    } = this.props;

    getRolePARecommender();
    fetchCaseOwnerList();
    ANALYTICS.page.initialize({ pageName: PAGES.MY_NAVIGATOR_INIT, category: PRIMARY_CATEGORY.MY_NAVIGATOR, trackPageLoad: true });
    const userId = await getUserId();
    this.setState({
      userId
    });
  }


  componentDidUpdate = (prevProps) => {
    const { apiErrorStatus, isRolePARecomm, isContributionLoading, isMyCasesLoading, isMyActivitiesLoading } = this.props;
    const { isContributionLoading: prevIsContributionLoading, isMyCasesLoading: prevIsMyCasesLoading, isMyActivitiesLoading: prevIsMyActivitiesLoading } = prevProps;
    if (prevProps.apiErrorStatus !== apiErrorStatus) {
      this.showErrorNotification();
    }
    // only fetch activity list when - my role is PA recommender.
    if (prevProps.isRolePARecomm !== isRolePARecomm) {
      this.setActiveTab();
    }

    if (
      (isContributionLoading !== prevIsContributionLoading && isContributionLoading)
      || (isMyCasesLoading !== prevIsMyCasesLoading && isMyCasesLoading)
      || (prevIsMyActivitiesLoading !== isMyActivitiesLoading && isMyActivitiesLoading)
    ) {
      scrollToTop();
    }

  }


  getTabParamFromSearchQuery = () => {
    const { history: { location: { search } } } = this.props;
    const searchParams = new URLSearchParams(search);
    const tabselected = searchParams.get('tabselected');
    return tabselected;
  }

  getIsNotLoadingPaRecomm = () => {
    const { isRolePARecomm } = this.props;
    return isRolePARecomm !== null;
  }

  getIndextab = () => {
    let tabselected = this.getTabParamFromSearchQuery();
    const { isRolePARecomm } = this.props;
    let defaultTabIndex = 0;
    const {
      MYNAVIGATOR_TABS: {
        CONTRIBUTIONS,
        ACTIVITIES,
        MY_CASES
      }
    } = CONSTANTS;
    let startIndex = 0;
    if (this.getIsNotLoadingPaRecomm() && !isRolePARecomm) {
      startIndex = 1;
    }

    if (tabselected) {
      switch (tabselected) {
        case ACTIVITIES:
          defaultTabIndex = 0
          break;

        case CONTRIBUTIONS:
          defaultTabIndex = 1
          break;

        case MY_CASES:
          defaultTabIndex = 2
          break;

        default:
          defaultTabIndex = startIndex
          break;
      }
    } else if (this.getIsNotLoadingPaRecomm()) {
      defaultTabIndex = startIndex;
    }
    return defaultTabIndex;
  };

  showErrorNotification = () => {
    const {
      notifyError,
      dismissNotifications,
    } = this.props;
    const { ERROR_MESSAGES: { DOWNLOAD_ERROR: generalErrorMSg } } = LABELS;
    dismissNotifications();
    setTimeout(() => {
      notifyError("", generalErrorMSg);
    }, 200)
  }

  setActiveTab = () => {
    const defaultIndex = this.getIndextab();
    this.setState({
      activeIndexTab: defaultIndex
    });
  }

  tabClicked = (e, index) => {
    const { history } = this.props;
    const { UI_URL: { HOME } } = CONFIG;
    const { MYNAVIGATOR_TABS: { ACTIVITIES, CONTRIBUTIONS, MY_CASES, DRAFT } } = CONSTANTS;
    let newAdobeData = {
      page: {
        pageInfo: {
          pageName: PAGES.MY_NAVIGATOR,
          pageURL: getmyCurrentLocation(),
        },
        category: {
          primaryCategory: PRIMARY_CATEGORY.MY_NAVIGATOR
        }
      }
    };
    switch (index) {
      case 0:
        history.push({
          pathname: HOME,
          search: `?tabselected=${ACTIVITIES}`
        });
        newAdobeData.collection = {
          myTrigger: TRIGGERS.MY_NAVIGATOR.TAB_ACTIVITIES
        };

        break;
      case 1:
        history.push({
          pathname: HOME,
          search: `?tabselected=${CONTRIBUTIONS}&pill=${DRAFT}`
        });
        newAdobeData.collection = {
          myTrigger: TRIGGERS.MY_NAVIGATOR.TAB_CONTRIBUTIONS
        };
        break;
      case 2:
        history.push({
          pathname: HOME,
          search: `?tabselected=${MY_CASES}`
        });
        newAdobeData.collection = {
          myTrigger: TRIGGERS.MY_NAVIGATOR.TAB_MYCASES
        };
        break;
      default:
        break;
    }

    ANALYTICS.kc.sendEventData(newAdobeData, DTMRULE.MY_CREATION_ACTIONS);

  }

  renderTABMyActivities() {
    const { userId } = this.state;
    return (
      <MyActivites hrid={userId} />
    )
  }

  renderTABMyCases() {
    const { userId } = this.state;
    return (
      <MyCases hrid={userId} />
    )
  }

  renderTABMyContribution() {
    const { userId } = this.state;
    return (
      <MyContribution hrid={userId} />
    )

  }
  setNavigationTab = (activeIndex) => {
    const { history: { location: { search } } } = this.props;
    const searchParams = new URLSearchParams(search);
    const tabselected = searchParams.get('tabselected');
    if (tabselected) {
      this.setActiveTab();
    } else {
      this.setState({ activeIndexTab: activeIndex });
    }
  }

  render() {
    const {
      allEntitlements,
      isViewEntitlement,
      isCreateEditEntitlement,
      isRolePARecomm,
      caseOwnerList,
      isContributionLoading,
      isMyCasesLoading,
      isMyActivitiesLoading
    } = this.props;

    const { activeIndexTab } = this.state;
    const {
      MY_NAVIGATOR: {
        PAGE_TITLE,
        TAB_MY_CONTRIBUTIONS,
        TAB_MY_ACTIVITIES,
        TAB_MY_CASES,
      }
    } = LABELS;
    const {
      PAGETITLE: {
        MY_NAVIGATOR
      }
    } = LABELS;
    const {
      UI_URL: {
        CREATION
      },
      IT_CONNECT_ACCESS
    } = CONFIG;
    const { userId } = this.state;
    const areAllEntitlementsFalse = checkAllEntitlementValue(allEntitlements, false);
    const isMyCaseTabVisible = caseOwnerList && caseOwnerList.length > 0 && caseOwnerList.includes(userId);
    let tabArrayList = [];


    tabArrayList = [
      {
        className: "item-style workspacebar__my-activities",
        hiddenClassName: !!isRolePARecomm && !areAllEntitlementsFalse && isViewEntitlement ? "" : "displaynone",
        heading: TAB_MY_ACTIVITIES,
        component: this.renderTABMyActivities()
      },
      {
        className: "item-style workspacebar__my-contributions",
        hiddenClassName: !areAllEntitlementsFalse && isViewEntitlement ? "" : "displaynone",
        heading: TAB_MY_CONTRIBUTIONS,
        component: this.renderTABMyContribution()
      }
    ];

    if (isMyCaseTabVisible && process.env.REACT_APP_IS_MY_CASES_TAB_ENABLED?.trim() === "true") {
      tabArrayList.push({
        className: `item-style workspacebar__my-contributions`,
        hiddenClassName: "",
        heading: TAB_MY_CASES,
        component: this.renderTABMyCases()
      })
    };
    return (
      <Layout
        id="mynavigator"
        layoutClass="layout__my-navigator"
        pageTitle={PAGE_TITLE}
        breadCrumbFlag
        bannerFlag
        contributeKnwldgFlag={false}
        productName={LABELS.KNOWLEDGE_PRODUCT_NAME.MY_NAVIGATOR}
        homeIconFlag={false}
      >
        <Helmet>
          <title>{MY_NAVIGATOR}</title>
        </Helmet>
        <div className="my-navigator">
          <TabComponent
            className={`tabs__container tabs__my-navigator ${isContributionLoading || isMyCasesLoading || isMyActivitiesLoading ? " tabs__my-navigator--loadingcontent" : ""}`}
            menu={{ secondary: true, pointing: true }}
            defaultActiveIndex={activeIndexTab}
            getActiveIndex={this.setNavigationTab}
            hideCount
            onTabChangeCallback={this.tabClicked}
            arrayOfPaneElements={tabArrayList}
          />
          <div className="my-navigator__contributeBtn">
            <ContributeKnwldgBtn
              url={CREATION}
              disable={isCreateEditEntitlement ? false : true}
              disableTooltipHTML={<p>You do not have access to contribute knowledge. Please reach out to <strong><a href={IT_CONNECT_ACCESS} target="_blank" rel="noopener noreferrer">Service Center</a></strong> to get the access.</p>}
            />
          </div>
        </div>
      </Layout>
    )
  }
}

const mapStateToProps = (state) => ({
  caseOwnerList: caseOwners.getCaseOwnerList(state),
  apiErrorStatus: versioningSelectors.getErrorStatus(state),
  isRolePARecomm: isPaRecommendedSelectors.getRolePARecommeder(state),
  allEntitlements: entitlementSelectors.getEntitlementValue(state, KNOWLEDGE_CONTRIBUTIONS, ALL),
  isViewEntitlement: entitlementSelectors.getEntitlementValue(state, KNOWLEDGE_CONTRIBUTIONS, VIEW),
  isCreateEditEntitlement: entitlementSelectors.getEntitlementValue(state, KNOWLEDGE_CONTRIBUTIONS, CREATE_EDIT_DRAFT),
  isContributionLoading: mynavigatorSelectors.getLoading(state),
  isMyCasesLoading: BTSelectors.getLoading(state),
  isMyActivitiesLoading: mynavigatorSelectors.getActivitiesLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
  getRolePARecommender: () => dispatch(isRolePARecommender()),
  fetchCaseOwnerList: () => dispatch(getCaseOwnerList()),
  changeActivitiesPage: (searchCriteria) => dispatch(changeActivitiesPage(searchCriteria)),
  mynavigatorActions: bindActionCreators({ ...mynavigatorActions }, dispatch),
  notifyError: (title, msg) => dispatch(notifyError(title, msg)),
  dismissNotifications: () => dispatch(dismissNotifications()),
});

export const MyNavigator = connect(
  mapStateToProps,
  mapDispatchToProps
)(MyNavigatorContainer);

export { MyNavigatorContainer };
