import {
  MATERIALS_STATUS,
} from 'redux/constants';

export const materialsStatusPending = () => ({
  type: MATERIALS_STATUS.GET_MATERIALS_STATUS_PENDING
});

export const materialsStatusSuccess = (response) => ({
  type: MATERIALS_STATUS.GET_MATERIALS_STATUS_SUCCESS,
  payload: response
});

export const materialsStatusFailure = (error) => ({
  type: MATERIALS_STATUS.GET_MATERIALS_STATUS_FAILURE,
  payload: error,
});