import { MATERIALS_STATUS } from 'redux/constants';
export const name = 'materialsstatus';
export const initialState = {
  data: [],
  error: '',
  loading: false
};
export const selectors = {
  getMaterialsStatusData: state => state[name].data,
};

const setMaterialsStatusPending = (state) => {
  return {
    ...state,
    loading: true,
    error: ''
  };
}

const setMaterialsStatusSuccess = (state, payload) => {
  return {
    ...state,
    data: payload
  }
};

const setMaterialsStatusFailure = (state, payload) => {
  return {
    ...state,
    loading: false,
    error: payload
  };
};

export function reducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case MATERIALS_STATUS.GET_MATERIALS_STATUS_PENDING:
      return setMaterialsStatusPending(state);
    case MATERIALS_STATUS.GET_MATERIALS_STATUS_SUCCESS:
      return setMaterialsStatusSuccess(state, payload);
    case MATERIALS_STATUS.GET_MATERIALS_STATUS_FAILURE:
      return setMaterialsStatusFailure(state);
    default:
      return state;
  }
}
