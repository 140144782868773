///////////// Importing Depencies ///////////
import React from "react";
import Autosuggest from "react-autosuggest";
import { connect } from "react-redux";

//////////// Importing Redux, Actions and Selectors //////////
import { bindActionCreators } from "redux";
import { actions as peopleResultsActions } from "redux/actions/peopleresults/peopleresults_actions";
import {
  notifyWarning,
  dismissNotifications,
} from "redux/actions/notification/notification_actions";
import { selectors as peopleResultsSelectors } from "redux/reducers/peopleresults/peopleresults_reducer";

//////////// Importing Components //////////
import { Tooltip } from "components/shared/Tooltip";
import { PeopleInfoCard } from 'components/PeopleInfoCard'

//////////// Importing Constants & Helper //////////
import { sortArrayAlphabetically, transferObjValueToArray, checkIsGivenValueIsAObj, getPeopleApiDataByHrids } from "utils/helpers/helpers";
import LABELS from "labels";

import arrayMove from 'array-move';
import DraggableList from "components/shared/Draggable/DraggableList";


//////////// Importing scss Files /////////
import "./peopleSelector.scss";


export class PeopleSelectorContainer extends React.Component {
  constructor() {
    super();
    this.state = {
      value: "",
      suggestions: [],
      selectedSuggestions: {},
      suggestionList: {},
      elementsAddedFromSuggestions: [],
      selectedSuggestionsArray: [],
      dragDropDynamicClassCheck: false,
      hidePeopleTitleTooltip: false
    };
  }

  /**
   * Sorting data in same sequence in which ids are coming as props
   * @param {*} data 
   * @param {*} ids 
   */
  sortCurrentList = (data, ids, isDataChangedDueToAlumni) => {

    const { callBackAfterPeopleApiFetch } = this.props;
    const temp = {};
    let temp1 = {};
    if (data && data[0]) {
      data.forEach((peopleObj) => {
        const { id } = peopleObj;
        temp["id-" + id] = peopleObj;
      });
      if (ids.length > 1) {
        //sorting loop in the same format
        ids.forEach((id, index) => {
          const idNew = "id-" + id;
          if (temp[idNew]) {
            temp1[idNew] = temp[idNew];
          }
        });
      } else {
        temp1 = { ...temp };
      }
      let selectedSuggestionsArray = transferObjValueToArray(temp1);

      this.setState(
        {
          selectedSuggestions: {
            ...temp1,
          },
          selectedSuggestionsArray
        }, () => {
          if (callBackAfterPeopleApiFetch && isDataChangedDueToAlumni)
            callBackAfterPeopleApiFetch(selectedSuggestionsArray);
        }
      );
    }
  }

  /**
   * Sorting data in basis of preferredDisplayName key
   * @param {*} data 
   */
  sortSuggestionsList = (data) => {
    const temp = {};
    let temp1 = {};
    data = sortArrayAlphabetically(data, 'preferredDisplayName')
    if (data && data[0]) {
      data.forEach((peopleObj) => {
        const { id } = peopleObj;
        temp["id-" + id] = peopleObj;
      });
      data.forEach(({ id }) => {
        const idNew = "id-" + id;
        temp1[idNew] = temp[idNew];
      });
      this.setState(
        {
          suggestionList: {
            ...temp1,
          },
        }
      );
    }
  }

  getDataByID = async (ids, isSuggestion) => {
    const { inputByEmail, excludeAlumni, caseTeamWithNoAlumniCallback, isBigImage } = this.props;
    const {
      peopleResultsActions: { getPeopleDataUsingIDs },
    } = this.props;
    const data = await getPeopleApiDataByHrids(ids, getPeopleDataUsingIDs, inputByEmail, isBigImage);

    let newData = [];
    if (excludeAlumni) {
      const dataWithNoAlumni = data?.filter(({ alumni }) => alumni?.toLowerCase() !== "yes");
      newData = dataWithNoAlumni;
      if (caseTeamWithNoAlumniCallback)
        caseTeamWithNoAlumniCallback(newData);
    } else {
      newData = data;
    }
    if (isSuggestion) {
      this.sortSuggestionsList(newData);
    } else {
      this.sortCurrentList(newData, ids, newData !== data);
    }
  };

  onSelectedDataChange = (isAuthorSequenceChange = false) => {
    const { selectedSuggestions, elementsAddedFromSuggestions } = this.state;
    const { onChange } = this.props;
    let hridArray = Object.keys(selectedSuggestions);
    hridArray = hridArray.map((id) => id.split("id-")[1]);
    if (onChange) {
      onChange(hridArray, elementsAddedFromSuggestions, isAuthorSequenceChange);
    }
  };
  preFill = () => {
    const { preFillData, isStateReset } = this.props;
    const { selectedSuggestions } = this.state;
    let objLength = isStateReset ? true : !Object.keys(selectedSuggestions).length;
    if (
      preFillData.length &&
      preFillData[0] &&
      preFillData[0].length &&
      objLength
    ) {
      this.getDataByID(preFillData[0]);
    } else {
      objLength && this.setState({
        selectedSuggestions: {},
        selectedSuggestionsArray: []
      });
    }

  };

  /**
   * This funciton is used to fill suggestionList
   */
  preFillSuggestionList = () => {
    const { suggestions: { hrids } } = this.props;
    if (hrids?.length) {
      /**
       * Sending extra parameter true to getDataByID function to 
       * fill suggestionList state value for 
       * hrids which is based on authors
       */
      if (hrids?.length) {
        this.getDataByID(hrids, true)
      }
    }
  }

  componentDidMount = () => {
    this.preFill();
    this.preFillSuggestionList();
    const { preFillSelectedSuggestion, suggestions: {
      useDragDrop
    } } = this.props;
    if (preFillSelectedSuggestion?.length) {
      this.setState({
        elementsAddedFromSuggestions: preFillSelectedSuggestion
      });
    }
    if (useDragDrop) {
      this.setState({ dragDropDynamicClassCheck: true })
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { peopleResults, preFillData, suggestions } = this.props;
    if (
      peopleResults &&
      peopleResults.length &&
      JSON.stringify(peopleResults) !== JSON.stringify(this.state.suggestions)
    ) {
      this.setState({
        suggestions: [...peopleResults],
      });
    }
    if (JSON.stringify(preFillData) !== JSON.stringify(prevProps.preFillData)) {
      this.preFill();
    }
    if (suggestions && JSON.stringify(suggestions) !== JSON.stringify(prevProps.suggestions)) {
      this.preFillSuggestionList();
    }
  };

  onChange = (event, { newValue }) => {
    if (newValue.length < 2) {
      const {
        peopleResultsActions: { resetTypeAheadData },
        name,
      } = this.props;
      const selectorName = name;
      resetTypeAheadData(selectorName);
    }
    this.setState({
      value: newValue,
      suggestions: [],
    });
  };

  renderSuggestion = ({ preferredDisplayName, businessTitle, hostOfficeLocation, id }) => {
    const { value, selectedSuggestionsArray } = this.state;
    let isDuplicatePerson = selectedSuggestionsArray.some((person) => {
      return person.id === id;
    })

    if (value && value.length > 1) {
      return (
        <div className={`react-autosuggest__suggestion-wrap ${isDuplicatePerson ? 'duplicatePerson' : ''}`} id="id">
          <span className="react-autosuggest__name">{preferredDisplayName}</span>
          <span className="react-autosuggest__title">
            {businessTitle}, {!!hostOfficeLocation ? (!!hostOfficeLocation?.name ? hostOfficeLocation?.name : hostOfficeLocation?.city) : ''}
          </span>
        </div>
      );
    }
  };

  getSuggestionValue = () => {
    const { value: valueText } = this.state;
    return valueText;
  };

  onSuggestionSelected = (event, { suggestion }) => {
    const { selectedSuggestions, selectedSuggestionsArray } = this.state;
    const { id, preferredDisplayName } = suggestion;

    const isDuplicatePerson = selectedSuggestionsArray.some((person) => {
      return person.id === id;
    });

    if (isDuplicatePerson) {
      event.preventDefault();
      return;
    }
    const { value: valueText, dragDropDynamicClassCheck } = this.state;
    const { getSuggestionValueCallback,
      peopleResultsActions: { resetTypeAheadData },
      name,
      kbId,
      topicId,
      suggestions: {
        useDragDrop
      } } = this.props;


    if (getSuggestionValueCallback) {
      const dataAnal = { valueText: valueText, preferredDisplayName: preferredDisplayName, kbId: kbId, topicId: topicId };
      getSuggestionValueCallback(dataAnal);
    }
    const selectorName = name;
    resetTypeAheadData(selectorName);
    this.setState(
      {
        selectedSuggestions: {
          ...selectedSuggestions,
          ["id-" + id]: suggestion,
        },
        selectedSuggestionsArray: [...selectedSuggestionsArray, suggestion],
        suggestions: [],
        value: "",
      },
      () => {
        this.onSelectedDataChange();
      }
    );
    if (useDragDrop && !dragDropDynamicClassCheck) {
      this.setState({ dragDropDynamicClassCheck: true })
    }
  }

  deleteSelected = (id) => {
    const { selectedSuggestions } = this.state;
    const { crossClickCallback } = this.props;
    const temp = JSON.parse(JSON.stringify(selectedSuggestions));
    delete temp[id];
    const currentId = id.split("id-")[1];
    let selectedSuggestionsArray = transferObjValueToArray(temp);
    this.setState(
      {
        selectedSuggestions: { ...temp },
        selectedSuggestionsArray,
        elementsAddedFromSuggestions: Object.keys(temp).length ? this.state.elementsAddedFromSuggestions.filter(item => item !== currentId) : []
      },
      () => {
        const {
          dismissNotifications
        } = this.props;
        dismissNotifications();
        this.onSelectedDataChange();

        if (Object.keys(temp).length === 0 && crossClickCallback) {
          crossClickCallback();
        }

      }
    );
  };

  onSuggestionsFetchRequested = ({ value, reason }) => {
    if (reason === 'suggestion-selected') {
      return false;
    }
    const { selectedSuggestions, dragDropDynamicClassCheck } = this.state;
    const { suggestions: {
      useDragDrop
    } } = this.props;
    const {
      notifyWarning,
      dismissNotifications,
      maxLimit,
      filterQuery,
      name,
    } = this.props;

    const selectorName = name;
    const {
      METADATA: { CONTRIBUTORS_MAXLIMIT_MSG },
    } = LABELS;

    if (dragDropDynamicClassCheck && useDragDrop) {
      this.setState({ dragDropDynamicClassCheck: false })
    }

    if (Object.keys(selectedSuggestions).length >= maxLimit) {
      this.setState({
        suggestions: [],
      });
      if (value.length) {
        notifyWarning(
          "",
          CONTRIBUTORS_MAXLIMIT_MSG
        );
      } else {
        dismissNotifications();
      }
      return false;
    } else {
      const {
        peopleResultsActions: { getPeopleResult },
      } = this.props;
      const term = value.trim().toLocaleLowerCase();
      if (term.length >= 2) {
        setTimeout(() => {
          getPeopleResult(term, selectorName, filterQuery);
        }, 500);
      }
    }
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    const {
      peopleResultsActions: { resetTypeAheadData }, name,
      suggestions: {
        useDragDrop
      }
    } = this.props;
    const selectorName = name;
    resetTypeAheadData(selectorName);
    this.setState({
      suggestions: [],
      value: ''
    });
    if (useDragDrop) {
      this.setState({ dragDropDynamicClassCheck: true })
    }
  };

  /**
   * Function to add a user from suggestion list to current selected list
   * @param {*} obj 
   */
  addToSelectedList = (obj) => {
    const { id } = obj;
    const { elementsAddedFromSuggestions } = this.state;
    this.setState({
      elementsAddedFromSuggestions: [...elementsAddedFromSuggestions, id]
    })
    let suggestionObj = {
      suggestion: obj
    }
    this.onSuggestionSelected(null, suggestionObj)
  }

  /**
   * Function to check whether the current suggestion list user is
   * also present in the current selected list
   * if user is present then return true otherwise return false
   * helpful in showing grren tick and cross icon
   * @param {*} id 
   * @returns bool
   */
  getIsExisting = (id) => {
    let isExisting = false;
    const { selectedSuggestions } = this.state;
    const currentList = selectedSuggestions[id];
    isExisting = currentList ? true : false;
    return isExisting;
  }

  /**
   * People card used for DragDrop Feature
   * @param {*} item 
   * @returns 
   */
  renderPeopleCard = (item) => {
    const { selectedSuggestionsArray, hidePeopleTitleTooltip } = this.state;
    return (
      <PeopleInfoCard
        id={item.id}
        peopleObj={item}
        showImage={true}
        disabledCross={false}
        deleteSelected={() => this.deleteSelected(`id-${item.id}`)}
        initialWidth={6}
        showDragDropIcon={selectedSuggestionsArray.length > 1 ? true : false}
        customClass={"dragAndDropPeopleSelectorCls"}
        hidePeopleTitleTooltip={hidePeopleTitleTooltip}
      />
    );
  }

  /**
   * This funciton will be called when we are done with the 
   * drag and drop of people selector
   * oldIndex = the current index
   * new Index = where you are dropping the card
   * @param {*} param0 
   */
  onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      let { selectedSuggestionsArray } = this.state;

      let data = arrayMove(selectedSuggestionsArray, oldIndex, newIndex);

      let temp = {};
      data.forEach((peopleObj) => {
        const { id } = peopleObj;
        temp["id-" + id] = peopleObj;
      });

      this.setState({
        selectedSuggestionsArray: data,
        selectedSuggestions: temp
      }, () => {
        this.onSelectedDataChange(true);
      })
    }
    this.setState({ hidePeopleTitleTooltip: false })
  };

  onSortStart = () => {
    this.setState({ hidePeopleTitleTooltip: true })
  }

  /**
   * function used to show PeopleInfoCard component with noraml case as well with dragDrop feature
   * if we need to show dragdrop feature then we need to pass useDragDrop as props inside
   * suggestions from parentComponent
   * @returns 
   */
  renderDynamicPeopleInfoCard = () => {
    const { selectedSuggestionsArray, selectedSuggestions } = this.state;
    const { suggestions: {
      disabledCross,
      useDragDrop
    } } = this.props;

    if (!useDragDrop && selectedSuggestions && checkIsGivenValueIsAObj(selectedSuggestions) && Object.keys(selectedSuggestions).length) {
      return Object.keys(selectedSuggestions).map((id) => {
        const peopleObj = selectedSuggestions[id];
        return (
          <PeopleInfoCard
            key={id}
            id={id}
            peopleObj={peopleObj}
            showImage={true}
            disabledCross={disabledCross}
            deleteSelected={() => this.deleteSelected(id)}
            initialWidth={6}
          />
        );
      })
    } else {
      const { hidePeopleTitleTooltip } = this.state;
      return <DraggableList items={selectedSuggestionsArray}
        onSort={this.onSortEnd}
        onSortStart={this.onSortStart}
        dragHandle={true}
        countOfItems={selectedSuggestionsArray.length}
        componentFunc={(item) => this.renderPeopleCard(item)}
        axis="xy"
        customClass={hidePeopleTitleTooltip ? "draggable__item--custom peopleselector__hide-tooltip" : "draggable__item--custom"}
      />
    }
  }


  render() {
    const {
      value,
      suggestions,
      selectedSuggestions,
      suggestionList,
      dragDropDynamicClassCheck
    } = this.state;
    const {
      label,
      className,
      maxLimit,
      placeholder,
      disabled,
      optionalTxt,
      tooltipHtml,
      hideInput,
      customSelect,
      isInputFirst,
      name,
      showSuggestionList,
      suggestions: {
        heading
      },
      isScrollable
    } = this.props;
    // Autosuggest will pass through all these props to the input.
    const inputProps = {
      placeholder: placeholder,
      value,
      onChange: this.onChange,
      autoComplete: "new",
    };

    let peopleSector = (
      <div
        className={`peopleselector__wrapper ${dragDropDynamicClassCheck ? 'peopleselector__wrapper--dragDrop' : ''} ${className ? className : " "}${disabled ? " peopleselector__disabled" : " "} ${Object.keys(selectedSuggestions).length
          ? ""
          : " peopleselector__wrapper--full"
          } ${Object.keys(selectedSuggestions).length === maxLimit ? "peopleselector__wrapper--max" : ""}`}
      >
        {customSelect}
        {!isInputFirst && <span className={`${isScrollable ? "peopleselector__infoCard" : ""}`}>
          {this.renderDynamicPeopleInfoCard()}
        </span>}
        {
          (Object.keys(selectedSuggestions).length < maxLimit && !hideInput) && (
            <Autosuggest
              suggestions={suggestions}
              onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
              onSuggestionsClearRequested={this.onSuggestionsClearRequested}
              getSuggestionValue={this.getSuggestionValue}
              onSuggestionSelected={this.onSuggestionSelected}
              renderSuggestion={this.renderSuggestion}
              inputProps={inputProps}
              alwaysRenderSuggestions={value && value.length > 1}
            />
          )
        }
        {isInputFirst && <span className={`${isScrollable ? "peopleselector__infoCard" : ""}`}>
          {this.renderDynamicPeopleInfoCard()}
        </span>}
      </div >
    )

    // Finally, render it!
    return (
      <>
        {(!!label || !!optionalTxt) && (<label className={`peopleselector__label tooltip__label ${disabled ? " peopleselector__label__disabled label__disabled" : ""}`}>
          {label} {optionalTxt ? <span className="optional-text">{optionalTxt}</span> : ""}
        </label>
        )}
        {tooltipHtml && (
          <Tooltip id={"people_selector" + name}>{tooltipHtml}</Tooltip>
        )}

        {showSuggestionList ? (
          <div className="peopleselector__outer">
            {peopleSector}
            <div className="peopleselector__list-container">
              <div className="peopleselector__list-heading">
                <label>
                  {heading}
                </label>
              </div>
              <div className="peopleselector__list-inner">
                {Object.keys(suggestionList).map((id) => {
                  const peopleObj = suggestionList[id];
                  const isSelected = this.getIsExisting(id);
                  return (
                    <PeopleInfoCard
                      key={id}
                      id={id}
                      peopleObj={peopleObj}
                      showImage={false}
                      disabledCross={true}
                      deleteSelected={() => () => { }}
                      showAddBtn={true}
                      showBoldCls={true}
                      initialWidth={5}
                      customClass={"suggestionListOuterSection"}
                      addToSelectedList={() => this.addToSelectedList(peopleObj)}
                      isSelected={isSelected}
                    />
                  );
                })
                }
              </div>
            </div>
          </div>
        ) : peopleSector}
      </>
    );
  }
}


PeopleSelectorContainer.defaultProps = {
  preFillData: [],
  disabledCross: false,
  isInputFirst: false,
  isBigImage: false,
  disabled: false,
  filterQuery: '',
  onChange: () => { },
  maxLimit: 500,
  inputByEmail: false,
  suggestions: {
    heading: '',
    hrids: [],
    useDragDrop: false
  },
  showSuggestionList: false,
  isScrollable: false
};
/* istanbul ignore next */
const mapStateToProps = (state, ownProps) => ({
  peopleResults: peopleResultsSelectors.getPeopleResults(state, ownProps.name),
});

/* istanbul ignore next */
const mapDispatchToProps = (dispatch) => ({
  peopleResultsActions: bindActionCreators(
    { ...peopleResultsActions },
    dispatch
  ),
  notifyWarning: (title, msg) => dispatch(notifyWarning(title, msg)),
  dismissNotifications: () => dispatch(dismissNotifications()),
});

export const PeopleSelector = connect(
  mapStateToProps,
  mapDispatchToProps
)(PeopleSelectorContainer);
